import React from "react";

export const Faqp = (props) => {
    document.title = `You clicked faq page`;
    return (
        <div id='policy' >
            <div className="">
                <div className="policyinto">
                <img src="img/banners/faq.png" className="" alt="return-policies" />{" "}

                </div>
                <div className="container">
                    <div className='section-title text-center' style={{marginTop:'50px'}}>
                        <h2>Privacy Policy</h2>
                    </div>
                    <div className="policytext">
                        <p>We are respect and humbly revere the privacy of all the visitors to our website. Under no conditions will Aquapure Ro SERVICES pass your personal information to external agents, unless we are legally entitled to do so.</p>
                        <p>All personal information provided to Aquapure Ro SERVICES, through its web sites or other means, is
                        protected. Individuals will be informed at the time of collection of the personal information that their
                        personal information is being collected, the purpose for whi ch it is being collected and that they
                        have a right of access to that information.</p>

                        <h3><b><u>Acceptance of Terms</u></b></h3>
                        <p>The following are terms of a legal agreement between you and Aquapure Ro services. By accessing,
                        browsing and/or using the Website, Mobile Apps, or contacting Aquapure Ro services via telephone
                        or electronic media (including, but not limited to e mail, postings, social media sites, texts, etc.),
                        (collectively referred to as the “Services”), you automatically agree to abide by Aquapure Ro services
                        terms of use and acknowledge that you have read, understood, and agree, to be bound by these
                        terms and to comply with all applicable laws and regulations. If you do not agree to these terms, do
                        not use the Services. All information appearing on the Aquapure Ro services Services, including the
                        Website and Mobile Apps are the property of Aquapure Ro services and/or is presented with the
                        permission of others. The Services, including the Website and Mobile Apps are controlled and
                        operated by Aquapure Ro services.</p>
                        
                        <p>TERMS OF USE ARE SUBJECT TO CHANGE AT ANY TIME, EFFECTIVE UPON POSTING ON THE SERVICE. In
                        using our web site, you are agreeing to the Privacy policy of Aquapure RO SERVICES. We reserve the right to
                        change, modify, add/remove portions of this policy at any time.</p>

                        <h3><b><u>Disclaimer</u></b></h3>
                        <p>The information contained on the Services, including the Website and Mobile Apps is intended to
                        provide information on various subjects of interest. YOUR USE OF THIS INFORMATION IS AT YOUR OWN
                        RISK. YOU ASSUME FULL RESPONSIBILITY AND R ISK OF LOSS RESULTING FROM THE USE OF THIS
                        INFORMATION. Aquapure Ro services WILL NOT BE LIABLE FOR ANY DIRECT, SPECIAL, INDIRECT,
                        INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES WHATSOEVER, WHETHER
                        IN AN ACTION BASED UPON A STATUTE, CON TRACT, TORT (INCLUDING, WITHOUT LIMITATION NEGLIGENCE)
                        OR OTHERWISE.</p>

                        <p>Aquapure
                        Ro services nor its vendor partners, warrants that access to the Services will be
                        uninterrupted or that the Services will be error free; nor do they make any warranty as to the results
                        that may be obtained from the reliability, completeness, or content of any service, information or
                        materials provided through or in connection with the use of the Services.</p>

                        <p>Aquapure
                        Ro services may, at any time, revise t hese Terms of Use by updating this posting without
                        prior notice. By using the Website, you agree to be bound by any such revisions and should therefore
                        periodically visit this page to determine the then current Terms of Use to which you are bound. If any
                        f uture changes to these Terms of Use are unacceptable to you or cause you to no longer be in
                        compliance with these Terms of Use you must terminate, and immediately stop using, the Services.
                        Your continued use of the Services following any revision to these Terms of Use constitutes your
                        complete and irrevocable acceptance of any and all such changes. Aquapure Ro services may
                        change, modify, suspend, or discontinue any aspect of the Services at any time. Aquapure Ro
                        services may also impose limits on certain f eatures or restrict your access to parts or all of the
                        Services without notice or liability.</p>

                        <h3><b><u>Indemnification</u></b></h3>
                        <p>YOU AGREE TO INDEMNIFY AND HOLD HARMLESS TO
                        Aquapure Ro services, ITS DIRECTORS, AND
                        EMPLOYEES, FROM AND AGAINST ANY AND ALL CLAIM, LOSS, EXPENSE OR DEMAND OF LIABILITY, AND
                        COSTS INCURRED, AND HOLD THEM EACH HARMLESS FROM ANY AND ALL CLAIMS OR DEMANDS,
                        INCLUDING LEGAL FEES, MADE BY ANY THIRD PARTY DUE TO OR ARISING FROM YOUR USE OF THE Aquapure
                        Ro services SERVICES, WITH REGARD TO ANY DISPUTE BETWE EN YOU AND A VENDOR, OR YOUR VIOLATION
                        OF THESE TERMS OF USE, OR ARISING FROM YOUR VIOLATION OF ANY RIGHTS OF A THIRD PARTY.</p>
                        </div>
                
                </div>

            </div>
        </div>
    )
}