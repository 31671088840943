import { useState, useEffect } from "react";
import { Route, Routes, Switch } from 'react-router-dom';
import JsonData from "./data/data.json";
import locdata from "./data/locdata.json";
import rocompany from "./data/rocompany.json";


import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";
import { Errorpage } from "./components/pages/errorpage";
import { Thanks } from "./components/pages/thanks";
import { Footer } from "./components/footer";
import Footerdetail from "./components/Footerdetail";
import { Bihar } from "./components/location/Bihar";
import { Jharkhand } from "./components/location/Jharkhand";
import { Bengal } from "./components/location/Bengal";
import { Popularcitydata } from "./components/location/Popularcitydata";
import { Usefulnearme } from "./components/location/Usefulnearme";
import { Returnypolicy } from "./components/pages/returnpolicy";
import { TermsCondition } from "./components/pages/termscondition";
import { Faqp } from "./components/pages/faqp";
import { Privacypolicy } from "./components/pages/privacypolicy";
import { Helpcenter } from "./components/pages/helpcenter";

import { Companylist } from "./components/location/Companylist";
import { Kentro } from "./components/pages/brandpage/kent/kentro";
import { AOSmithro } from "./components/pages/brandpage/aosmith/aosmithro";
import { Aquafreshro } from "./components/pages/brandpage/aquafresh/aquafreshro";
import { Aquaguardro } from "./components/pages/brandpage/aquaguard/aquaguardro";
import { BlueStarro } from "./components/pages/brandpage/bluestar/bluestarro";
import { Eurekaforbesro } from "./components/pages/brandpage/edurekaforbes/eurekaforbesro";
import { Havellsro } from "./components/pages/brandpage/havells/havellsro";
import { LGro } from "./components/pages/brandpage/lg/lgro";
import { Livepurero } from "./components/pages/brandpage/livepure/livepurero";
import { MarQro } from "./components/pages/brandpage/marq/marqro";
import { PureItro } from "./components/pages/brandpage/pureit/pureitro";
import { Whirlpoolro } from "./components/pages/brandpage/whirlpool/whirlpoolro";
import { Kuchinaro } from "./components/pages/brandpage/kuchina/kuchinaro";
import { Aquagrandro } from "./components/pages/brandpage/aquagrand/aquagrandro";
import { BiharAquafresh } from "./components/pages/brandpage/aquafresh/biharaquafresh";
import { JharkhandAquafresh } from "./components/pages/brandpage/aquafresh/jharkhandaquafresh";
import { BengalAquafresh } from "./components/pages/brandpage/aquafresh/bengalaquafresh";
import { BiharAquagrand } from "./components/pages/brandpage/aquagrand/biharaquagrand";
import { JharkhandAquagrand } from "./components/pages/brandpage/aquagrand/jharkhandaquagrand";
import { BengalAquagrand } from "./components/pages/brandpage/aquagrand/bengalaquagrand";
import { BiharAOSmith } from "./components/pages/brandpage/aosmith/biharsosmith";
import { JharkhandAOSmith } from "./components/pages/brandpage/aosmith/jharkhandaosmith";
import { BengalAOSmith } from "./components/pages/brandpage/aosmith/bengalaosmith";
import { BiharAquaguard } from "./components/pages/brandpage/aquaguard/biharaquaguard";
import { JharkhandAquaguard } from "./components/pages/brandpage/aquaguard/jharkhandaquaguard";
import { BengalAquaguard } from "./components/pages/brandpage/aquaguard/bengalaquaguard";
import { BiharEdurekaforbes } from "./components/pages/brandpage/edurekaforbes/biharedurekaforbes";
import { JharkhandEdurekaforbes } from "./components/pages/brandpage/edurekaforbes/jharkhandedurekaforbes";
import { BengalEdurekaforbes } from "./components/pages/brandpage/edurekaforbes/bengaledurekaforbes";
import { BiharHavells } from "./components/pages/brandpage/havells/biharhavells";
import { JharkhandHavells } from "./components/pages/brandpage/havells/jharkhandhavells";
import { BengalHavells } from "./components/pages/brandpage/havells/bengalhavells";
import { BiharKent } from "./components/pages/brandpage/kent/biharkent";
import { JharkhandKent } from "./components/pages/brandpage/kent/jharkhandkent";
import { BengalKent } from "./components/pages/brandpage/kent/bengalkent";
import { BengalKuchina } from "./components/pages/brandpage/kuchina/bengalkuchina";
import { JharkhandKuchina } from "./components/pages/brandpage/kuchina/jharkhandkuchina";
import { BiharKuchina } from "./components/pages/brandpage/kuchina/biharkuchina";
import { BengalLG } from "./components/pages/brandpage/lg/bengallg";
import { JharkhandLG } from "./components/pages/brandpage/lg/jharkhandlg";
import { BiharLG } from "./components/pages/brandpage/lg/biharlg";
import { BengalLivepure } from "./components/pages/brandpage/livepure/bengallivepure";
import { JharkhandLivepure } from "./components/pages/brandpage/livepure/jharkhandlivepure";
import { BiharLivepure } from "./components/pages/brandpage/livepure/biharlivepure";
import { BengalMarq } from "./components/pages/brandpage/marq/bengalmarq";
import { JharkhandMarq } from "./components/pages/brandpage/marq/jharkhandmarq";
import { BiharMarq } from "./components/pages/brandpage/marq/biharmarq";
import { BengalPureit } from "./components/pages/brandpage/pureit/bengalpureit";
import { JharkhandPureit } from "./components/pages/brandpage/pureit/jharkhandpureit";
import { BiharPureit } from "./components/pages/brandpage/pureit/biharpureit";
import { BengalWhirlpool } from "./components/pages/brandpage/whirlpool/bengalwhirlpool";
import { JharkhandWhirlpool } from "./components/pages/brandpage/whirlpool/jharkhandwhirlpool";
import { BiharWhirlpool } from "./components/pages/brandpage/whirlpool/biharwhirlpool";
import { BiharBluestar } from "./components/pages/brandpage/bluestar/biharbluestar";
import { JharkhandBluestar } from "./components/pages/brandpage/bluestar/jharkhandbluestar";
import { BengalBluestar } from "./components/pages/brandpage/bluestar/bengalbluestar";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {
  /*
  const imageUrlkent = 'img/brandcover/kent.jpg';
  const imageUrlAOSmith = 'img/brandcover/aosmith.jpg';
  const imageUrlAquaguard = 'img/brandcover/aquaguard.jpg';
  const imageUrlAquaFresh = 'img/brandcover/aquafreshro.jpg';
  const imageUrlBlueStar = 'img/brandcover/bluestarro.jpg';
  const imageUrlEurekaForbes = 'img/brandcover/eduraka.jpg';
  const imageUrlHavells = 'img/brandcover/havelsro.jpg';
  const imageUrlLG = 'img/brandcover/lgro.jpg';
  const imageUrlLivpure = 'img/brandcover/livepure.jpg';
  const imageUrlMarQ = 'img/brandcover/marqro.jpg';
  const imageUrlPureit = 'img/brandcover/pureit.jpg';
  const imageUrlWhirlpool = 'img/brandcover/whirlpool.jpg';

  and use in component imageUrl={imageUrlAquaFresh}
  */


  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
    
      <div className='social'>
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=919031062011" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phone" href="tel:9031062011">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>
      <Navigation />

      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="*" element={<Errorpage />} />
        <Route exact path="/thanks" element={<Thanks />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/return-policy" element={<Returnypolicy />} />
        <Route path="/help-center" element={<Helpcenter />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/faq" element={<Faqp />} />

        <Route path="/ro-service" element={<Usefulnearme title={locdata.RoService.title} number={locdata.RoService.number} />} />
        <Route path="/buy-water-purifier" element={<Usefulnearme title={locdata.WaterPurifier.title} number={locdata.WaterPurifier.number} />} />
        <Route path="/water-purifier-installation" element={<Usefulnearme title={locdata.Installation.title} number={locdata.Installation.number} />} />
        <Route path="/amc-plans" element={<Usefulnearme title={locdata.AMCPlans.title} number={locdata.AMCPlans.number} />} />
        <Route path="/customer-care" element={<Usefulnearme title={locdata.CustomerCare.title} number={locdata.CustomerCare.number} />} />
        <Route path="/charges" element={<Usefulnearme title={locdata.Charges.title} number={locdata.Charges.number} />} />
        <Route path="/ro-service-kit" element={<Usefulnearme title={locdata.ServiceKit.title} number={locdata.ServiceKit.number} />} />
        <Route path="/ro-plant" element={<Usefulnearme title={locdata.Plant.title} number={locdata.Plant.number} />} />
        <Route path="/ro-softener" element={<Usefulnearme title={locdata.Softener.title} number={locdata.Softener.number} />} />
        <Route path="/water-purifier-spare" element={<Usefulnearme title={locdata.Spare.title} number={locdata.Spare.number} />} />
        <Route path="/dm-plant-manufacturers" element={<Usefulnearme title={locdata.Manufacturers.title} number={locdata.Manufacturers.number} />} />
        <Route path="/sewage-treatment-plant-manufacturers" element={<Usefulnearme title={locdata.SewageTreatment.title} number={locdata.SewageTreatment.number} />} />
        <Route path="/atm" element={<Usefulnearme title={locdata.ATM.title} number={locdata.ATM.number} />} />
        <Route path="/effluent-treatment-manufacturers" element={<Usefulnearme title={locdata.EffluentTreatment.title} number={locdata.EffluentTreatment.number} />} />


        <Route path="/ro-service-Araria" element={<Bihar title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/ro-service-Arwal" element={<Bihar title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/ro-service-Aurangabad" element={<Bihar title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/ro-service-Banka" element={<Bihar title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/ro-service-Begusarai" element={<Bihar title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/ro-service-Bhabua" element={<Bihar title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/ro-service-Bhagalpur" element={<Bihar title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/ro-service-Bhojpur" element={<Bihar title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/ro-service-Bihar-Sharif" element={<Bihar title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/ro-service-Buxar" element={<Bihar title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/ro-service-Chhapra" element={<Bihar title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/ro-service-Darbhanga" element={<Bihar title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/ro-service-East-Champaran" element={<Bihar title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/ro-service-Gaya" element={<Bihar title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/ro-service-Gopalganj" element={<Bihar title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/ro-service-Jamui" element={<Bihar title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/ro-service-Jehanabad" element={<Bihar title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/ro-service-Khagaria" element={<Bihar title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/ro-service-Kishanganj" element={<Bihar title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/ro-service-Kaimur" element={<Bihar title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/ro-service-Katihar" element={<Bihar title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/ro-service-Lakhisarai" element={<Bihar title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/ro-service-Madhubani" element={<Bihar title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/ro-service-Munger" element={<Bihar title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/ro-service-Madhepura" element={<Bihar title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/ro-service-Muzaffarpur" element={<Bihar title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/ro-service-Nalanda" element={<Bihar title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/ro-service-Nawada" element={<Bihar title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/ro-service-Patna" element={<Bihar title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/ro-service-Purnia" element={<Bihar title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/ro-service-Rohtas" element={<Bihar title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/ro-service-Saharsa" element={<Bihar title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/ro-service-Samastipur" element={<Bihar title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/ro-service-Sasaram" element={<Bihar title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/ro-service-Sheohar" element={<Bihar title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/ro-service-Sheikhpura" element={<Bihar title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/ro-service-Saran" element={<Bihar title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/ro-service-Sitamarhi" element={<Bihar title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/ro-service-Supaul" element={<Bihar title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/ro-service-Siwan" element={<Bihar title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/ro-service-Vaishali" element={<Bihar title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/ro-service-West-Champaran" element={<Bihar title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/ro-service-Garhwa" element={<Jharkhand title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/ro-service-Palamu" element={<Jharkhand title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/ro-service-Latehar" element={<Jharkhand title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/ro-service-Chatra" element={<Jharkhand title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/ro-service-Hazaribagh" element={<Jharkhand title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/ro-service-Jamshedpur" element={<Jharkhand title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/ro-service-Koderma" element={<Jharkhand title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/ro-service-Giridih" element={<Jharkhand title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/ro-service-Ramgarh" element={<Jharkhand title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/ro-service-Bokaro" element={<Jharkhand title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/ro-service-Dhanbad" element={<Jharkhand title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/ro-service-Lohardaga" element={<Jharkhand title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/ro-service-Gumla" element={<Jharkhand title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/ro-service-Simdega" element={<Jharkhand title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/ro-service-Ranchi" element={<Jharkhand title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/ro-service-Khunti" element={<Jharkhand title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/ro-service-Singhbhum" element={<Jharkhand title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/ro-service-Saraikela-Kharsawan" element={<Jharkhand title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/ro-service-Jamtara" element={<Jharkhand title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/ro-service-Deoghar" element={<Jharkhand title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/ro-service-Dumka" element={<Jharkhand title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/ro-service-Pakur" element={<Jharkhand title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/ro-service-Godda" element={<Jharkhand title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/ro-service-Sahebganj" element={<Jharkhand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />


        <Route path="/ro-service-Alipurduar" element={<Bengal title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/ro-service-Bankura" element={<Bengal title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/ro-service-Birbhum" element={<Bengal title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/ro-service-Cooch-Behar" element={<Bengal title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/ro-service-Dakshin-Dinajpur" element={<Bengal title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/ro-service-Darjeeling" element={<Bengal title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/ro-service-Durgapur" element={<Bengal title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/ro-service-Hooghly" element={<Bengal title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/ro-service-Howrah" element={<Bengal title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/ro-service-Jalpaiguri" element={<Bengal title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/ro-service-Jhargram" element={<Bengal title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/ro-service-Kalimpong" element={<Bengal title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/ro-service-Kolkata" element={<Bengal title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/ro-service-Malda" element={<Bengal title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/ro-service-Murshidabad" element={<Bengal title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/ro-service-Nadia" element={<Bengal title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/ro-service-North-24-Parganas" element={<Bengal title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/ro-service-Paschim-Bardhaman" element={<Bengal title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/ro-service-Paschim-Medinipur" element={<Bengal title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/ro-service-Purba-Bardhaman" element={<Bengal title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/ro-service-Purba-Medinipur" element={<Bengal title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/ro-service-South-24-Parganas" element={<Bengal title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/ro-service-Uttar-Dinajpur" element={<Bengal title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/ro-service-Mumbai" element={<Popularcitydata title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/ro-service-Delhi" element={<Popularcitydata title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/ro-service-Bengaluru" element={<Popularcitydata title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/ro-service-Hyderabad" element={<Popularcitydata title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/ro-service-Ahmedabad" element={<Popularcitydata title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/ro-service-Chennai" element={<Popularcitydata title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/ro-service-Surat" element={<Popularcitydata title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/ro-service-Pune" element={<Popularcitydata title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/ro-service-Jaipur" element={<Popularcitydata title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/ro-service-Lucknow" element={<Popularcitydata title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/ro-service-Kanpur" element={<Popularcitydata title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/ro-service-Nagpur" element={<Popularcitydata title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/ro-service-Indore" element={<Popularcitydata title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/ro-service-Thane" element={<Popularcitydata title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/ro-service-Bhopal" element={<Popularcitydata title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/ro-service-Visakhapatnam" element={<Popularcitydata title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/ro-service-Vadodara" element={<Popularcitydata title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/ro-service-Ghaziabad" element={<Popularcitydata title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/ro-service-Ludhiana" element={<Popularcitydata title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/ro-service-Agra" element={<Popularcitydata title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/ro-service-Nashik" element={<Popularcitydata title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/ro-service-Faridabad" element={<Popularcitydata title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/ro-service-Meerut" element={<Popularcitydata title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/ro-service-Rajkot" element={<Popularcitydata title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/ro-service-Varanasi" element={<Popularcitydata title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/ro-service-Srinagar" element={<Popularcitydata title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/ro-service-Amritsar" element={<Popularcitydata title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/ro-service-Navi-Mumbai" element={<Popularcitydata title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/ro-service-Prayagraj" element={<Popularcitydata title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/ro-service-Jabalpur" element={<Popularcitydata title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/ro-service-Gwalior" element={<Popularcitydata title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/ro-service-Coimbatore" element={<Popularcitydata title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/ro-service-Vijayawada" element={<Popularcitydata title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/ro-service-Jodhpur" element={<Popularcitydata title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/ro-service-Madurai" element={<Popularcitydata title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/ro-service-Raipur" element={<Popularcitydata title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/ro-service-Kota" element={<Popularcitydata title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/ro-service-Guwahati" element={<Popularcitydata title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/ro-service-Chandigarh" element={<Popularcitydata title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/ro-service-Thiruvananthapuram" element={<Popularcitydata title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/ro-service-Solapur" element={<Popularcitydata title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/ro-service-Tiruppur" element={<Popularcitydata title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/ro-service-Moradabad" element={<Popularcitydata title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/ro-service-Mysore" element={<Popularcitydata title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/ro-service-Gurgaon" element={<Popularcitydata title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/ro-service-Aligarh" element={<Popularcitydata title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/ro-service-Jalandhar" element={<Popularcitydata title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/ro-service-Bhubaneswar" element={<Popularcitydata title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/ro-service-Noida" element={<Popularcitydata title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/ro-service-Jamshedpur" element={<Popularcitydata title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/ro-service-Cuttack" element={<Popularcitydata title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/ro-service-Bhavnagar" element={<Popularcitydata title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/ro-service-Dehradun" element={<Popularcitydata title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/ro-service-Rourkela" element={<Popularcitydata title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/ro-service-Jamnagar" element={<Popularcitydata title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/ro-service-Ujjain" element={<Popularcitydata title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/ro-service-Jammu" element={<Popularcitydata title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/ro-service-Mangalore" element={<Popularcitydata title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/ro-service-Erode" element={<Popularcitydata title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/ro-service-Udaipur" element={<Popularcitydata title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/ro-service-Rajahmundry" element={<Popularcitydata title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/ro-service-Patiala" element={<Popularcitydata title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/ro-service-Agartala" element={<Popularcitydata title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/ro-service-Tirupati" element={<Popularcitydata title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/ro-service-Sambalpur" element={<Popularcitydata title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/ro-service-Bilaspur" element={<Popularcitydata title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/ro-service-Alwar" element={<Popularcitydata title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/ro-service-Bardhaman" element={<Popularcitydata title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/ro-service-Sonipat" element={<Popularcitydata title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/ro-service-New-Delhi" element={<Popularcitydata title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/ro-service-Puducherry" element={<Popularcitydata title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/ro-service-Haridwar" element={<Popularcitydata title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/ro-service-Vijayanagaram" element={<Popularcitydata title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/ro-service-Sri-Ganganagar" element={<Popularcitydata title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/ro-service-Secunderabad" element={<Popularcitydata title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/ro-service-Yamunanagar" element={<Popularcitydata title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/ro-service-Gandhinagar" element={<Popularcitydata title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/ro-service-Ambala" element={<Popularcitydata title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/ro-service-Vellore" element={<Popularcitydata title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/ro-service-Shimla" element={<Popularcitydata title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/ro-service-Amaravati" element={<Popularcitydata title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />

        <Route path="/ro-service-Kent" element={<Kentro title={rocompany.Kent.title} number={rocompany.Kent.number}  />} />
        <Route path="/ro-service-AOSmith" element={<AOSmithro title={rocompany.AOSmith.title} number={rocompany.AOSmith.number} />} />
        <Route path="/ro-service-Aquaguard" element={<Aquaguardro title={rocompany.Aquaguard.title} number={rocompany.Aquaguard.number} />} />
        <Route path="/ro-service-AquaFresh" element={<Aquafreshro title={rocompany.AquaFresh.title} number={rocompany.AquaFresh.number} />} />
        <Route path="/ro-service-BlueStar" element={<BlueStarro title={rocompany.BlueStar.title} number={rocompany.BlueStar.number} />} />
        <Route path="/ro-service-EurekaForbes" element={<Eurekaforbesro title={rocompany.EurekaForbes.title} number={rocompany.EurekaForbes.number} />} />
        <Route path="/ro-service-Havells" element={<Havellsro title={rocompany.Havells.title} number={rocompany.Havells.number} />} />
        <Route path="/ro-service-LG" element={<LGro title={rocompany.LG.title} number={rocompany.LG.number} />} />
        <Route path="/ro-service-Livpure" element={<Livepurero title={rocompany.Livpure.title} number={rocompany.Livpure.number} />} />
        <Route path="/ro-service-MarQ" element={<MarQro title={rocompany.MarQ.title} number={rocompany.MarQ.number}  />} />
        <Route path="/ro-service-Pureit" element={<PureItro title={rocompany.Pureit.title} number={rocompany.Pureit.number}  />} />
        <Route path="/ro-service-Whirlpool" element={<Whirlpoolro title={rocompany.Whirlpool.title} number={rocompany.Whirlpool.number}  />} />
        <Route path="/ro-service-Kutchina" element={<Kuchinaro title={rocompany.MarQ.title} number={rocompany.MarQ.number}  />} />
        <Route path="/ro-service-Aquagrand" element={<Aquagrandro title={rocompany.Pureit.title} number={rocompany.Pureit.number}  />} />
        

        <Route path="/aquafresh-ro-service-Araria" element={<BiharAquafresh title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/aquafresh-ro-service-Arwal" element={<BiharAquafresh title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/aquafresh-ro-service-Aurangabad" element={<BiharAquafresh title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/aquafresh-ro-service-Banka" element={<BiharAquafresh title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/aquafresh-ro-service-Begusarai" element={<BiharAquafresh title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/aquafresh-ro-service-Bhabua" element={<BiharAquafresh title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/aquafresh-ro-service-Bhagalpur" element={<BiharAquafresh title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/aquafresh-ro-service-Bhojpur" element={<BiharAquafresh title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/aquafresh-ro-service-Bihar-Sharif" element={<BiharAquafresh title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/aquafresh-ro-service-Buxar" element={<BiharAquafresh title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/aquafresh-ro-service-Chhapra" element={<BiharAquafresh title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/aquafresh-ro-service-Darbhanga" element={<BiharAquafresh title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/aquafresh-ro-service-East-Champaran" element={<BiharAquafresh title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/aquafresh-ro-service-Gaya" element={<BiharAquafresh title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/aquafresh-ro-service-Gopalganj" element={<BiharAquafresh title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/aquafresh-ro-service-Jamui" element={<BiharAquafresh title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/aquafresh-ro-service-Jehanabad" element={<BiharAquafresh title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/aquafresh-ro-service-Khagaria" element={<BiharAquafresh title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/aquafresh-ro-service-Kishanganj" element={<BiharAquafresh title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/aquafresh-ro-service-Kaimur" element={<BiharAquafresh title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/aquafresh-ro-service-Katihar" element={<BiharAquafresh title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/aquafresh-ro-service-Lakhisarai" element={<BiharAquafresh title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/aquafresh-ro-service-Madhubani" element={<BiharAquafresh title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/aquafresh-ro-service-Munger" element={<BiharAquafresh title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/aquafresh-ro-service-Madhepura" element={<BiharAquafresh title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/aquafresh-ro-service-Muzaffarpur" element={<BiharAquafresh title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/aquafresh-ro-service-Nalanda" element={<BiharAquafresh title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/aquafresh-ro-service-Nawada" element={<BiharAquafresh title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/aquafresh-ro-service-Patna" element={<BiharAquafresh title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/aquafresh-ro-service-Purnia" element={<BiharAquafresh title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/aquafresh-ro-service-Rohtas" element={<BiharAquafresh title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/aquafresh-ro-service-Saharsa" element={<BiharAquafresh title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/aquafresh-ro-service-Samastipur" element={<BiharAquafresh title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/aquafresh-ro-service-Sasaram" element={<BiharAquafresh title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/aquafresh-ro-service-Sheohar" element={<BiharAquafresh title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/aquafresh-ro-service-Sheikhpura" element={<BiharAquafresh title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/aquafresh-ro-service-Saran" element={<BiharAquafresh title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/aquafresh-ro-service-Sitamarhi" element={<BiharAquafresh title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/aquafresh-ro-service-Supaul" element={<BiharAquafresh title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/aquafresh-ro-service-Siwan" element={<BiharAquafresh title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/aquafresh-ro-service-Vaishali" element={<BiharAquafresh title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/aquafresh-ro-service-West-Champaran" element={<BiharAquafresh title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/aquafresh-ro-service-Garhwa" element={<JharkhandAquafresh title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/aquafresh-ro-service-Palamu" element={<JharkhandAquafresh title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/aquafresh-ro-service-Latehar" element={<JharkhandAquafresh title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/aquafresh-ro-service-Chatra" element={<JharkhandAquafresh title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/aquafresh-ro-service-Hazaribagh" element={<JharkhandAquafresh title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/aquafresh-ro-service-Jamshedpur" element={<JharkhandAquafresh title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquafresh-ro-service-Koderma" element={<JharkhandAquafresh title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/aquafresh-ro-service-Giridih" element={<JharkhandAquafresh title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/aquafresh-ro-service-Ramgarh" element={<JharkhandAquafresh title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/aquafresh-ro-service-Bokaro" element={<JharkhandAquafresh title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/aquafresh-ro-service-Dhanbad" element={<JharkhandAquafresh title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/aquafresh-ro-service-Lohardaga" element={<JharkhandAquafresh title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/aquafresh-ro-service-Gumla" element={<JharkhandAquafresh title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/aquafresh-ro-service-Simdega" element={<JharkhandAquafresh title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/aquafresh-ro-service-Ranchi" element={<JharkhandAquafresh title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/aquafresh-ro-service-Khunti" element={<JharkhandAquafresh title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/aquafresh-ro-service-Singhbhum" element={<JharkhandAquafresh title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/aquafresh-ro-service-Saraikela-Kharsawan" element={<JharkhandAquafresh title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/aquafresh-ro-service-Jamtara" element={<JharkhandAquafresh title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/aquafresh-ro-service-Deoghar" element={<JharkhandAquafresh title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/aquafresh-ro-service-Dumka" element={<JharkhandAquafresh title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/aquafresh-ro-service-Pakur" element={<JharkhandAquafresh title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/aquafresh-ro-service-Godda" element={<JharkhandAquafresh title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/aquafresh-ro-service-Sahebganj" element={<JharkhandAquafresh title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />
        <Route path="/aquafresh-ro-service-Alipurduar" element={<BengalAquafresh title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/aquafresh-ro-service-Bankura" element={<BengalAquafresh title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/aquafresh-ro-service-Birbhum" element={<BengalAquafresh title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/aquafresh-ro-service-Cooch-Behar" element={<BengalAquafresh title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/aquafresh-ro-service-Dakshin-Dinajpur" element={<BengalAquafresh title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/aquafresh-ro-service-Darjeeling" element={<BengalAquafresh title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/aquafresh-ro-service-Durgapur" element={<BengalAquafresh title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/aquafresh-ro-service-Hooghly" element={<BengalAquafresh title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/aquafresh-ro-service-Howrah" element={<BengalAquafresh title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/aquafresh-ro-service-Jalpaiguri" element={<BengalAquafresh title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/aquafresh-ro-service-Jhargram" element={<BengalAquafresh title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/aquafresh-ro-service-Kalimpong" element={<BengalAquafresh title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/aquafresh-ro-service-Kolkata" element={<BengalAquafresh title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/aquafresh-ro-service-Malda" element={<BengalAquafresh title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/aquafresh-ro-service-Murshidabad" element={<BengalAquafresh title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/aquafresh-ro-service-Nadia" element={<BengalAquafresh title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/aquafresh-ro-service-North-24-Parganas" element={<BengalAquafresh title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/aquafresh-ro-service-Paschim-Bardhaman" element={<BengalAquafresh title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/aquafresh-ro-service-Paschim-Medinipur" element={<BengalAquafresh title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/aquafresh-ro-service-Purba-Bardhaman" element={<BengalAquafresh title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/aquafresh-ro-service-Purba-Medinipur" element={<BengalAquafresh title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/aquafresh-ro-service-South-24-Parganas" element={<BengalAquafresh title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/aquafresh-ro-service-Uttar-Dinajpur" element={<BengalAquafresh title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        
        <Route path="/aquafresh-ro-service-Mumbai" element={<Aquafreshro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/aquafresh-ro-service-Delhi" element={<Aquafreshro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/aquafresh-ro-service-Bengaluru" element={<Aquafreshro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/aquafresh-ro-service-Hyderabad" element={<Aquafreshro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/aquafresh-ro-service-Ahmedabad" element={<Aquafreshro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/aquafresh-ro-service-Chennai" element={<Aquafreshro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/aquafresh-ro-service-Surat" element={<Aquafreshro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/aquafresh-ro-service-Pune" element={<Aquafreshro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/aquafresh-ro-service-Jaipur" element={<Aquafreshro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquafresh-ro-service-Lucknow" element={<Aquafreshro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquafresh-ro-service-Kanpur" element={<Aquafreshro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquafresh-ro-service-Nagpur" element={<Aquafreshro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/aquafresh-ro-service-Indore" element={<Aquafreshro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquafresh-ro-service-Thane" element={<Aquafreshro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/aquafresh-ro-service-Bhopal" element={<Aquafreshro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquafresh-ro-service-Visakhapatnam" element={<Aquafreshro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/aquafresh-ro-service-Vadodara" element={<Aquafreshro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/aquafresh-ro-service-Ghaziabad" element={<Aquafreshro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquafresh-ro-service-Ludhiana" element={<Aquafreshro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/aquafresh-ro-service-Agra" element={<Aquafreshro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquafresh-ro-service-Nashik" element={<Aquafreshro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/aquafresh-ro-service-Faridabad" element={<Aquafreshro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/aquafresh-ro-service-Meerut" element={<Aquafreshro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquafresh-ro-service-Rajkot" element={<Aquafreshro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/aquafresh-ro-service-Varanasi" element={<Aquafreshro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquafresh-ro-service-Srinagar" element={<Aquafreshro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/aquafresh-ro-service-Amritsar" element={<Aquafreshro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/aquafresh-ro-service-Navi-Mumbai" element={<Aquafreshro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/aquafresh-ro-service-Prayagraj" element={<Aquafreshro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquafresh-ro-service-Jabalpur" element={<Aquafreshro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquafresh-ro-service-Gwalior" element={<Aquafreshro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquafresh-ro-service-Coimbatore" element={<Aquafreshro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/aquafresh-ro-service-Vijayawada" element={<Aquafreshro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/aquafresh-ro-service-Jodhpur" element={<Aquafreshro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquafresh-ro-service-Madurai" element={<Aquafreshro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/aquafresh-ro-service-Raipur" element={<Aquafreshro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/aquafresh-ro-service-Kota" element={<Aquafreshro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquafresh-ro-service-Guwahati" element={<Aquafreshro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/aquafresh-ro-service-Chandigarh" element={<Aquafreshro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/aquafresh-ro-service-Thiruvananthapuram" element={<Aquafreshro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/aquafresh-ro-service-Solapur" element={<Aquafreshro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/aquafresh-ro-service-Tiruppur" element={<Aquafreshro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/aquafresh-ro-service-Moradabad" element={<Aquafreshro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquafresh-ro-service-Mysore" element={<Aquafreshro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/aquafresh-ro-service-Gurgaon" element={<Aquafreshro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/aquafresh-ro-service-Aligarh" element={<Aquafreshro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquafresh-ro-service-Jalandhar" element={<Aquafreshro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/aquafresh-ro-service-Bhubaneswar" element={<Aquafreshro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/aquafresh-ro-service-Noida" element={<Aquafreshro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquafresh-ro-service-Jamshedpur" element={<Aquafreshro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquafresh-ro-service-Cuttack" element={<Aquafreshro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/aquafresh-ro-service-Bhavnagar" element={<Aquafreshro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/aquafresh-ro-service-Dehradun" element={<Aquafreshro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/aquafresh-ro-service-Rourkela" element={<Aquafreshro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/aquafresh-ro-service-Jamnagar" element={<Aquafreshro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/aquafresh-ro-service-Ujjain" element={<Aquafreshro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquafresh-ro-service-Jammu" element={<Aquafreshro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/aquafresh-ro-service-Mangalore" element={<Aquafreshro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/aquafresh-ro-service-Erode" element={<Aquafreshro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/aquafresh-ro-service-Udaipur" element={<Aquafreshro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/aquafresh-ro-service-Rajahmundry" element={<Aquafreshro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/aquafresh-ro-service-Patiala" element={<Aquafreshro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/aquafresh-ro-service-Agartala" element={<Aquafreshro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/aquafresh-ro-service-Tirupati" element={<Aquafreshro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/aquafresh-ro-service-Sambalpur" element={<Aquafreshro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/aquafresh-ro-service-Bilaspur" element={<Aquafreshro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/aquafresh-ro-service-Alwar" element={<Aquafreshro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquafresh-ro-service-Bardhaman" element={<Aquafreshro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/aquafresh-ro-service-Sonipat" element={<Aquafreshro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/aquafresh-ro-service-New-Delhi" element={<Aquafreshro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/aquafresh-ro-service-Puducherry" element={<Aquafreshro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/aquafresh-ro-service-Haridwar" element={<Aquafreshro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/aquafresh-ro-service-Vijayanagaram" element={<Aquafreshro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/aquafresh-ro-service-Sri-Ganganagar" element={<Aquafreshro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquafresh-ro-service-Secunderabad" element={<Aquafreshro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/aquafresh-ro-service-Yamunanagar" element={<Aquafreshro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/aquafresh-ro-service-Gandhinagar" element={<Aquafreshro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/aquafresh-ro-service-Ambala" element={<Aquafreshro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/aquafresh-ro-service-Vellore" element={<Aquafreshro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/aquafresh-ro-service-Shimla" element={<Aquafreshro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/aquafresh-ro-service-Amaravati" element={<Aquafreshro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />
      
        <Route path="/aquagrand-ro-service-Araria" element={<BiharAquagrand title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/aquagrand-ro-service-Arwal" element={<BiharAquagrand title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/aquagrand-ro-service-Aurangabad" element={<BiharAquagrand title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/aquagrand-ro-service-Banka" element={<BiharAquagrand title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/aquagrand-ro-service-Begusarai" element={<BiharAquagrand title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/aquagrand-ro-service-Bhabua" element={<BiharAquagrand title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/aquagrand-ro-service-Bhagalpur" element={<BiharAquagrand title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/aquagrand-ro-service-Bhojpur" element={<BiharAquagrand title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/aquagrand-ro-service-Bihar-Sharif" element={<BiharAquagrand title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/aquagrand-ro-service-Buxar" element={<BiharAquagrand title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/aquagrand-ro-service-Chhapra" element={<BiharAquagrand title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/aquagrand-ro-service-Darbhanga" element={<BiharAquagrand title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/aquagrand-ro-service-East-Champaran" element={<BiharAquagrand title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/aquagrand-ro-service-Gaya" element={<BiharAquagrand title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/aquagrand-ro-service-Gopalganj" element={<BiharAquagrand title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/aquagrand-ro-service-Jamui" element={<BiharAquagrand title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/aquagrand-ro-service-Jehanabad" element={<BiharAquagrand title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/aquagrand-ro-service-Khagaria" element={<BiharAquagrand title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/aquagrand-ro-service-Kishanganj" element={<BiharAquagrand title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/aquagrand-ro-service-Kaimur" element={<BiharAquagrand title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/aquagrand-ro-service-Katihar" element={<BiharAquagrand title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/aquagrand-ro-service-Lakhisarai" element={<BiharAquagrand title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/aquagrand-ro-service-Madhubani" element={<BiharAquagrand title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/aquagrand-ro-service-Munger" element={<BiharAquagrand title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/aquagrand-ro-service-Madhepura" element={<BiharAquagrand title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/aquagrand-ro-service-Muzaffarpur" element={<BiharAquagrand title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/aquagrand-ro-service-Nalanda" element={<BiharAquagrand title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/aquagrand-ro-service-Nawada" element={<BiharAquagrand title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/aquagrand-ro-service-Patna" element={<BiharAquagrand title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/aquagrand-ro-service-Purnia" element={<BiharAquagrand title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/aquagrand-ro-service-Rohtas" element={<BiharAquagrand title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/aquagrand-ro-service-Saharsa" element={<BiharAquagrand title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/aquagrand-ro-service-Samastipur" element={<BiharAquagrand title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/aquagrand-ro-service-Sasaram" element={<BiharAquagrand title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/aquagrand-ro-service-Sheohar" element={<BiharAquagrand title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/aquagrand-ro-service-Sheikhpura" element={<BiharAquagrand title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/aquagrand-ro-service-Saran" element={<BiharAquagrand title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/aquagrand-ro-service-Sitamarhi" element={<BiharAquagrand title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/aquagrand-ro-service-Supaul" element={<BiharAquagrand title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/aquagrand-ro-service-Siwan" element={<BiharAquagrand title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/aquagrand-ro-service-Vaishali" element={<BiharAquagrand title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/aquagrand-ro-service-West-Champaran" element={<BiharAquagrand title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/aquagrand-ro-service-Garhwa" element={<JharkhandAquagrand title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/aquagrand-ro-service-Palamu" element={<JharkhandAquagrand title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/aquagrand-ro-service-Latehar" element={<JharkhandAquagrand title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/aquagrand-ro-service-Chatra" element={<JharkhandAquagrand title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/aquagrand-ro-service-Hazaribagh" element={<JharkhandAquagrand title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/aquagrand-ro-service-Jamshedpur" element={<JharkhandAquagrand title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquagrand-ro-service-Koderma" element={<JharkhandAquagrand title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/aquagrand-ro-service-Giridih" element={<JharkhandAquagrand title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/aquagrand-ro-service-Ramgarh" element={<JharkhandAquagrand title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/aquagrand-ro-service-Bokaro" element={<JharkhandAquagrand title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/aquagrand-ro-service-Dhanbad" element={<JharkhandAquagrand title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/aquagrand-ro-service-Lohardaga" element={<JharkhandAquagrand title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/aquagrand-ro-service-Gumla" element={<JharkhandAquagrand title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/aquagrand-ro-service-Simdega" element={<JharkhandAquagrand title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/aquagrand-ro-service-Ranchi" element={<JharkhandAquagrand title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/aquagrand-ro-service-Khunti" element={<JharkhandAquagrand title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/aquagrand-ro-service-Singhbhum" element={<JharkhandAquagrand title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/aquagrand-ro-service-Saraikela-Kharsawan" element={<JharkhandAquagrand title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/aquagrand-ro-service-Jamtara" element={<JharkhandAquagrand title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/aquagrand-ro-service-Deoghar" element={<JharkhandAquagrand title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/aquagrand-ro-service-Dumka" element={<JharkhandAquagrand title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/aquagrand-ro-service-Pakur" element={<JharkhandAquagrand title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/aquagrand-ro-service-Godda" element={<JharkhandAquagrand title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/aquagrand-ro-service-Sahebganj" element={<JharkhandAquagrand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />
        <Route path="/aquagrand-ro-service-Alipurduar" element={<BengalAquagrand title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/aquagrand-ro-service-Bankura" element={<BengalAquagrand title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/aquagrand-ro-service-Birbhum" element={<BengalAquagrand title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/aquagrand-ro-service-Cooch-Behar" element={<BengalAquagrand title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/aquagrand-ro-service-Dakshin-Dinajpur" element={<BengalAquagrand title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/aquagrand-ro-service-Darjeeling" element={<BengalAquagrand title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/aquagrand-ro-service-Durgapur" element={<BengalAquagrand title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/aquagrand-ro-service-Hooghly" element={<BengalAquagrand title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/aquagrand-ro-service-Howrah" element={<BengalAquagrand title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/aquagrand-ro-service-Jalpaiguri" element={<BengalAquagrand title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/aquagrand-ro-service-Jhargram" element={<BengalAquagrand title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/aquagrand-ro-service-Kalimpong" element={<BengalAquagrand title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/aquagrand-ro-service-Kolkata" element={<BengalAquagrand title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/aquagrand-ro-service-Malda" element={<BengalAquagrand title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/aquagrand-ro-service-Murshidabad" element={<BengalAquagrand title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/aquagrand-ro-service-Nadia" element={<BengalAquagrand title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/aquagrand-ro-service-North-24-Parganas" element={<BengalAquagrand title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/aquagrand-ro-service-Paschim-Bardhaman" element={<BengalAquagrand title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/aquagrand-ro-service-Paschim-Medinipur" element={<BengalAquagrand title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/aquagrand-ro-service-Purba-Bardhaman" element={<BengalAquagrand title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/aquagrand-ro-service-Purba-Medinipur" element={<BengalAquagrand title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/aquagrand-ro-service-South-24-Parganas" element={<BengalAquagrand title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/aquagrand-ro-service-Uttar-Dinajpur" element={<BengalAquagrand title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        
        <Route path="/aquagrand-ro-service-Mumbai" element={<Aquagrandro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/aquagrand-ro-service-Delhi" element={<Aquagrandro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/aquagrand-ro-service-Bengaluru" element={<Aquagrandro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/aquagrand-ro-service-Hyderabad" element={<Aquagrandro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/aquagrand-ro-service-Ahmedabad" element={<Aquagrandro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/aquagrand-ro-service-Chennai" element={<Aquagrandro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/aquagrand-ro-service-Surat" element={<Aquagrandro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/aquagrand-ro-service-Pune" element={<Aquagrandro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/aquagrand-ro-service-Jaipur" element={<Aquagrandro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquagrand-ro-service-Lucknow" element={<Aquagrandro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquagrand-ro-service-Kanpur" element={<Aquagrandro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquagrand-ro-service-Nagpur" element={<Aquagrandro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/aquagrand-ro-service-Indore" element={<Aquagrandro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquagrand-ro-service-Thane" element={<Aquagrandro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/aquagrand-ro-service-Bhopal" element={<Aquagrandro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquagrand-ro-service-Visakhapatnam" element={<Aquagrandro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/aquagrand-ro-service-Vadodara" element={<Aquagrandro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/aquagrand-ro-service-Ghaziabad" element={<Aquagrandro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquagrand-ro-service-Ludhiana" element={<Aquagrandro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/aquagrand-ro-service-Agra" element={<Aquagrandro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquagrand-ro-service-Nashik" element={<Aquagrandro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/aquagrand-ro-service-Faridabad" element={<Aquagrandro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/aquagrand-ro-service-Meerut" element={<Aquagrandro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquagrand-ro-service-Rajkot" element={<Aquagrandro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/aquagrand-ro-service-Varanasi" element={<Aquagrandro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquagrand-ro-service-Srinagar" element={<Aquagrandro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/aquagrand-ro-service-Amritsar" element={<Aquagrandro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/aquagrand-ro-service-Navi-Mumbai" element={<Aquagrandro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/aquagrand-ro-service-Prayagraj" element={<Aquagrandro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquagrand-ro-service-Jabalpur" element={<Aquagrandro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquagrand-ro-service-Gwalior" element={<Aquagrandro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquagrand-ro-service-Coimbatore" element={<Aquagrandro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/aquagrand-ro-service-Vijayawada" element={<Aquagrandro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/aquagrand-ro-service-Jodhpur" element={<Aquagrandro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquagrand-ro-service-Madurai" element={<Aquagrandro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/aquagrand-ro-service-Raipur" element={<Aquagrandro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/aquagrand-ro-service-Kota" element={<Aquagrandro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquagrand-ro-service-Guwahati" element={<Aquagrandro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/aquagrand-ro-service-Chandigarh" element={<Aquagrandro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/aquagrand-ro-service-Thiruvananthapuram" element={<Aquagrandro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/aquagrand-ro-service-Solapur" element={<Aquagrandro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/aquagrand-ro-service-Tiruppur" element={<Aquagrandro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/aquagrand-ro-service-Moradabad" element={<Aquagrandro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquagrand-ro-service-Mysore" element={<Aquagrandro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/aquagrand-ro-service-Gurgaon" element={<Aquagrandro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/aquagrand-ro-service-Aligarh" element={<Aquagrandro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquagrand-ro-service-Jalandhar" element={<Aquagrandro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/aquagrand-ro-service-Bhubaneswar" element={<Aquagrandro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/aquagrand-ro-service-Noida" element={<Aquagrandro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquagrand-ro-service-Jamshedpur" element={<Aquagrandro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquagrand-ro-service-Cuttack" element={<Aquagrandro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/aquagrand-ro-service-Bhavnagar" element={<Aquagrandro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/aquagrand-ro-service-Dehradun" element={<Aquagrandro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/aquagrand-ro-service-Rourkela" element={<Aquagrandro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/aquagrand-ro-service-Jamnagar" element={<Aquagrandro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/aquagrand-ro-service-Ujjain" element={<Aquagrandro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquagrand-ro-service-Jammu" element={<Aquagrandro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/aquagrand-ro-service-Mangalore" element={<Aquagrandro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/aquagrand-ro-service-Erode" element={<Aquagrandro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/aquagrand-ro-service-Udaipur" element={<Aquagrandro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/aquagrand-ro-service-Rajahmundry" element={<Aquagrandro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/aquagrand-ro-service-Patiala" element={<Aquagrandro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/aquagrand-ro-service-Agartala" element={<Aquagrandro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/aquagrand-ro-service-Tirupati" element={<Aquagrandro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/aquagrand-ro-service-Sambalpur" element={<Aquagrandro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/aquagrand-ro-service-Bilaspur" element={<Aquagrandro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/aquagrand-ro-service-Alwar" element={<Aquagrandro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquagrand-ro-service-Bardhaman" element={<Aquagrandro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/aquagrand-ro-service-Sonipat" element={<Aquagrandro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/aquagrand-ro-service-New-Delhi" element={<Aquagrandro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/aquagrand-ro-service-Puducherry" element={<Aquagrandro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/aquagrand-ro-service-Haridwar" element={<Aquagrandro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/aquagrand-ro-service-Vijayanagaram" element={<Aquagrandro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/aquagrand-ro-service-Sri-Ganganagar" element={<Aquagrandro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquagrand-ro-service-Secunderabad" element={<Aquagrandro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/aquagrand-ro-service-Yamunanagar" element={<Aquagrandro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/aquagrand-ro-service-Gandhinagar" element={<Aquagrandro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/aquagrand-ro-service-Ambala" element={<Aquagrandro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/aquagrand-ro-service-Vellore" element={<Aquagrandro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/aquagrand-ro-service-Shimla" element={<Aquagrandro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/aquagrand-ro-service-Amaravati" element={<Aquagrandro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />

        <Route path="/aosmith-ro-service-Araria" element={<BiharAOSmith title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/aosmith-ro-service-Arwal" element={<BiharAOSmith title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/aosmith-ro-service-Aurangabad" element={<BiharAOSmith title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/aosmith-ro-service-Banka" element={<BiharAOSmith title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/aosmith-ro-service-Begusarai" element={<BiharAOSmith title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/aosmith-ro-service-Bhabua" element={<BiharAOSmith title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/aosmith-ro-service-Bhagalpur" element={<BiharAOSmith title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/aosmith-ro-service-Bhojpur" element={<BiharAOSmith title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/aosmith-ro-service-Bihar-Sharif" element={<BiharAOSmith title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/aosmith-ro-service-Buxar" element={<BiharAOSmith title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/aosmith-ro-service-Chhapra" element={<BiharAOSmith title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/aosmith-ro-service-Darbhanga" element={<BiharAOSmith title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/aosmith-ro-service-East-Champaran" element={<BiharAOSmith title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/aosmith-ro-service-Gaya" element={<BiharAOSmith title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/aosmith-ro-service-Gopalganj" element={<BiharAOSmith title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/aosmith-ro-service-Jamui" element={<BiharAOSmith title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/aosmith-ro-service-Jehanabad" element={<BiharAOSmith title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/aosmith-ro-service-Khagaria" element={<BiharAOSmith title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/aosmith-ro-service-Kishanganj" element={<BiharAOSmith title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/aosmith-ro-service-Kaimur" element={<BiharAOSmith title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/aosmith-ro-service-Katihar" element={<BiharAOSmith title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/aosmith-ro-service-Lakhisarai" element={<BiharAOSmith title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/aosmith-ro-service-Madhubani" element={<BiharAOSmith title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/aosmith-ro-service-Munger" element={<BiharAOSmith title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/aosmith-ro-service-Madhepura" element={<BiharAOSmith title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/aosmith-ro-service-Muzaffarpur" element={<BiharAOSmith title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/aosmith-ro-service-Nalanda" element={<BiharAOSmith title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/aosmith-ro-service-Nawada" element={<BiharAOSmith title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/aosmith-ro-service-Patna" element={<BiharAOSmith title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/aosmith-ro-service-Purnia" element={<BiharAOSmith title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/aosmith-ro-service-Rohtas" element={<BiharAOSmith title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/aosmith-ro-service-Saharsa" element={<BiharAOSmith title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/aosmith-ro-service-Samastipur" element={<BiharAOSmith title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/aosmith-ro-service-Sasaram" element={<BiharAOSmith title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/aosmith-ro-service-Sheohar" element={<BiharAOSmith title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/aosmith-ro-service-Sheikhpura" element={<BiharAOSmith title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/aosmith-ro-service-Saran" element={<BiharAOSmith title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/aosmith-ro-service-Sitamarhi" element={<BiharAOSmith title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/aosmith-ro-service-Supaul" element={<BiharAOSmith title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/aosmith-ro-service-Siwan" element={<BiharAOSmith title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/aosmith-ro-service-Vaishali" element={<BiharAOSmith title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/aosmith-ro-service-West-Champaran" element={<BiharAOSmith title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/aosmith-ro-service-Garhwa" element={<JharkhandAOSmith title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/aosmith-ro-service-Palamu" element={<JharkhandAOSmith title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/aosmith-ro-service-Latehar" element={<JharkhandAOSmith title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/aosmith-ro-service-Chatra" element={<JharkhandAOSmith title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/aosmith-ro-service-Hazaribagh" element={<JharkhandAOSmith title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/aosmith-ro-service-Jamshedpur" element={<JharkhandAOSmith title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aosmith-ro-service-Koderma" element={<JharkhandAOSmith title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/aosmith-ro-service-Giridih" element={<JharkhandAOSmith title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/aosmith-ro-service-Ramgarh" element={<JharkhandAOSmith title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/aosmith-ro-service-Bokaro" element={<JharkhandAOSmith title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/aosmith-ro-service-Dhanbad" element={<JharkhandAOSmith title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/aosmith-ro-service-Lohardaga" element={<JharkhandAOSmith title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/aosmith-ro-service-Gumla" element={<JharkhandAOSmith title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/aosmith-ro-service-Simdega" element={<JharkhandAOSmith title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/aosmith-ro-service-Ranchi" element={<JharkhandAOSmith title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/aosmith-ro-service-Khunti" element={<JharkhandAOSmith title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/aosmith-ro-service-Singhbhum" element={<JharkhandAOSmith title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/aosmith-ro-service-Saraikela-Kharsawan" element={<JharkhandAOSmith title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/aosmith-ro-service-Jamtara" element={<JharkhandAOSmith title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/aosmith-ro-service-Deoghar" element={<JharkhandAOSmith title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/aosmith-ro-service-Dumka" element={<JharkhandAOSmith title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/aosmith-ro-service-Pakur" element={<JharkhandAOSmith title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/aosmith-ro-service-Godda" element={<JharkhandAOSmith title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/aosmith-ro-service-Sahebganj" element={<JharkhandAOSmith title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />
        <Route path="/aosmith-ro-service-Alipurduar" element={<BengalAOSmith title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/aosmith-ro-service-Bankura" element={<BengalAOSmith title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/aosmith-ro-service-Birbhum" element={<BengalAOSmith title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/aosmith-ro-service-Cooch-Behar" element={<BengalAOSmith title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/aosmith-ro-service-Dakshin-Dinajpur" element={<BengalAOSmith title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/aosmith-ro-service-Darjeeling" element={<BengalAOSmith title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/aosmith-ro-service-Durgapur" element={<BengalAOSmith title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/aosmith-ro-service-Hooghly" element={<BengalAOSmith title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/aosmith-ro-service-Howrah" element={<BengalAOSmith title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/aosmith-ro-service-Jalpaiguri" element={<BengalAOSmith title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/aosmith-ro-service-Jhargram" element={<BengalAOSmith title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/aosmith-ro-service-Kalimpong" element={<BengalAOSmith title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/aosmith-ro-service-Kolkata" element={<BengalAOSmith title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/aosmith-ro-service-Malda" element={<BengalAOSmith title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/aosmith-ro-service-Murshidabad" element={<BengalAOSmith title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/aosmith-ro-service-Nadia" element={<BengalAOSmith title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/aosmith-ro-service-North-24-Parganas" element={<BengalAOSmith title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/aosmith-ro-service-Paschim-Bardhaman" element={<BengalAOSmith title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/aosmith-ro-service-Paschim-Medinipur" element={<BengalAOSmith title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/aosmith-ro-service-Purba-Bardhaman" element={<BengalAOSmith title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/aosmith-ro-service-Purba-Medinipur" element={<BengalAOSmith title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/aosmith-ro-service-South-24-Parganas" element={<BengalAOSmith title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/aosmith-ro-service-Uttar-Dinajpur" element={<BengalAOSmith title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />
        
        <Route path="/aosmith-ro-service-Mumbai" element={<AOSmithro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/aosmith-ro-service-Delhi" element={<AOSmithro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/aosmith-ro-service-Bengaluru" element={<AOSmithro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/aosmith-ro-service-Hyderabad" element={<AOSmithro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/aosmith-ro-service-Ahmedabad" element={<AOSmithro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/aosmith-ro-service-Chennai" element={<AOSmithro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/aosmith-ro-service-Surat" element={<AOSmithro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/aosmith-ro-service-Pune" element={<AOSmithro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/aosmith-ro-service-Jaipur" element={<AOSmithro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aosmith-ro-service-Lucknow" element={<AOSmithro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aosmith-ro-service-Kanpur" element={<AOSmithro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aosmith-ro-service-Nagpur" element={<AOSmithro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/aosmith-ro-service-Indore" element={<AOSmithro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aosmith-ro-service-Thane" element={<AOSmithro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/aosmith-ro-service-Bhopal" element={<AOSmithro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aosmith-ro-service-Visakhapatnam" element={<AOSmithro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/aosmith-ro-service-Vadodara" element={<AOSmithro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/aosmith-ro-service-Ghaziabad" element={<AOSmithro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aosmith-ro-service-Ludhiana" element={<AOSmithro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/aosmith-ro-service-Agra" element={<AOSmithro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aosmith-ro-service-Nashik" element={<AOSmithro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/aosmith-ro-service-Faridabad" element={<AOSmithro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/aosmith-ro-service-Meerut" element={<AOSmithro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aosmith-ro-service-Rajkot" element={<AOSmithro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/aosmith-ro-service-Varanasi" element={<AOSmithro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aosmith-ro-service-Srinagar" element={<AOSmithro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/aosmith-ro-service-Amritsar" element={<AOSmithro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/aosmith-ro-service-Navi-Mumbai" element={<AOSmithro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/aosmith-ro-service-Prayagraj" element={<AOSmithro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aosmith-ro-service-Jabalpur" element={<AOSmithro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aosmith-ro-service-Gwalior" element={<AOSmithro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aosmith-ro-service-Coimbatore" element={<AOSmithro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/aosmith-ro-service-Vijayawada" element={<AOSmithro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/aosmith-ro-service-Jodhpur" element={<AOSmithro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aosmith-ro-service-Madurai" element={<AOSmithro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/aosmith-ro-service-Raipur" element={<AOSmithro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/aosmith-ro-service-Kota" element={<AOSmithro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aosmith-ro-service-Guwahati" element={<AOSmithro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/aosmith-ro-service-Chandigarh" element={<AOSmithro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/aosmith-ro-service-Thiruvananthapuram" element={<AOSmithro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/aosmith-ro-service-Solapur" element={<AOSmithro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/aosmith-ro-service-Tiruppur" element={<AOSmithro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/aosmith-ro-service-Moradabad" element={<AOSmithro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aosmith-ro-service-Mysore" element={<AOSmithro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/aosmith-ro-service-Gurgaon" element={<AOSmithro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/aosmith-ro-service-Aligarh" element={<AOSmithro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aosmith-ro-service-Jalandhar" element={<AOSmithro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/aosmith-ro-service-Bhubaneswar" element={<AOSmithro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/aosmith-ro-service-Noida" element={<AOSmithro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aosmith-ro-service-Jamshedpur" element={<AOSmithro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aosmith-ro-service-Cuttack" element={<AOSmithro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/aosmith-ro-service-Bhavnagar" element={<AOSmithro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/aosmith-ro-service-Dehradun" element={<AOSmithro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/aosmith-ro-service-Rourkela" element={<AOSmithro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/aosmith-ro-service-Jamnagar" element={<AOSmithro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/aosmith-ro-service-Ujjain" element={<AOSmithro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aosmith-ro-service-Jammu" element={<AOSmithro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/aosmith-ro-service-Mangalore" element={<AOSmithro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/aosmith-ro-service-Erode" element={<AOSmithro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/aosmith-ro-service-Udaipur" element={<AOSmithro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/aosmith-ro-service-Rajahmundry" element={<AOSmithro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/aosmith-ro-service-Patiala" element={<AOSmithro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/aosmith-ro-service-Agartala" element={<AOSmithro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/aosmith-ro-service-Tirupati" element={<AOSmithro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/aosmith-ro-service-Sambalpur" element={<AOSmithro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/aosmith-ro-service-Bilaspur" element={<AOSmithro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/aosmith-ro-service-Alwar" element={<AOSmithro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aosmith-ro-service-Bardhaman" element={<AOSmithro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/aosmith-ro-service-Sonipat" element={<AOSmithro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/aosmith-ro-service-New-Delhi" element={<AOSmithro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/aosmith-ro-service-Puducherry" element={<AOSmithro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/aosmith-ro-service-Haridwar" element={<AOSmithro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/aosmith-ro-service-Vijayanagaram" element={<AOSmithro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/aosmith-ro-service-Sri-Ganganagar" element={<AOSmithro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aosmith-ro-service-Secunderabad" element={<AOSmithro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/aosmith-ro-service-Yamunanagar" element={<AOSmithro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/aosmith-ro-service-Gandhinagar" element={<AOSmithro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/aosmith-ro-service-Ambala" element={<AOSmithro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/aosmith-ro-service-Vellore" element={<AOSmithro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/aosmith-ro-service-Shimla" element={<AOSmithro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/aosmith-ro-service-Amaravati" element={<AOSmithro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/aquaguard-ro-service-Araria" element={<BiharAquaguard title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/aquaguard-ro-service-Arwal" element={<BiharAquaguard title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/aquaguard-ro-service-Aurangabad" element={<BiharAquaguard title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/aquaguard-ro-service-Banka" element={<BiharAquaguard title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/aquaguard-ro-service-Begusarai" element={<BiharAquaguard title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/aquaguard-ro-service-Bhabua" element={<BiharAquaguard title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/aquaguard-ro-service-Bhagalpur" element={<BiharAquaguard title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/aquaguard-ro-service-Bhojpur" element={<BiharAquaguard title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/aquaguard-ro-service-Bihar-Sharif" element={<BiharAquaguard title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/aquaguard-ro-service-Buxar" element={<BiharAquaguard title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/aquaguard-ro-service-Chhapra" element={<BiharAquaguard title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/aquaguard-ro-service-Darbhanga" element={<BiharAquaguard title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/aquaguard-ro-service-East-Champaran" element={<BiharAquaguard title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/aquaguard-ro-service-Gaya" element={<BiharAquaguard title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/aquaguard-ro-service-Gopalganj" element={<BiharAquaguard title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/aquaguard-ro-service-Jamui" element={<BiharAquaguard title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/aquaguard-ro-service-Jehanabad" element={<BiharAquaguard title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/aquaguard-ro-service-Khagaria" element={<BiharAquaguard title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/aquaguard-ro-service-Kishanganj" element={<BiharAquaguard title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/aquaguard-ro-service-Kaimur" element={<BiharAquaguard title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/aquaguard-ro-service-Katihar" element={<BiharAquaguard title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/aquaguard-ro-service-Lakhisarai" element={<BiharAquaguard title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/aquaguard-ro-service-Madhubani" element={<BiharAquaguard title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/aquaguard-ro-service-Munger" element={<BiharAquaguard title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/aquaguard-ro-service-Madhepura" element={<BiharAquaguard title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/aquaguard-ro-service-Muzaffarpur" element={<BiharAquaguard title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/aquaguard-ro-service-Nalanda" element={<BiharAquaguard title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/aquaguard-ro-service-Nawada" element={<BiharAquaguard title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/aquaguard-ro-service-Patna" element={<BiharAquaguard title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/aquaguard-ro-service-Purnia" element={<BiharAquaguard title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/aquaguard-ro-service-Rohtas" element={<BiharAquaguard title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/aquaguard-ro-service-Saharsa" element={<BiharAquaguard title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/aquaguard-ro-service-Samastipur" element={<BiharAquaguard title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/aquaguard-ro-service-Sasaram" element={<BiharAquaguard title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/aquaguard-ro-service-Sheohar" element={<BiharAquaguard title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/aquaguard-ro-service-Sheikhpura" element={<BiharAquaguard title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/aquaguard-ro-service-Saran" element={<BiharAquaguard title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/aquaguard-ro-service-Sitamarhi" element={<BiharAquaguard title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/aquaguard-ro-service-Supaul" element={<BiharAquaguard title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/aquaguard-ro-service-Siwan" element={<BiharAquaguard title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/aquaguard-ro-service-Vaishali" element={<BiharAquaguard title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/aquaguard-ro-service-West-Champaran" element={<BiharAquaguard title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/aquaguard-ro-service-Garhwa" element={<JharkhandAquaguard title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/aquaguard-ro-service-Palamu" element={<JharkhandAquaguard title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/aquaguard-ro-service-Latehar" element={<JharkhandAquaguard title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/aquaguard-ro-service-Chatra" element={<JharkhandAquaguard title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/aquaguard-ro-service-Hazaribagh" element={<JharkhandAquaguard title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/aquaguard-ro-service-Jamshedpur" element={<JharkhandAquaguard title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquaguard-ro-service-Koderma" element={<JharkhandAquaguard title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/aquaguard-ro-service-Giridih" element={<JharkhandAquaguard title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/aquaguard-ro-service-Ramgarh" element={<JharkhandAquaguard title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/aquaguard-ro-service-Bokaro" element={<JharkhandAquaguard title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/aquaguard-ro-service-Dhanbad" element={<JharkhandAquaguard title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/aquaguard-ro-service-Lohardaga" element={<JharkhandAquaguard title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/aquaguard-ro-service-Gumla" element={<JharkhandAquaguard title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/aquaguard-ro-service-Simdega" element={<JharkhandAquaguard title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/aquaguard-ro-service-Ranchi" element={<JharkhandAquaguard title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/aquaguard-ro-service-Khunti" element={<JharkhandAquaguard title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/aquaguard-ro-service-Singhbhum" element={<JharkhandAquaguard title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/aquaguard-ro-service-Saraikela-Kharsawan" element={<JharkhandAquaguard title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/aquaguard-ro-service-Jamtara" element={<JharkhandAquaguard title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/aquaguard-ro-service-Deoghar" element={<JharkhandAquaguard title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/aquaguard-ro-service-Dumka" element={<JharkhandAquaguard title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/aquaguard-ro-service-Pakur" element={<JharkhandAquaguard title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/aquaguard-ro-service-Godda" element={<JharkhandAquaguard title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/aquaguard-ro-service-Sahebganj" element={<JharkhandAquaguard title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />


        <Route path="/aquaguard-ro-service-Alipurduar" element={<BengalAquaguard title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/aquaguard-ro-service-Bankura" element={<BengalAquaguard title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/aquaguard-ro-service-Birbhum" element={<BengalAquaguard title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/aquaguard-ro-service-Cooch-Behar" element={<BengalAquaguard title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/aquaguard-ro-service-Dakshin-Dinajpur" element={<BengalAquaguard title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/aquaguard-ro-service-Darjeeling" element={<BengalAquaguard title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/aquaguard-ro-service-Durgapur" element={<BengalAquaguard title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/aquaguard-ro-service-Hooghly" element={<BengalAquaguard title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/aquaguard-ro-service-Howrah" element={<BengalAquaguard title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/aquaguard-ro-service-Jalpaiguri" element={<BengalAquaguard title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/aquaguard-ro-service-Jhargram" element={<BengalAquaguard title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/aquaguard-ro-service-Kalimpong" element={<BengalAquaguard title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/aquaguard-ro-service-Kolkata" element={<BengalAquaguard title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/aquaguard-ro-service-Malda" element={<BengalAquaguard title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/aquaguard-ro-service-Murshidabad" element={<BengalAquaguard title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/aquaguard-ro-service-Nadia" element={<BengalAquaguard title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/aquaguard-ro-service-North-24-Parganas" element={<BengalAquaguard title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/aquaguard-ro-service-Paschim-Bardhaman" element={<BengalAquaguard title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/aquaguard-ro-service-Paschim-Medinipur" element={<BengalAquaguard title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/aquaguard-ro-service-Purba-Bardhaman" element={<BengalAquaguard title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/aquaguard-ro-service-Purba-Medinipur" element={<BengalAquaguard title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/aquaguard-ro-service-South-24-Parganas" element={<BengalAquaguard title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/aquaguard-ro-service-Uttar-Dinajpur" element={<BengalAquaguard title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/aquaguard-ro-service-Mumbai" element={<Aquaguardro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/aquaguard-ro-service-Delhi" element={<Aquaguardro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/aquaguard-ro-service-Bengaluru" element={<Aquaguardro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/aquaguard-ro-service-Hyderabad" element={<Aquaguardro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/aquaguard-ro-service-Ahmedabad" element={<Aquaguardro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/aquaguard-ro-service-Chennai" element={<Aquaguardro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/aquaguard-ro-service-Surat" element={<Aquaguardro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/aquaguard-ro-service-Pune" element={<Aquaguardro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/aquaguard-ro-service-Jaipur" element={<Aquaguardro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/aquaguard-ro-service-Lucknow" element={<Aquaguardro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/aquaguard-ro-service-Kanpur" element={<Aquaguardro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/aquaguard-ro-service-Nagpur" element={<Aquaguardro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/aquaguard-ro-service-Indore" element={<Aquaguardro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/aquaguard-ro-service-Thane" element={<Aquaguardro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/aquaguard-ro-service-Bhopal" element={<Aquaguardro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/aquaguard-ro-service-Visakhapatnam" element={<Aquaguardro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/aquaguard-ro-service-Vadodara" element={<Aquaguardro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/aquaguard-ro-service-Ghaziabad" element={<Aquaguardro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/aquaguard-ro-service-Ludhiana" element={<Aquaguardro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/aquaguard-ro-service-Agra" element={<Aquaguardro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/aquaguard-ro-service-Nashik" element={<Aquaguardro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/aquaguard-ro-service-Faridabad" element={<Aquaguardro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/aquaguard-ro-service-Meerut" element={<Aquaguardro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/aquaguard-ro-service-Rajkot" element={<Aquaguardro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/aquaguard-ro-service-Varanasi" element={<Aquaguardro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/aquaguard-ro-service-Srinagar" element={<Aquaguardro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/aquaguard-ro-service-Amritsar" element={<Aquaguardro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/aquaguard-ro-service-Navi-Mumbai" element={<Aquaguardro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/aquaguard-ro-service-Prayagraj" element={<Aquaguardro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/aquaguard-ro-service-Jabalpur" element={<Aquaguardro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/aquaguard-ro-service-Gwalior" element={<Aquaguardro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/aquaguard-ro-service-Coimbatore" element={<Aquaguardro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/aquaguard-ro-service-Vijayawada" element={<Aquaguardro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/aquaguard-ro-service-Jodhpur" element={<Aquaguardro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/aquaguard-ro-service-Madurai" element={<Aquaguardro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/aquaguard-ro-service-Raipur" element={<Aquaguardro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/aquaguard-ro-service-Kota" element={<Aquaguardro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/aquaguard-ro-service-Guwahati" element={<Aquaguardro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/aquaguard-ro-service-Chandigarh" element={<Aquaguardro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/aquaguard-ro-service-Thiruvananthapuram" element={<Aquaguardro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/aquaguard-ro-service-Solapur" element={<Aquaguardro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/aquaguard-ro-service-Tiruppur" element={<Aquaguardro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/aquaguard-ro-service-Moradabad" element={<Aquaguardro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/aquaguard-ro-service-Mysore" element={<Aquaguardro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/aquaguard-ro-service-Gurgaon" element={<Aquaguardro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/aquaguard-ro-service-Aligarh" element={<Aquaguardro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/aquaguard-ro-service-Jalandhar" element={<Aquaguardro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/aquaguard-ro-service-Bhubaneswar" element={<Aquaguardro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/aquaguard-ro-service-Noida" element={<Aquaguardro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/aquaguard-ro-service-Jamshedpur" element={<Aquaguardro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/aquaguard-ro-service-Cuttack" element={<Aquaguardro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/aquaguard-ro-service-Bhavnagar" element={<Aquaguardro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/aquaguard-ro-service-Dehradun" element={<Aquaguardro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/aquaguard-ro-service-Rourkela" element={<Aquaguardro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/aquaguard-ro-service-Jamnagar" element={<Aquaguardro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/aquaguard-ro-service-Ujjain" element={<Aquaguardro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/aquaguard-ro-service-Jammu" element={<Aquaguardro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/aquaguard-ro-service-Mangalore" element={<Aquaguardro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/aquaguard-ro-service-Erode" element={<Aquaguardro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/aquaguard-ro-service-Udaipur" element={<Aquaguardro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/aquaguard-ro-service-Rajahmundry" element={<Aquaguardro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/aquaguard-ro-service-Patiala" element={<Aquaguardro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/aquaguard-ro-service-Agartala" element={<Aquaguardro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/aquaguard-ro-service-Tirupati" element={<Aquaguardro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/aquaguard-ro-service-Sambalpur" element={<Aquaguardro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/aquaguard-ro-service-Bilaspur" element={<Aquaguardro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/aquaguard-ro-service-Alwar" element={<Aquaguardro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/aquaguard-ro-service-Bardhaman" element={<Aquaguardro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/aquaguard-ro-service-Sonipat" element={<Aquaguardro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/aquaguard-ro-service-New-Delhi" element={<Aquaguardro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/aquaguard-ro-service-Puducherry" element={<Aquaguardro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/aquaguard-ro-service-Haridwar" element={<Aquaguardro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/aquaguard-ro-service-Vijayanagaram" element={<Aquaguardro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/aquaguard-ro-service-Sri-Ganganagar" element={<Aquaguardro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/aquaguard-ro-service-Secunderabad" element={<Aquaguardro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/aquaguard-ro-service-Yamunanagar" element={<Aquaguardro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/aquaguard-ro-service-Gandhinagar" element={<Aquaguardro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/aquaguard-ro-service-Ambala" element={<Aquaguardro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/aquaguard-ro-service-Vellore" element={<Aquaguardro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/aquaguard-ro-service-Shimla" element={<Aquaguardro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/aquaguard-ro-service-Amaravati" element={<Aquaguardro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/bluestar-ro-service-Araria" element={<BiharBluestar title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/bluestar-ro-service-Arwal" element={<BiharBluestar title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/bluestar-ro-service-Aurangabad" element={<BiharBluestar title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/bluestar-ro-service-Banka" element={<BiharBluestar title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/bluestar-ro-service-Begusarai" element={<BiharBluestar title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/bluestar-ro-service-Bhabua" element={<BiharBluestar title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/bluestar-ro-service-Bhagalpur" element={<BiharBluestar title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/bluestar-ro-service-Bhojpur" element={<BiharBluestar title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/bluestar-ro-service-Bihar-Sharif" element={<BiharBluestar title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/bluestar-ro-service-Buxar" element={<BiharBluestar title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/bluestar-ro-service-Chhapra" element={<BiharBluestar title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/bluestar-ro-service-Darbhanga" element={<BiharBluestar title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/bluestar-ro-service-East-Champaran" element={<BiharBluestar title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/bluestar-ro-service-Gaya" element={<BiharBluestar title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/bluestar-ro-service-Gopalganj" element={<BiharBluestar title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/bluestar-ro-service-Jamui" element={<BiharBluestar title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/bluestar-ro-service-Jehanabad" element={<BiharBluestar title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/bluestar-ro-service-Khagaria" element={<BiharBluestar title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/bluestar-ro-service-Kishanganj" element={<BiharBluestar title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/bluestar-ro-service-Kaimur" element={<BiharBluestar title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/bluestar-ro-service-Katihar" element={<BiharBluestar title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/bluestar-ro-service-Lakhisarai" element={<BiharBluestar title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/bluestar-ro-service-Madhubani" element={<BiharBluestar title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/bluestar-ro-service-Munger" element={<BiharBluestar title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/bluestar-ro-service-Madhepura" element={<BiharBluestar title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/bluestar-ro-service-Muzaffarpur" element={<BiharBluestar title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/bluestar-ro-service-Nalanda" element={<BiharBluestar title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/bluestar-ro-service-Nawada" element={<BiharBluestar title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/bluestar-ro-service-Patna" element={<BiharBluestar title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/bluestar-ro-service-Purnia" element={<BiharBluestar title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/bluestar-ro-service-Rohtas" element={<BiharBluestar title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/bluestar-ro-service-Saharsa" element={<BiharBluestar title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/bluestar-ro-service-Samastipur" element={<BiharBluestar title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/bluestar-ro-service-Sasaram" element={<BiharBluestar title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/bluestar-ro-service-Sheohar" element={<BiharBluestar title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/bluestar-ro-service-Sheikhpura" element={<BiharBluestar title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/bluestar-ro-service-Saran" element={<BiharBluestar title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/bluestar-ro-service-Sitamarhi" element={<BiharBluestar title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/bluestar-ro-service-Supaul" element={<BiharBluestar title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/bluestar-ro-service-Siwan" element={<BiharBluestar title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/bluestar-ro-service-Vaishali" element={<BiharBluestar title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/bluestar-ro-service-West-Champaran" element={<BiharBluestar title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/bluestar-ro-service-Garhwa" element={<JharkhandBluestar title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/bluestar-ro-service-Palamu" element={<JharkhandBluestar title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/bluestar-ro-service-Latehar" element={<JharkhandBluestar title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/bluestar-ro-service-Chatra" element={<JharkhandBluestar title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/bluestar-ro-service-Hazaribagh" element={<JharkhandBluestar title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/bluestar-ro-service-Jamshedpur" element={<JharkhandBluestar title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/bluestar-ro-service-Koderma" element={<JharkhandBluestar title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/bluestar-ro-service-Giridih" element={<JharkhandBluestar title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/bluestar-ro-service-Ramgarh" element={<JharkhandBluestar title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/bluestar-ro-service-Bokaro" element={<JharkhandBluestar title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/bluestar-ro-service-Dhanbad" element={<JharkhandBluestar title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/bluestar-ro-service-Lohardaga" element={<JharkhandBluestar title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/bluestar-ro-service-Gumla" element={<JharkhandBluestar title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/bluestar-ro-service-Simdega" element={<JharkhandBluestar title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/bluestar-ro-service-Ranchi" element={<JharkhandBluestar title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/bluestar-ro-service-Khunti" element={<JharkhandBluestar title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/bluestar-ro-service-Singhbhum" element={<JharkhandBluestar title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/bluestar-ro-service-Saraikela-Kharsawan" element={<JharkhandBluestar title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/bluestar-ro-service-Jamtara" element={<JharkhandBluestar title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/bluestar-ro-service-Deoghar" element={<JharkhandBluestar title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/bluestar-ro-service-Dumka" element={<JharkhandBluestar title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/bluestar-ro-service-Pakur" element={<JharkhandBluestar title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/bluestar-ro-service-Godda" element={<JharkhandBluestar title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/bluestar-ro-service-Sahebganj" element={<JharkhandBluestar title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/bluestar-ro-service-Alipurduar" element={<BengalBluestar title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/bluestar-ro-service-Bankura" element={<BengalBluestar title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/bluestar-ro-service-Birbhum" element={<BengalBluestar title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/bluestar-ro-service-Cooch-Behar" element={<BengalBluestar title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/bluestar-ro-service-Dakshin-Dinajpur" element={<BengalBluestar title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/bluestar-ro-service-Darjeeling" element={<BengalBluestar title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/bluestar-ro-service-Durgapur" element={<BengalBluestar title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/bluestar-ro-service-Hooghly" element={<BengalBluestar title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/bluestar-ro-service-Howrah" element={<BengalBluestar title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/bluestar-ro-service-Jalpaiguri" element={<BengalBluestar title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/bluestar-ro-service-Jhargram" element={<BengalBluestar title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/bluestar-ro-service-Kalimpong" element={<BengalBluestar title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/bluestar-ro-service-Kolkata" element={<BengalBluestar title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/bluestar-ro-service-Malda" element={<BengalBluestar title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/bluestar-ro-service-Murshidabad" element={<BengalBluestar title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/bluestar-ro-service-Nadia" element={<BengalBluestar title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/bluestar-ro-service-North-24-Parganas" element={<BengalBluestar title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/bluestar-ro-service-Paschim-Bardhaman" element={<BengalBluestar title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/bluestar-ro-service-Paschim-Medinipur" element={<BengalBluestar title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/bluestar-ro-service-Purba-Bardhaman" element={<BengalBluestar title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/bluestar-ro-service-Purba-Medinipur" element={<BengalBluestar title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/bluestar-ro-service-South-24-Parganas" element={<BengalBluestar title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/bluestar-ro-service-Uttar-Dinajpur" element={<BengalBluestar title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/bluestar-ro-service-Mumbai" element={<BlueStarro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/bluestar-ro-service-Delhi" element={<BlueStarro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/bluestar-ro-service-Bengaluru" element={<BlueStarro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/bluestar-ro-service-Hyderabad" element={<BlueStarro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/bluestar-ro-service-Ahmedabad" element={<BlueStarro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/bluestar-ro-service-Chennai" element={<BlueStarro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/bluestar-ro-service-Surat" element={<BlueStarro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/bluestar-ro-service-Pune" element={<BlueStarro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/bluestar-ro-service-Jaipur" element={<BlueStarro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/bluestar-ro-service-Lucknow" element={<BlueStarro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/bluestar-ro-service-Kanpur" element={<BlueStarro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/bluestar-ro-service-Nagpur" element={<BlueStarro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/bluestar-ro-service-Indore" element={<BlueStarro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/bluestar-ro-service-Thane" element={<BlueStarro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/bluestar-ro-service-Bhopal" element={<BlueStarro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/bluestar-ro-service-Visakhapatnam" element={<BlueStarro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/bluestar-ro-service-Vadodara" element={<BlueStarro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/bluestar-ro-service-Ghaziabad" element={<BlueStarro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/bluestar-ro-service-Ludhiana" element={<BlueStarro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/bluestar-ro-service-Agra" element={<BlueStarro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/bluestar-ro-service-Nashik" element={<BlueStarro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/bluestar-ro-service-Faridabad" element={<BlueStarro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/bluestar-ro-service-Meerut" element={<BlueStarro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/bluestar-ro-service-Rajkot" element={<BlueStarro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/bluestar-ro-service-Varanasi" element={<BlueStarro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/bluestar-ro-service-Srinagar" element={<BlueStarro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/bluestar-ro-service-Amritsar" element={<BlueStarro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/bluestar-ro-service-Navi-Mumbai" element={<BlueStarro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/bluestar-ro-service-Prayagraj" element={<BlueStarro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/bluestar-ro-service-Jabalpur" element={<BlueStarro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/bluestar-ro-service-Gwalior" element={<BlueStarro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/bluestar-ro-service-Coimbatore" element={<BlueStarro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/bluestar-ro-service-Vijayawada" element={<BlueStarro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/bluestar-ro-service-Jodhpur" element={<BlueStarro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/bluestar-ro-service-Madurai" element={<BlueStarro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/bluestar-ro-service-Raipur" element={<BlueStarro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/bluestar-ro-service-Kota" element={<BlueStarro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/bluestar-ro-service-Guwahati" element={<BlueStarro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/bluestar-ro-service-Chandigarh" element={<BlueStarro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/bluestar-ro-service-Thiruvananthapuram" element={<BlueStarro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/bluestar-ro-service-Solapur" element={<BlueStarro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/bluestar-ro-service-Tiruppur" element={<BlueStarro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/bluestar-ro-service-Moradabad" element={<BlueStarro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/bluestar-ro-service-Mysore" element={<BlueStarro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/bluestar-ro-service-Gurgaon" element={<BlueStarro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/bluestar-ro-service-Aligarh" element={<BlueStarro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/bluestar-ro-service-Jalandhar" element={<BlueStarro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/bluestar-ro-service-Bhubaneswar" element={<BlueStarro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/bluestar-ro-service-Noida" element={<BlueStarro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/bluestar-ro-service-Jamshedpur" element={<BlueStarro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/bluestar-ro-service-Cuttack" element={<BlueStarro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/bluestar-ro-service-Bhavnagar" element={<BlueStarro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/bluestar-ro-service-Dehradun" element={<BlueStarro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/bluestar-ro-service-Rourkela" element={<BlueStarro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/bluestar-ro-service-Jamnagar" element={<BlueStarro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/bluestar-ro-service-Ujjain" element={<BlueStarro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/bluestar-ro-service-Jammu" element={<BlueStarro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/bluestar-ro-service-Mangalore" element={<BlueStarro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/bluestar-ro-service-Erode" element={<BlueStarro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/bluestar-ro-service-Udaipur" element={<BlueStarro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/bluestar-ro-service-Rajahmundry" element={<BlueStarro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/bluestar-ro-service-Patiala" element={<BlueStarro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/bluestar-ro-service-Agartala" element={<BlueStarro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/bluestar-ro-service-Tirupati" element={<BlueStarro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/bluestar-ro-service-Sambalpur" element={<BlueStarro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/bluestar-ro-service-Bilaspur" element={<BlueStarro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/bluestar-ro-service-Alwar" element={<BlueStarro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/bluestar-ro-service-Bardhaman" element={<BlueStarro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/bluestar-ro-service-Sonipat" element={<BlueStarro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/bluestar-ro-service-New-Delhi" element={<BlueStarro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/bluestar-ro-service-Puducherry" element={<BlueStarro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/bluestar-ro-service-Haridwar" element={<BlueStarro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/bluestar-ro-service-Vijayanagaram" element={<BlueStarro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/bluestar-ro-service-Sri-Ganganagar" element={<BlueStarro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/bluestar-ro-service-Secunderabad" element={<BlueStarro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/bluestar-ro-service-Yamunanagar" element={<BlueStarro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/bluestar-ro-service-Gandhinagar" element={<BlueStarro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/bluestar-ro-service-Ambala" element={<BlueStarro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/bluestar-ro-service-Vellore" element={<BlueStarro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/bluestar-ro-service-Shimla" element={<BlueStarro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/bluestar-ro-service-Amaravati" element={<BlueStarro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/eurekaforbes-ro-service-Araria" element={<BiharEdurekaforbes title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/eurekaforbes-ro-service-Arwal" element={<BiharEdurekaforbes title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/eurekaforbes-ro-service-Aurangabad" element={<BiharEdurekaforbes title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Banka" element={<BiharEdurekaforbes title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/eurekaforbes-ro-service-Begusarai" element={<BiharEdurekaforbes title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/eurekaforbes-ro-service-Bhabua" element={<BiharEdurekaforbes title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/eurekaforbes-ro-service-Bhagalpur" element={<BiharEdurekaforbes title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Bhojpur" element={<BiharEdurekaforbes title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Bihar-Sharif" element={<BiharEdurekaforbes title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/eurekaforbes-ro-service-Buxar" element={<BiharEdurekaforbes title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/eurekaforbes-ro-service-Chhapra" element={<BiharEdurekaforbes title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/eurekaforbes-ro-service-Darbhanga" element={<BiharEdurekaforbes title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/eurekaforbes-ro-service-East-Champaran" element={<BiharEdurekaforbes title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/eurekaforbes-ro-service-Gaya" element={<BiharEdurekaforbes title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/eurekaforbes-ro-service-Gopalganj" element={<BiharEdurekaforbes title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/eurekaforbes-ro-service-Jamui" element={<BiharEdurekaforbes title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/eurekaforbes-ro-service-Jehanabad" element={<BiharEdurekaforbes title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Khagaria" element={<BiharEdurekaforbes title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/eurekaforbes-ro-service-Kishanganj" element={<BiharEdurekaforbes title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/eurekaforbes-ro-service-Kaimur" element={<BiharEdurekaforbes title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/eurekaforbes-ro-service-Katihar" element={<BiharEdurekaforbes title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/eurekaforbes-ro-service-Lakhisarai" element={<BiharEdurekaforbes title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/eurekaforbes-ro-service-Madhubani" element={<BiharEdurekaforbes title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/eurekaforbes-ro-service-Munger" element={<BiharEdurekaforbes title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/eurekaforbes-ro-service-Madhepura" element={<BiharEdurekaforbes title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/eurekaforbes-ro-service-Muzaffarpur" element={<BiharEdurekaforbes title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Nalanda" element={<BiharEdurekaforbes title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/eurekaforbes-ro-service-Nawada" element={<BiharEdurekaforbes title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/eurekaforbes-ro-service-Patna" element={<BiharEdurekaforbes title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/eurekaforbes-ro-service-Purnia" element={<BiharEdurekaforbes title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/eurekaforbes-ro-service-Rohtas" element={<BiharEdurekaforbes title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/eurekaforbes-ro-service-Saharsa" element={<BiharEdurekaforbes title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/eurekaforbes-ro-service-Samastipur" element={<BiharEdurekaforbes title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/eurekaforbes-ro-service-Sasaram" element={<BiharEdurekaforbes title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/eurekaforbes-ro-service-Sheohar" element={<BiharEdurekaforbes title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/eurekaforbes-ro-service-Sheikhpura" element={<BiharEdurekaforbes title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/eurekaforbes-ro-service-Saran" element={<BiharEdurekaforbes title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/eurekaforbes-ro-service-Sitamarhi" element={<BiharEdurekaforbes title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/eurekaforbes-ro-service-Supaul" element={<BiharEdurekaforbes title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/eurekaforbes-ro-service-Siwan" element={<BiharEdurekaforbes title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/eurekaforbes-ro-service-Vaishali" element={<BiharEdurekaforbes title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/eurekaforbes-ro-service-West-Champaran" element={<BiharEdurekaforbes title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/eurekaforbes-ro-service-Garhwa" element={<JharkhandEdurekaforbes title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/eurekaforbes-ro-service-Palamu" element={<JharkhandEdurekaforbes title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/eurekaforbes-ro-service-Latehar" element={<JharkhandEdurekaforbes title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/eurekaforbes-ro-service-Chatra" element={<JharkhandEdurekaforbes title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/eurekaforbes-ro-service-Hazaribagh" element={<JharkhandEdurekaforbes title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/eurekaforbes-ro-service-Jamshedpur" element={<JharkhandEdurekaforbes title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Koderma" element={<JharkhandEdurekaforbes title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/eurekaforbes-ro-service-Giridih" element={<JharkhandEdurekaforbes title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/eurekaforbes-ro-service-Ramgarh" element={<JharkhandEdurekaforbes title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/eurekaforbes-ro-service-Bokaro" element={<JharkhandEdurekaforbes title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/eurekaforbes-ro-service-Dhanbad" element={<JharkhandEdurekaforbes title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/eurekaforbes-ro-service-Lohardaga" element={<JharkhandEdurekaforbes title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/eurekaforbes-ro-service-Gumla" element={<JharkhandEdurekaforbes title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/eurekaforbes-ro-service-Simdega" element={<JharkhandEdurekaforbes title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/eurekaforbes-ro-service-Ranchi" element={<JharkhandEdurekaforbes title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/eurekaforbes-ro-service-Khunti" element={<JharkhandEdurekaforbes title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/eurekaforbes-ro-service-Singhbhum" element={<JharkhandEdurekaforbes title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/eurekaforbes-ro-service-Saraikela-Kharsawan" element={<JharkhandEdurekaforbes title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/eurekaforbes-ro-service-Jamtara" element={<JharkhandEdurekaforbes title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/eurekaforbes-ro-service-Deoghar" element={<JharkhandEdurekaforbes title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/eurekaforbes-ro-service-Dumka" element={<JharkhandEdurekaforbes title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/eurekaforbes-ro-service-Pakur" element={<JharkhandEdurekaforbes title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/eurekaforbes-ro-service-Godda" element={<JharkhandEdurekaforbes title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/eurekaforbes-ro-service-Sahebganj" element={<JharkhandEdurekaforbes title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />


        <Route path="/eurekaforbes-ro-service-Alipurduar" element={<BengalEdurekaforbes title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/eurekaforbes-ro-service-Bankura" element={<BengalEdurekaforbes title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/eurekaforbes-ro-service-Birbhum" element={<BengalEdurekaforbes title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/eurekaforbes-ro-service-Cooch-Behar" element={<BengalEdurekaforbes title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/eurekaforbes-ro-service-Dakshin-Dinajpur" element={<BengalEdurekaforbes title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Darjeeling" element={<BengalEdurekaforbes title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/eurekaforbes-ro-service-Durgapur" element={<BengalEdurekaforbes title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/eurekaforbes-ro-service-Hooghly" element={<BengalEdurekaforbes title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/eurekaforbes-ro-service-Howrah" element={<BengalEdurekaforbes title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/eurekaforbes-ro-service-Jalpaiguri" element={<BengalEdurekaforbes title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/eurekaforbes-ro-service-Jhargram" element={<BengalEdurekaforbes title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/eurekaforbes-ro-service-Kalimpong" element={<BengalEdurekaforbes title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/eurekaforbes-ro-service-Kolkata" element={<BengalEdurekaforbes title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/eurekaforbes-ro-service-Malda" element={<BengalEdurekaforbes title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/eurekaforbes-ro-service-Murshidabad" element={<BengalEdurekaforbes title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Nadia" element={<BengalEdurekaforbes title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/eurekaforbes-ro-service-North-24-Parganas" element={<BengalEdurekaforbes title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/eurekaforbes-ro-service-Paschim-Bardhaman" element={<BengalEdurekaforbes title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/eurekaforbes-ro-service-Paschim-Medinipur" element={<BengalEdurekaforbes title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/eurekaforbes-ro-service-Purba-Bardhaman" element={<BengalEdurekaforbes title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/eurekaforbes-ro-service-Purba-Medinipur" element={<BengalEdurekaforbes title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/eurekaforbes-ro-service-South-24-Parganas" element={<BengalEdurekaforbes title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/eurekaforbes-ro-service-Uttar-Dinajpur" element={<BengalEdurekaforbes title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/eurekaforbes-ro-service-Mumbai" element={<Eurekaforbesro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/eurekaforbes-ro-service-Delhi" element={<Eurekaforbesro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/eurekaforbes-ro-service-Bengaluru" element={<Eurekaforbesro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/eurekaforbes-ro-service-Hyderabad" element={<Eurekaforbesro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Ahmedabad" element={<Eurekaforbesro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Chennai" element={<Eurekaforbesro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/eurekaforbes-ro-service-Surat" element={<Eurekaforbesro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/eurekaforbes-ro-service-Pune" element={<Eurekaforbesro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/eurekaforbes-ro-service-Jaipur" element={<Eurekaforbesro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/eurekaforbes-ro-service-Lucknow" element={<Eurekaforbesro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/eurekaforbes-ro-service-Kanpur" element={<Eurekaforbesro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Nagpur" element={<Eurekaforbesro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Indore" element={<Eurekaforbesro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/eurekaforbes-ro-service-Thane" element={<Eurekaforbesro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/eurekaforbes-ro-service-Bhopal" element={<Eurekaforbesro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/eurekaforbes-ro-service-Visakhapatnam" element={<Eurekaforbesro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/eurekaforbes-ro-service-Vadodara" element={<Eurekaforbesro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/eurekaforbes-ro-service-Ghaziabad" element={<Eurekaforbesro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Ludhiana" element={<Eurekaforbesro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/eurekaforbes-ro-service-Agra" element={<Eurekaforbesro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/eurekaforbes-ro-service-Nashik" element={<Eurekaforbesro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/eurekaforbes-ro-service-Faridabad" element={<Eurekaforbesro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Meerut" element={<Eurekaforbesro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/eurekaforbes-ro-service-Rajkot" element={<Eurekaforbesro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/eurekaforbes-ro-service-Varanasi" element={<Eurekaforbesro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/eurekaforbes-ro-service-Srinagar" element={<Eurekaforbesro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/eurekaforbes-ro-service-Amritsar" element={<Eurekaforbesro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/eurekaforbes-ro-service-Navi-Mumbai" element={<Eurekaforbesro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/eurekaforbes-ro-service-Prayagraj" element={<Eurekaforbesro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/eurekaforbes-ro-service-Jabalpur" element={<Eurekaforbesro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Gwalior" element={<Eurekaforbesro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/eurekaforbes-ro-service-Coimbatore" element={<Eurekaforbesro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/eurekaforbes-ro-service-Vijayawada" element={<Eurekaforbesro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/eurekaforbes-ro-service-Jodhpur" element={<Eurekaforbesro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Madurai" element={<Eurekaforbesro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/eurekaforbes-ro-service-Raipur" element={<Eurekaforbesro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/eurekaforbes-ro-service-Kota" element={<Eurekaforbesro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/eurekaforbes-ro-service-Guwahati" element={<Eurekaforbesro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/eurekaforbes-ro-service-Chandigarh" element={<Eurekaforbesro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/eurekaforbes-ro-service-Thiruvananthapuram" element={<Eurekaforbesro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/eurekaforbes-ro-service-Solapur" element={<Eurekaforbesro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/eurekaforbes-ro-service-Tiruppur" element={<Eurekaforbesro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/eurekaforbes-ro-service-Moradabad" element={<Eurekaforbesro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Mysore" element={<Eurekaforbesro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/eurekaforbes-ro-service-Gurgaon" element={<Eurekaforbesro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/eurekaforbes-ro-service-Aligarh" element={<Eurekaforbesro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/eurekaforbes-ro-service-Jalandhar" element={<Eurekaforbesro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/eurekaforbes-ro-service-Bhubaneswar" element={<Eurekaforbesro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/eurekaforbes-ro-service-Noida" element={<Eurekaforbesro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/eurekaforbes-ro-service-Jamshedpur" element={<Eurekaforbesro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Cuttack" element={<Eurekaforbesro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/eurekaforbes-ro-service-Bhavnagar" element={<Eurekaforbesro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/eurekaforbes-ro-service-Dehradun" element={<Eurekaforbesro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/eurekaforbes-ro-service-Rourkela" element={<Eurekaforbesro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/eurekaforbes-ro-service-Jamnagar" element={<Eurekaforbesro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/eurekaforbes-ro-service-Ujjain" element={<Eurekaforbesro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/eurekaforbes-ro-service-Jammu" element={<Eurekaforbesro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/eurekaforbes-ro-service-Mangalore" element={<Eurekaforbesro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/eurekaforbes-ro-service-Erode" element={<Eurekaforbesro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/eurekaforbes-ro-service-Udaipur" element={<Eurekaforbesro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/eurekaforbes-ro-service-Rajahmundry" element={<Eurekaforbesro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/eurekaforbes-ro-service-Patiala" element={<Eurekaforbesro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/eurekaforbes-ro-service-Agartala" element={<Eurekaforbesro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/eurekaforbes-ro-service-Tirupati" element={<Eurekaforbesro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/eurekaforbes-ro-service-Sambalpur" element={<Eurekaforbesro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/eurekaforbes-ro-service-Bilaspur" element={<Eurekaforbesro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/eurekaforbes-ro-service-Alwar" element={<Eurekaforbesro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/eurekaforbes-ro-service-Bardhaman" element={<Eurekaforbesro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/eurekaforbes-ro-service-Sonipat" element={<Eurekaforbesro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/eurekaforbes-ro-service-New-Delhi" element={<Eurekaforbesro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/eurekaforbes-ro-service-Puducherry" element={<Eurekaforbesro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/eurekaforbes-ro-service-Haridwar" element={<Eurekaforbesro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/eurekaforbes-ro-service-Vijayanagaram" element={<Eurekaforbesro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/eurekaforbes-ro-service-Sri-Ganganagar" element={<Eurekaforbesro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/eurekaforbes-ro-service-Secunderabad" element={<Eurekaforbesro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/eurekaforbes-ro-service-Yamunanagar" element={<Eurekaforbesro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/eurekaforbes-ro-service-Gandhinagar" element={<Eurekaforbesro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/eurekaforbes-ro-service-Ambala" element={<Eurekaforbesro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/eurekaforbes-ro-service-Vellore" element={<Eurekaforbesro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/eurekaforbes-ro-service-Shimla" element={<Eurekaforbesro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/eurekaforbes-ro-service-Amaravati" element={<Eurekaforbesro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/havells-ro-service-Araria" element={<BiharHavells title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/havells-ro-service-Arwal" element={<BiharHavells title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/havells-ro-service-Aurangabad" element={<BiharHavells title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/havells-ro-service-Banka" element={<BiharHavells title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/havells-ro-service-Begusarai" element={<BiharHavells title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/havells-ro-service-Bhabua" element={<BiharHavells title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/havells-ro-service-Bhagalpur" element={<BiharHavells title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/havells-ro-service-Bhojpur" element={<BiharHavells title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/havells-ro-service-Bihar-Sharif" element={<BiharHavells title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/havells-ro-service-Buxar" element={<BiharHavells title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/havells-ro-service-Chhapra" element={<BiharHavells title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/havells-ro-service-Darbhanga" element={<BiharHavells title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/havells-ro-service-East-Champaran" element={<BiharHavells title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/havells-ro-service-Gaya" element={<BiharHavells title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/havells-ro-service-Gopalganj" element={<BiharHavells title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/havells-ro-service-Jamui" element={<BiharHavells title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/havells-ro-service-Jehanabad" element={<BiharHavells title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/havells-ro-service-Khagaria" element={<BiharHavells title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/havells-ro-service-Kishanganj" element={<BiharHavells title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/havells-ro-service-Kaimur" element={<BiharHavells title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/havells-ro-service-Katihar" element={<BiharHavells title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/havells-ro-service-Lakhisarai" element={<BiharHavells title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/havells-ro-service-Madhubani" element={<BiharHavells title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/havells-ro-service-Munger" element={<BiharHavells title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/havells-ro-service-Madhepura" element={<BiharHavells title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/havells-ro-service-Muzaffarpur" element={<BiharHavells title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/havells-ro-service-Nalanda" element={<BiharHavells title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/havells-ro-service-Nawada" element={<BiharHavells title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/havells-ro-service-Patna" element={<BiharHavells title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/havells-ro-service-Purnia" element={<BiharHavells title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/havells-ro-service-Rohtas" element={<BiharHavells title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/havells-ro-service-Saharsa" element={<BiharHavells title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/havells-ro-service-Samastipur" element={<BiharHavells title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/havells-ro-service-Sasaram" element={<BiharHavells title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/havells-ro-service-Sheohar" element={<BiharHavells title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/havells-ro-service-Sheikhpura" element={<BiharHavells title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/havells-ro-service-Saran" element={<BiharHavells title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/havells-ro-service-Sitamarhi" element={<BiharHavells title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/havells-ro-service-Supaul" element={<BiharHavells title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/havells-ro-service-Siwan" element={<BiharHavells title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/havells-ro-service-Vaishali" element={<BiharHavells title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/havells-ro-service-West-Champaran" element={<BiharHavells title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/havells-ro-service-Garhwa" element={<JharkhandHavells title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/havells-ro-service-Palamu" element={<JharkhandHavells title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/havells-ro-service-Latehar" element={<JharkhandHavells title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/havells-ro-service-Chatra" element={<JharkhandHavells title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/havells-ro-service-Hazaribagh" element={<JharkhandHavells title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/havells-ro-service-Jamshedpur" element={<JharkhandHavells title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/havells-ro-service-Koderma" element={<JharkhandHavells title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/havells-ro-service-Giridih" element={<JharkhandHavells title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/havells-ro-service-Ramgarh" element={<JharkhandHavells title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/havells-ro-service-Bokaro" element={<JharkhandHavells title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/havells-ro-service-Dhanbad" element={<JharkhandHavells title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/havells-ro-service-Lohardaga" element={<JharkhandHavells title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/havells-ro-service-Gumla" element={<JharkhandHavells title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/havells-ro-service-Simdega" element={<JharkhandHavells title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/havells-ro-service-Ranchi" element={<JharkhandHavells title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/havells-ro-service-Khunti" element={<JharkhandHavells title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/havells-ro-service-Singhbhum" element={<JharkhandHavells title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/havells-ro-service-Saraikela-Kharsawan" element={<JharkhandHavells title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/havells-ro-service-Jamtara" element={<JharkhandHavells title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/havells-ro-service-Deoghar" element={<JharkhandHavells title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/havells-ro-service-Dumka" element={<JharkhandHavells title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/havells-ro-service-Pakur" element={<JharkhandHavells title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/havells-ro-service-Godda" element={<JharkhandHavells title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/havells-ro-service-Sahebganj" element={<Jharkhand title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/havells-ro-service-Alipurduar" element={<BengalHavells title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/havells-ro-service-Bankura" element={<BengalHavells title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/havells-ro-service-Birbhum" element={<BengalHavells title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/havells-ro-service-Cooch-Behar" element={<BengalHavells title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/havells-ro-service-Dakshin-Dinajpur" element={<BengalHavells title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/havells-ro-service-Darjeeling" element={<BengalHavells title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/havells-ro-service-Durgapur" element={<BengalHavells title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/havells-ro-service-Hooghly" element={<BengalHavells title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/havells-ro-service-Howrah" element={<BengalHavells title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/havells-ro-service-Jalpaiguri" element={<BengalHavells title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/havells-ro-service-Jhargram" element={<BengalHavells title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/havells-ro-service-Kalimpong" element={<BengalHavells title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/havells-ro-service-Kolkata" element={<BengalHavells title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/havells-ro-service-Malda" element={<BengalHavells title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/havells-ro-service-Murshidabad" element={<BengalHavells title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/havells-ro-service-Nadia" element={<BengalHavells title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/havells-ro-service-North-24-Parganas" element={<BengalHavells title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/havells-ro-service-Paschim-Bardhaman" element={<BengalHavells title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/havells-ro-service-Paschim-Medinipur" element={<BengalHavells title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/havells-ro-service-Purba-Bardhaman" element={<BengalHavells title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/havells-ro-service-Purba-Medinipur" element={<BengalHavells title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/havells-ro-service-South-24-Parganas" element={<BengalHavells title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/havells-ro-service-Uttar-Dinajpur" element={<BengalHavells title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/havells-ro-service-Mumbai" element={<Havellsro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/havells-ro-service-Delhi" element={<Havellsro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/havells-ro-service-Bengaluru" element={<Havellsro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/havells-ro-service-Hyderabad" element={<Havellsro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/havells-ro-service-Ahmedabad" element={<Havellsro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/havells-ro-service-Chennai" element={<Havellsro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/havells-ro-service-Surat" element={<Havellsro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/havells-ro-service-Pune" element={<Havellsro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/havells-ro-service-Jaipur" element={<Havellsro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/havells-ro-service-Lucknow" element={<Havellsro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/havells-ro-service-Kanpur" element={<Havellsro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/havells-ro-service-Nagpur" element={<Havellsro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/havells-ro-service-Indore" element={<Havellsro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/havells-ro-service-Thane" element={<Havellsro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/havells-ro-service-Bhopal" element={<Havellsro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/havells-ro-service-Visakhapatnam" element={<Havellsro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/havells-ro-service-Vadodara" element={<Havellsro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/havells-ro-service-Ghaziabad" element={<Havellsro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/havells-ro-service-Ludhiana" element={<Havellsro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/havells-ro-service-Agra" element={<Havellsro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/havells-ro-service-Nashik" element={<Havellsro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/havells-ro-service-Faridabad" element={<Havellsro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/havells-ro-service-Meerut" element={<Havellsro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/havells-ro-service-Rajkot" element={<Havellsro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/havells-ro-service-Varanasi" element={<Havellsro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/havells-ro-service-Srinagar" element={<Havellsro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/havells-ro-service-Amritsar" element={<Havellsro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/havells-ro-service-Navi-Mumbai" element={<Havellsro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/havells-ro-service-Prayagraj" element={<Havellsro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/havells-ro-service-Jabalpur" element={<Havellsro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/havells-ro-service-Gwalior" element={<Havellsro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/havells-ro-service-Coimbatore" element={<Havellsro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/havells-ro-service-Vijayawada" element={<Havellsro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/havells-ro-service-Jodhpur" element={<Havellsro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/havells-ro-service-Madurai" element={<Havellsro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/havells-ro-service-Raipur" element={<Havellsro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/havells-ro-service-Kota" element={<Havellsro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/havells-ro-service-Guwahati" element={<Havellsro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/havells-ro-service-Chandigarh" element={<Havellsro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/havells-ro-service-Thiruvananthapuram" element={<Havellsro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/havells-ro-service-Solapur" element={<Havellsro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/havells-ro-service-Tiruppur" element={<Havellsro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/havells-ro-service-Moradabad" element={<Havellsro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/havells-ro-service-Mysore" element={<Havellsro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/havells-ro-service-Gurgaon" element={<Havellsro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/havells-ro-service-Aligarh" element={<Havellsro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/havells-ro-service-Jalandhar" element={<Havellsro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/havells-ro-service-Bhubaneswar" element={<Havellsro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/havells-ro-service-Noida" element={<Havellsro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/havells-ro-service-Jamshedpur" element={<Havellsro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/havells-ro-service-Cuttack" element={<Havellsro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/havells-ro-service-Bhavnagar" element={<Havellsro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/havells-ro-service-Dehradun" element={<Havellsro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/havells-ro-service-Rourkela" element={<Havellsro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/havells-ro-service-Jamnagar" element={<Havellsro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/havells-ro-service-Ujjain" element={<Havellsro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/havells-ro-service-Jammu" element={<Havellsro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/havells-ro-service-Mangalore" element={<Havellsro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/havells-ro-service-Erode" element={<Havellsro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/havells-ro-service-Udaipur" element={<Havellsro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/havells-ro-service-Rajahmundry" element={<Havellsro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/havells-ro-service-Patiala" element={<Havellsro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/havells-ro-service-Agartala" element={<Havellsro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/havells-ro-service-Tirupati" element={<Havellsro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/havells-ro-service-Sambalpur" element={<Havellsro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/havells-ro-service-Bilaspur" element={<Havellsro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/havells-ro-service-Alwar" element={<Havellsro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/havells-ro-service-Bardhaman" element={<Havellsro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/havells-ro-service-Sonipat" element={<Havellsro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/havells-ro-service-New-Delhi" element={<Havellsro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/havells-ro-service-Puducherry" element={<Havellsro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/havells-ro-service-Haridwar" element={<Havellsro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/havells-ro-service-Vijayanagaram" element={<Havellsro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/havells-ro-service-Sri-Ganganagar" element={<Havellsro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/havells-ro-service-Secunderabad" element={<Havellsro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/havells-ro-service-Yamunanagar" element={<Havellsro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/havells-ro-service-Gandhinagar" element={<Havellsro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/havells-ro-service-Ambala" element={<Havellsro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/havells-ro-service-Vellore" element={<Havellsro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/havells-ro-service-Shimla" element={<Havellsro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/havells-ro-service-Amaravati" element={<Havellsro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />

        <Route path="/kent-ro-service-Araria" element={<BiharKent title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/kent-ro-service-Arwal" element={<BiharKent title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/kent-ro-service-Aurangabad" element={<BiharKent title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/kent-ro-service-Banka" element={<BiharKent title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/kent-ro-service-Begusarai" element={<BiharKent title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/kent-ro-service-Bhabua" element={<BiharKent title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/kent-ro-service-Bhagalpur" element={<BiharKent title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/kent-ro-service-Bhojpur" element={<BiharKent title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/kent-ro-service-Bihar-Sharif" element={<BiharKent title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/kent-ro-service-Buxar" element={<BiharKent title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/kent-ro-service-Chhapra" element={<BiharKent title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/kent-ro-service-Darbhanga" element={<BiharKent title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/kent-ro-service-East-Champaran" element={<BiharKent title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/kent-ro-service-Gaya" element={<BiharKent title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/kent-ro-service-Gopalganj" element={<BiharKent title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/kent-ro-service-Jamui" element={<BiharKent title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/kent-ro-service-Jehanabad" element={<BiharKent title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/kent-ro-service-Khagaria" element={<BiharKent title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/kent-ro-service-Kishanganj" element={<BiharKent title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/kent-ro-service-Kaimur" element={<BiharKent title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/kent-ro-service-Katihar" element={<BiharKent title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/kent-ro-service-Lakhisarai" element={<BiharKent title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/kent-ro-service-Madhubani" element={<BiharKent title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/kent-ro-service-Munger" element={<BiharKent title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/kent-ro-service-Madhepura" element={<BiharKent title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/kent-ro-service-Muzaffarpur" element={<BiharKent title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/kent-ro-service-Nalanda" element={<BiharKent title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/kent-ro-service-Nawada" element={<BiharKent title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/kent-ro-service-Patna" element={<BiharKent title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/kent-ro-service-Purnia" element={<BiharKent title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/kent-ro-service-Rohtas" element={<BiharKent title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/kent-ro-service-Saharsa" element={<BiharKent title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/kent-ro-service-Samastipur" element={<BiharKent title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/kent-ro-service-Sasaram" element={<BiharKent title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/kent-ro-service-Sheohar" element={<BiharKent title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/kent-ro-service-Sheikhpura" element={<BiharKent title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/kent-ro-service-Saran" element={<BiharKent title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/kent-ro-service-Sitamarhi" element={<BiharKent title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/kent-ro-service-Supaul" element={<BiharKent title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/kent-ro-service-Siwan" element={<BiharKent title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/kent-ro-service-Vaishali" element={<BiharKent title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/kent-ro-service-West-Champaran" element={<BiharKent title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/kent-ro-service-Garhwa" element={<JharkhandKent title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/kent-ro-service-Palamu" element={<JharkhandKent title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/kent-ro-service-Latehar" element={<JharkhandKent title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/kent-ro-service-Chatra" element={<JharkhandKent title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/kent-ro-service-Hazaribagh" element={<JharkhandKent title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/kent-ro-service-Jamshedpur" element={<JharkhandKent title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/kent-ro-service-Koderma" element={<JharkhandKent title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/kent-ro-service-Giridih" element={<JharkhandKent title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/kent-ro-service-Ramgarh" element={<JharkhandKent title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/kent-ro-service-Bokaro" element={<JharkhandKent title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/kent-ro-service-Dhanbad" element={<JharkhandKent title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/kent-ro-service-Lohardaga" element={<JharkhandKent title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/kent-ro-service-Gumla" element={<JharkhandKent title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/kent-ro-service-Simdega" element={<JharkhandKent title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/kent-ro-service-Ranchi" element={<JharkhandKent title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/kent-ro-service-Khunti" element={<JharkhandKent title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/kent-ro-service-Singhbhum" element={<JharkhandKent title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/kent-ro-service-Saraikela-Kharsawan" element={<JharkhandKent title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/kent-ro-service-Jamtara" element={<JharkhandKent title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/kent-ro-service-Deoghar" element={<JharkhandKent title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/kent-ro-service-Dumka" element={<JharkhandKent title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/kent-ro-service-Pakur" element={<JharkhandKent title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/kent-ro-service-Godda" element={<JharkhandKent title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/kent-ro-service-Sahebganj" element={<JharkhandKent title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/kent-ro-service-Alipurduar" element={<BengalKent title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/kent-ro-service-Bankura" element={<BengalKent title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/kent-ro-service-Birbhum" element={<BengalKent title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/kent-ro-service-Cooch-Behar" element={<BengalKent title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/kent-ro-service-Dakshin-Dinajpur" element={<BengalKent title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/kent-ro-service-Darjeeling" element={<BengalKent title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/kent-ro-service-Durgapur" element={<BengalKent title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/kent-ro-service-Hooghly" element={<BengalKent title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/kent-ro-service-Howrah" element={<BengalKent title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/kent-ro-service-Jalpaiguri" element={<BengalKent title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/kent-ro-service-Jhargram" element={<BengalKent title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/kent-ro-service-Kalimpong" element={<BengalKent title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/kent-ro-service-Kolkata" element={<BengalKent title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/kent-ro-service-Malda" element={<BengalKent title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/kent-ro-service-Murshidabad" element={<BengalKent title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/kent-ro-service-Nadia" element={<BengalKent title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/kent-ro-service-North-24-Parganas" element={<BengalKent title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/kent-ro-service-Paschim-Bardhaman" element={<BengalKent title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/kent-ro-service-Paschim-Medinipur" element={<BengalKent title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/kent-ro-service-Purba-Bardhaman" element={<BengalKent title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/kent-ro-service-Purba-Medinipur" element={<BengalKent title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/kent-ro-service-South-24-Parganas" element={<BengalKent title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/kent-ro-service-Uttar-Dinajpur" element={<BengalKent title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/kent-ro-service-Mumbai" element={<Kentro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/kent-ro-service-Delhi" element={<Kentro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/kent-ro-service-Bengaluru" element={<Kentro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/kent-ro-service-Hyderabad" element={<Kentro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/kent-ro-service-Ahmedabad" element={<Kentro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/kent-ro-service-Chennai" element={<Kentro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/kent-ro-service-Surat" element={<Kentro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/kent-ro-service-Pune" element={<Kentro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/kent-ro-service-Jaipur" element={<Kentro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/kent-ro-service-Lucknow" element={<Kentro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/kent-ro-service-Kanpur" element={<Kentro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/kent-ro-service-Nagpur" element={<Kentro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/kent-ro-service-Indore" element={<Kentro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/kent-ro-service-Thane" element={<Kentro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/kent-ro-service-Bhopal" element={<Kentro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/kent-ro-service-Visakhapatnam" element={<Kentro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/kent-ro-service-Vadodara" element={<Kentro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/kent-ro-service-Ghaziabad" element={<Kentro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/kent-ro-service-Ludhiana" element={<Kentro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/kent-ro-service-Agra" element={<Kentro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/kent-ro-service-Nashik" element={<Kentro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/kent-ro-service-Faridabad" element={<Kentro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/kent-ro-service-Meerut" element={<Kentro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/kent-ro-service-Rajkot" element={<Kentro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/kent-ro-service-Varanasi" element={<Kentro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/kent-ro-service-Srinagar" element={<Kentro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/kent-ro-service-Amritsar" element={<Kentro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/kent-ro-service-Navi-Mumbai" element={<Kentro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/kent-ro-service-Prayagraj" element={<Kentro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/kent-ro-service-Jabalpur" element={<Kentro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/kent-ro-service-Gwalior" element={<Kentro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/kent-ro-service-Coimbatore" element={<Kentro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/kent-ro-service-Vijayawada" element={<Kentro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/kent-ro-service-Jodhpur" element={<Kentro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/kent-ro-service-Madurai" element={<Kentro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/kent-ro-service-Raipur" element={<Kentro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/kent-ro-service-Kota" element={<Kentro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/kent-ro-service-Guwahati" element={<Kentro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/kent-ro-service-Chandigarh" element={<Kentro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/kent-ro-service-Thiruvananthapuram" element={<Kentro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/kent-ro-service-Solapur" element={<Kentro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/kent-ro-service-Tiruppur" element={<Kentro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/kent-ro-service-Moradabad" element={<Kentro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/kent-ro-service-Mysore" element={<Kentro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/kent-ro-service-Gurgaon" element={<Kentro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/kent-ro-service-Aligarh" element={<Kentro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/kent-ro-service-Jalandhar" element={<Kentro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/kent-ro-service-Bhubaneswar" element={<Kentro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/kent-ro-service-Noida" element={<Kentro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/kent-ro-service-Jamshedpur" element={<Kentro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/kent-ro-service-Cuttack" element={<Kentro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/kent-ro-service-Bhavnagar" element={<Kentro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/kent-ro-service-Dehradun" element={<Kentro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/kent-ro-service-Rourkela" element={<Kentro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/kent-ro-service-Jamnagar" element={<Kentro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/kent-ro-service-Ujjain" element={<Kentro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/kent-ro-service-Jammu" element={<Kentro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/kent-ro-service-Mangalore" element={<Kentro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/kent-ro-service-Erode" element={<Kentro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/kent-ro-service-Udaipur" element={<Kentro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/kent-ro-service-Rajahmundry" element={<Kentro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/kent-ro-service-Patiala" element={<Kentro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/kent-ro-service-Agartala" element={<Kentro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/kent-ro-service-Tirupati" element={<Kentro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/kent-ro-service-Sambalpur" element={<Kentro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/kent-ro-service-Bilaspur" element={<Kentro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/kent-ro-service-Alwar" element={<Kentro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/kent-ro-service-Bardhaman" element={<Kentro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/kent-ro-service-Sonipat" element={<Kentro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/kent-ro-service-New-Delhi" element={<Kentro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/kent-ro-service-Puducherry" element={<Kentro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/kent-ro-service-Haridwar" element={<Kentro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/kent-ro-service-Vijayanagaram" element={<Kentro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/kent-ro-service-Sri-Ganganagar" element={<Kentro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/kent-ro-service-Secunderabad" element={<Kentro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/kent-ro-service-Yamunanagar" element={<Kentro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/kent-ro-service-Gandhinagar" element={<Kentro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/kent-ro-service-Ambala" element={<Kentro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/kent-ro-service-Vellore" element={<Kentro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/kent-ro-service-Shimla" element={<Kentro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/kent-ro-service-Amaravati" element={<Kentro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/kuchina-ro-service-Araria" element={<BiharKuchina title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/kuchina-ro-service-Arwal" element={<BiharKuchina title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/kuchina-ro-service-Aurangabad" element={<BiharKuchina title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/kuchina-ro-service-Banka" element={<BiharKuchina title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/kuchina-ro-service-Begusarai" element={<BiharKuchina title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/kuchina-ro-service-Bhabua" element={<BiharKuchina title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/kuchina-ro-service-Bhagalpur" element={<BiharKuchina title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/kuchina-ro-service-Bhojpur" element={<BiharKuchina title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/kuchina-ro-service-Bihar-Sharif" element={<BiharKuchina title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/kuchina-ro-service-Buxar" element={<BiharKuchina title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/kuchina-ro-service-Chhapra" element={<BiharKuchina title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/kuchina-ro-service-Darbhanga" element={<BiharKuchina title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/kuchina-ro-service-East-Champaran" element={<BiharKuchina title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/kuchina-ro-service-Gaya" element={<BiharKuchina title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/kuchina-ro-service-Gopalganj" element={<BiharKuchina title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/kuchina-ro-service-Jamui" element={<BiharKuchina title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/kuchina-ro-service-Jehanabad" element={<BiharKuchina title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/kuchina-ro-service-Khagaria" element={<BiharKuchina title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/kuchina-ro-service-Kishanganj" element={<BiharKuchina title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/kuchina-ro-service-Kaimur" element={<BiharKuchina title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/kuchina-ro-service-Katihar" element={<BiharKuchina title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/kuchina-ro-service-Lakhisarai" element={<BiharKuchina title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/kuchina-ro-service-Madhubani" element={<BiharKuchina title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/kuchina-ro-service-Munger" element={<BiharKuchina title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/kuchina-ro-service-Madhepura" element={<BiharKuchina title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/kuchina-ro-service-Muzaffarpur" element={<BiharKuchina title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/kuchina-ro-service-Nalanda" element={<BiharKuchina title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/kuchina-ro-service-Nawada" element={<BiharKuchina title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/kuchina-ro-service-Patna" element={<BiharKuchina title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/kuchina-ro-service-Purnia" element={<BiharKuchina title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/kuchina-ro-service-Rohtas" element={<BiharKuchina title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/kuchina-ro-service-Saharsa" element={<BiharKuchina title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/kuchina-ro-service-Samastipur" element={<BiharKuchina title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/kuchina-ro-service-Sasaram" element={<BiharKuchina title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/kuchina-ro-service-Sheohar" element={<BiharKuchina title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/kuchina-ro-service-Sheikhpura" element={<BiharKuchina title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/kuchina-ro-service-Saran" element={<BiharKuchina title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/kuchina-ro-service-Sitamarhi" element={<BiharKuchina title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/kuchina-ro-service-Supaul" element={<BiharKuchina title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/kuchina-ro-service-Siwan" element={<BiharKuchina title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/kuchina-ro-service-Vaishali" element={<BiharKuchina title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/kuchina-ro-service-West-Champaran" element={<BiharKuchina title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/kuchina-ro-service-Garhwa" element={<JharkhandKuchina title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/kuchina-ro-service-Palamu" element={<JharkhandKuchina title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/kuchina-ro-service-Latehar" element={<JharkhandKuchina title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/kuchina-ro-service-Chatra" element={<JharkhandKuchina title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/kuchina-ro-service-Hazaribagh" element={<JharkhandKuchina title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/kuchina-ro-service-Jamshedpur" element={<JharkhandKuchina title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/kuchina-ro-service-Koderma" element={<JharkhandKuchina title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/kuchina-ro-service-Giridih" element={<JharkhandKuchina title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/kuchina-ro-service-Ramgarh" element={<JharkhandKuchina title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/kuchina-ro-service-Bokaro" element={<JharkhandKuchina title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/kuchina-ro-service-Dhanbad" element={<JharkhandKuchina title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/kuchina-ro-service-Lohardaga" element={<JharkhandKuchina title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/kuchina-ro-service-Gumla" element={<JharkhandKuchina title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/kuchina-ro-service-Simdega" element={<JharkhandKuchina title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/kuchina-ro-service-Ranchi" element={<JharkhandKuchina title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/kuchina-ro-service-Khunti" element={<JharkhandKuchina title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/kuchina-ro-service-Singhbhum" element={<JharkhandKuchina title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/kuchina-ro-service-Saraikela-Kharsawan" element={<JharkhandKuchina title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/kuchina-ro-service-Jamtara" element={<JharkhandKuchina title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/kuchina-ro-service-Deoghar" element={<JharkhandKuchina title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/kuchina-ro-service-Dumka" element={<JharkhandKuchina title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/kuchina-ro-service-Pakur" element={<JharkhandKuchina title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/kuchina-ro-service-Godda" element={<JharkhandKuchina title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/kuchina-ro-service-Sahebganj" element={<JharkhandKuchina title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />


        <Route path="/kuchina-ro-service-Alipurduar" element={<BengalKuchina title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/kuchina-ro-service-Bankura" element={<BengalKuchina title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/kuchina-ro-service-Birbhum" element={<BengalKuchina title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/kuchina-ro-service-Cooch-Behar" element={<BengalKuchina title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/kuchina-ro-service-Dakshin-Dinajpur" element={<BengalKuchina title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/kuchina-ro-service-Darjeeling" element={<BengalKuchina title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/kuchina-ro-service-Durgapur" element={<BengalKuchina title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/kuchina-ro-service-Hooghly" element={<BengalKuchina title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/kuchina-ro-service-Howrah" element={<BengalKuchina title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/kuchina-ro-service-Jalpaiguri" element={<BengalKuchina title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/kuchina-ro-service-Jhargram" element={<BengalKuchina title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/kuchina-ro-service-Kalimpong" element={<BengalKuchina title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/kuchina-ro-service-Kolkata" element={<BengalKuchina title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/kuchina-ro-service-Malda" element={<BengalKuchina title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/kuchina-ro-service-Murshidabad" element={<BengalKuchina title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/kuchina-ro-service-Nadia" element={<BengalKuchina title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/kuchina-ro-service-North-24-Parganas" element={<BengalKuchina title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/kuchina-ro-service-Paschim-Bardhaman" element={<BengalKuchina title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/kuchina-ro-service-Paschim-Medinipur" element={<BengalKuchina title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/kuchina-ro-service-Purba-Bardhaman" element={<BengalKuchina title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/kuchina-ro-service-Purba-Medinipur" element={<BengalKuchina title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/kuchina-ro-service-South-24-Parganas" element={<BengalKuchina title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/kuchina-ro-service-Uttar-Dinajpur" element={<BengalKuchina title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/kuchina-ro-service-Mumbai" element={<Kuchinaro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/kuchina-ro-service-Delhi" element={<Kuchinaro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/kuchina-ro-service-Bengaluru" element={<Kuchinaro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/kuchina-ro-service-Hyderabad" element={<Kuchinaro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/kuchina-ro-service-Ahmedabad" element={<Kuchinaro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/kuchina-ro-service-Chennai" element={<Kuchinaro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/kuchina-ro-service-Surat" element={<Kuchinaro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/kuchina-ro-service-Pune" element={<Kuchinaro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/kuchina-ro-service-Jaipur" element={<Kuchinaro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/kuchina-ro-service-Lucknow" element={<Kuchinaro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/kuchina-ro-service-Kanpur" element={<Kuchinaro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/kuchina-ro-service-Nagpur" element={<Kuchinaro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/kuchina-ro-service-Indore" element={<Kuchinaro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/kuchina-ro-service-Thane" element={<Kuchinaro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/kuchina-ro-service-Bhopal" element={<Kuchinaro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/kuchina-ro-service-Visakhapatnam" element={<Kuchinaro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/kuchina-ro-service-Vadodara" element={<Kuchinaro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/kuchina-ro-service-Ghaziabad" element={<Kuchinaro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/kuchina-ro-service-Ludhiana" element={<Kuchinaro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/kuchina-ro-service-Agra" element={<Kuchinaro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/kuchina-ro-service-Nashik" element={<Kuchinaro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/kuchina-ro-service-Faridabad" element={<Kuchinaro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/kuchina-ro-service-Meerut" element={<Kuchinaro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/kuchina-ro-service-Rajkot" element={<Kuchinaro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/kuchina-ro-service-Varanasi" element={<Kuchinaro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/kuchina-ro-service-Srinagar" element={<Kuchinaro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/kuchina-ro-service-Amritsar" element={<Kuchinaro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/kuchina-ro-service-Navi-Mumbai" element={<Kuchinaro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/kuchina-ro-service-Prayagraj" element={<Kuchinaro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/kuchina-ro-service-Jabalpur" element={<Kuchinaro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/kuchina-ro-service-Gwalior" element={<Kuchinaro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/kuchina-ro-service-Coimbatore" element={<Kuchinaro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/kuchina-ro-service-Vijayawada" element={<Kuchinaro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/kuchina-ro-service-Jodhpur" element={<Kuchinaro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/kuchina-ro-service-Madurai" element={<Kuchinaro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/kuchina-ro-service-Raipur" element={<Kuchinaro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/kuchina-ro-service-Kota" element={<Kuchinaro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/kuchina-ro-service-Guwahati" element={<Kuchinaro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/kuchina-ro-service-Chandigarh" element={<Kuchinaro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/kuchina-ro-service-Thiruvananthapuram" element={<Kuchinaro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/kuchina-ro-service-Solapur" element={<Kuchinaro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/kuchina-ro-service-Tiruppur" element={<Kuchinaro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/kuchina-ro-service-Moradabad" element={<Kuchinaro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/kuchina-ro-service-Mysore" element={<Kuchinaro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/kuchina-ro-service-Gurgaon" element={<Kuchinaro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/kuchina-ro-service-Aligarh" element={<Kuchinaro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/kuchina-ro-service-Jalandhar" element={<Kuchinaro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/kuchina-ro-service-Bhubaneswar" element={<Kuchinaro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/kuchina-ro-service-Noida" element={<Kuchinaro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/kuchina-ro-service-Jamshedpur" element={<Kuchinaro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/kuchina-ro-service-Cuttack" element={<Kuchinaro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/kuchina-ro-service-Bhavnagar" element={<Kuchinaro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/kuchina-ro-service-Dehradun" element={<Kuchinaro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/kuchina-ro-service-Rourkela" element={<Kuchinaro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/kuchina-ro-service-Jamnagar" element={<Kuchinaro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/kuchina-ro-service-Ujjain" element={<Kuchinaro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/kuchina-ro-service-Jammu" element={<Kuchinaro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/kuchina-ro-service-Mangalore" element={<Kuchinaro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/kuchina-ro-service-Erode" element={<Kuchinaro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/kuchina-ro-service-Udaipur" element={<Kuchinaro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/kuchina-ro-service-Rajahmundry" element={<Kuchinaro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/kuchina-ro-service-Patiala" element={<Kuchinaro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/kuchina-ro-service-Agartala" element={<Kuchinaro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/kuchina-ro-service-Tirupati" element={<Kuchinaro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/kuchina-ro-service-Sambalpur" element={<Kuchinaro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/kuchina-ro-service-Bilaspur" element={<Kuchinaro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/kuchina-ro-service-Alwar" element={<Kuchinaro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/kuchina-ro-service-Bardhaman" element={<Kuchinaro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/kuchina-ro-service-Sonipat" element={<Kuchinaro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/kuchina-ro-service-New-Delhi" element={<Kuchinaro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/kuchina-ro-service-Puducherry" element={<Kuchinaro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/kuchina-ro-service-Haridwar" element={<Kuchinaro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/kuchina-ro-service-Vijayanagaram" element={<Kuchinaro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/kuchina-ro-service-Sri-Ganganagar" element={<Kuchinaro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/kuchina-ro-service-Secunderabad" element={<Kuchinaro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/kuchina-ro-service-Yamunanagar" element={<Kuchinaro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/kuchina-ro-service-Gandhinagar" element={<Kuchinaro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/kuchina-ro-service-Ambala" element={<Kuchinaro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/kuchina-ro-service-Vellore" element={<Kuchinaro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/kuchina-ro-service-Shimla" element={<Kuchinaro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/kuchina-ro-service-Amaravati" element={<Kuchinaro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/lg-ro-service-Araria" element={<BiharLG title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/lg-ro-service-Arwal" element={<BiharLG title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/lg-ro-service-Aurangabad" element={<BiharLG title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/lg-ro-service-Banka" element={<BiharLG title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/lg-ro-service-Begusarai" element={<BiharLG title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/lg-ro-service-Bhabua" element={<BiharLG title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/lg-ro-service-Bhagalpur" element={<BiharLG title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/lg-ro-service-Bhojpur" element={<BiharLG title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/lg-ro-service-Bihar-Sharif" element={<BiharLG title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/lg-ro-service-Buxar" element={<BiharLG title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/lg-ro-service-Chhapra" element={<BiharLG title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/lg-ro-service-Darbhanga" element={<BiharLG title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/lg-ro-service-East-Champaran" element={<BiharLG title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/lg-ro-service-Gaya" element={<BiharLG title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/lg-ro-service-Gopalganj" element={<BiharLG title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/lg-ro-service-Jamui" element={<BiharLG title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/lg-ro-service-Jehanabad" element={<BiharLG title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/lg-ro-service-Khagaria" element={<BiharLG title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/lg-ro-service-Kishanganj" element={<BiharLG title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/lg-ro-service-Kaimur" element={<BiharLG title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/lg-ro-service-Katihar" element={<BiharLG title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/lg-ro-service-Lakhisarai" element={<BiharLG title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/lg-ro-service-Madhubani" element={<BiharLG title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/lg-ro-service-Munger" element={<BiharLG title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/lg-ro-service-Madhepura" element={<BiharLG title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/lg-ro-service-Muzaffarpur" element={<BiharLG title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/lg-ro-service-Nalanda" element={<BiharLG title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/lg-ro-service-Nawada" element={<BiharLG title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/lg-ro-service-Patna" element={<BiharLG title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/lg-ro-service-Purnia" element={<BiharLG title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/lg-ro-service-Rohtas" element={<BiharLG title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/lg-ro-service-Saharsa" element={<BiharLG title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/lg-ro-service-Samastipur" element={<BiharLG title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/lg-ro-service-Sasaram" element={<BiharLG title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/lg-ro-service-Sheohar" element={<BiharLG title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/lg-ro-service-Sheikhpura" element={<BiharLG title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/lg-ro-service-Saran" element={<BiharLG title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/lg-ro-service-Sitamarhi" element={<BiharLG title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/lg-ro-service-Supaul" element={<BiharLG title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/lg-ro-service-Siwan" element={<BiharLG title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/lg-ro-service-Vaishali" element={<BiharLG title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/lg-ro-service-West-Champaran" element={<BiharLG title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/lg-ro-service-Garhwa" element={<JharkhandLG title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/lg-ro-service-Palamu" element={<JharkhandLG title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/lg-ro-service-Latehar" element={<JharkhandLG title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/lg-ro-service-Chatra" element={<JharkhandLG title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/lg-ro-service-Hazaribagh" element={<JharkhandLG title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/lg-ro-service-Jamshedpur" element={<JharkhandLG title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/lg-ro-service-Koderma" element={<JharkhandLG title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/lg-ro-service-Giridih" element={<JharkhandLG title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/lg-ro-service-Ramgarh" element={<JharkhandLG title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/lg-ro-service-Bokaro" element={<JharkhandLG title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/lg-ro-service-Dhanbad" element={<JharkhandLG title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/lg-ro-service-Lohardaga" element={<JharkhandLG title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/lg-ro-service-Gumla" element={<JharkhandLG title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/lg-ro-service-Simdega" element={<JharkhandLG title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/lg-ro-service-Ranchi" element={<JharkhandLG title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/lg-ro-service-Khunti" element={<JharkhandLG title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/lg-ro-service-Singhbhum" element={<JharkhandLG title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/lg-ro-service-Saraikela-Kharsawan" element={<JharkhandLG title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/lg-ro-service-Jamtara" element={<JharkhandLG title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/lg-ro-service-Deoghar" element={<JharkhandLG title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/lg-ro-service-Dumka" element={<JharkhandLG title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/lg-ro-service-Pakur" element={<JharkhandLG title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/lg-ro-service-Godda" element={<JharkhandLG title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/lg-ro-service-Sahebganj" element={<JharkhandLG title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />


        <Route path="/lg-ro-service-Alipurduar" element={<BengalLG title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/lg-ro-service-Bankura" element={<BengalLG title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/lg-ro-service-Birbhum" element={<BengalLG title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/lg-ro-service-Cooch-Behar" element={<BengalLG title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/lg-ro-service-Dakshin-Dinajpur" element={<BengalLG title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/lg-ro-service-Darjeeling" element={<BengalLG title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/lg-ro-service-Durgapur" element={<BengalLG title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/lg-ro-service-Hooghly" element={<BengalLG title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/lg-ro-service-Howrah" element={<BengalLG title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/lg-ro-service-Jalpaiguri" element={<BengalLG title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/lg-ro-service-Jhargram" element={<BengalLG title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/lg-ro-service-Kalimpong" element={<BengalLG title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/lg-ro-service-Kolkata" element={<BengalLG title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/lg-ro-service-Malda" element={<BengalLG title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/lg-ro-service-Murshidabad" element={<BengalLG title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/lg-ro-service-Nadia" element={<BengalLG title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/lg-ro-service-North-24-Parganas" element={<BengalLG title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/lg-ro-service-Paschim-Bardhaman" element={<BengalLG title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/lg-ro-service-Paschim-Medinipur" element={<BengalLG title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/lg-ro-service-Purba-Bardhaman" element={<BengalLG title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/lg-ro-service-Purba-Medinipur" element={<BengalLG title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/lg-ro-service-South-24-Parganas" element={<BengalLG title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/lg-ro-service-Uttar-Dinajpur" element={<BengalLG title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/lg-ro-service-Mumbai" element={<LGro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/lg-ro-service-Delhi" element={<LGro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/lg-ro-service-Bengaluru" element={<LGro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/lg-ro-service-Hyderabad" element={<LGro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/lg-ro-service-Ahmedabad" element={<LGro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/lg-ro-service-Chennai" element={<LGro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/lg-ro-service-Surat" element={<LGro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/lg-ro-service-Pune" element={<LGro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/lg-ro-service-Jaipur" element={<LGro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/lg-ro-service-Lucknow" element={<LGro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/lg-ro-service-Kanpur" element={<LGro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/lg-ro-service-Nagpur" element={<LGro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/lg-ro-service-Indore" element={<LGro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/lg-ro-service-Thane" element={<LGro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/lg-ro-service-Bhopal" element={<LGro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/lg-ro-service-Visakhapatnam" element={<LGro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/lg-ro-service-Vadodara" element={<LGro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/lg-ro-service-Ghaziabad" element={<LGro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/lg-ro-service-Ludhiana" element={<LGro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/lg-ro-service-Agra" element={<LGro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/lg-ro-service-Nashik" element={<LGro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/lg-ro-service-Faridabad" element={<LGro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/lg-ro-service-Meerut" element={<LGro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/lg-ro-service-Rajkot" element={<LGro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/lg-ro-service-Varanasi" element={<LGro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/lg-ro-service-Srinagar" element={<LGro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/lg-ro-service-Amritsar" element={<LGro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/lg-ro-service-Navi-Mumbai" element={<LGro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/lg-ro-service-Prayagraj" element={<LGro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/lg-ro-service-Jabalpur" element={<LGro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/lg-ro-service-Gwalior" element={<LGro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/lg-ro-service-Coimbatore" element={<LGro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/lg-ro-service-Vijayawada" element={<LGro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/lg-ro-service-Jodhpur" element={<LGro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/lg-ro-service-Madurai" element={<LGro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/lg-ro-service-Raipur" element={<LGro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/lg-ro-service-Kota" element={<LGro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/lg-ro-service-Guwahati" element={<LGro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/lg-ro-service-Chandigarh" element={<LGro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/lg-ro-service-Thiruvananthapuram" element={<LGro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/lg-ro-service-Solapur" element={<LGro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/lg-ro-service-Tiruppur" element={<LGro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/lg-ro-service-Moradabad" element={<LGro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/lg-ro-service-Mysore" element={<LGro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/lg-ro-service-Gurgaon" element={<LGro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/lg-ro-service-Aligarh" element={<LGro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/lg-ro-service-Jalandhar" element={<LGro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/lg-ro-service-Bhubaneswar" element={<LGro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/lg-ro-service-Noida" element={<LGro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/lg-ro-service-Jamshedpur" element={<LGro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/lg-ro-service-Cuttack" element={<LGro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/lg-ro-service-Bhavnagar" element={<LGro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/lg-ro-service-Dehradun" element={<LGro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/lg-ro-service-Rourkela" element={<LGro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/lg-ro-service-Jamnagar" element={<LGro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/lg-ro-service-Ujjain" element={<LGro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/lg-ro-service-Jammu" element={<LGro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/lg-ro-service-Mangalore" element={<LGro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/lg-ro-service-Erode" element={<LGro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/lg-ro-service-Udaipur" element={<LGro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/lg-ro-service-Rajahmundry" element={<LGro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/lg-ro-service-Patiala" element={<LGro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/lg-ro-service-Agartala" element={<LGro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/lg-ro-service-Tirupati" element={<LGro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/lg-ro-service-Sambalpur" element={<LGro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/lg-ro-service-Bilaspur" element={<LGro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/lg-ro-service-Alwar" element={<LGro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/lg-ro-service-Bardhaman" element={<LGro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/lg-ro-service-Sonipat" element={<LGro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/lg-ro-service-New-Delhi" element={<LGro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/lg-ro-service-Puducherry" element={<LGro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/lg-ro-service-Haridwar" element={<LGro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/lg-ro-service-Vijayanagaram" element={<LGro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/lg-ro-service-Sri-Ganganagar" element={<LGro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/lg-ro-service-Secunderabad" element={<LGro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/lg-ro-service-Yamunanagar" element={<LGro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/lg-ro-service-Gandhinagar" element={<LGro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/lg-ro-service-Ambala" element={<LGro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/lg-ro-service-Vellore" element={<LGro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/lg-ro-service-Shimla" element={<LGro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/lg-ro-service-Amaravati" element={<LGro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/livepure-ro-service-Araria" element={<BiharLivepure title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/livepure-ro-service-Arwal" element={<BiharLivepure title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/livepure-ro-service-Aurangabad" element={<BiharLivepure title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/livepure-ro-service-Banka" element={<BiharLivepure title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/livepure-ro-service-Begusarai" element={<BiharLivepure title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/livepure-ro-service-Bhabua" element={<BiharLivepure title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/livepure-ro-service-Bhagalpur" element={<BiharLivepure title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/livepure-ro-service-Bhojpur" element={<BiharLivepure title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/livepure-ro-service-Bihar-Sharif" element={<BiharLivepure title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/livepure-ro-service-Buxar" element={<BiharLivepure title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/livepure-ro-service-Chhapra" element={<BiharLivepure title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/livepure-ro-service-Darbhanga" element={<BiharLivepure title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/livepure-ro-service-East-Champaran" element={<BiharLivepure title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/livepure-ro-service-Gaya" element={<BiharLivepure title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/livepure-ro-service-Gopalganj" element={<BiharLivepure title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/livepure-ro-service-Jamui" element={<BiharLivepure title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/livepure-ro-service-Jehanabad" element={<BiharLivepure title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/livepure-ro-service-Khagaria" element={<BiharLivepure title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/livepure-ro-service-Kishanganj" element={<BiharLivepure title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/livepure-ro-service-Kaimur" element={<BiharLivepure title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/livepure-ro-service-Katihar" element={<BiharLivepure title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/livepure-ro-service-Lakhisarai" element={<BiharLivepure title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/livepure-ro-service-Madhubani" element={<BiharLivepure title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/livepure-ro-service-Munger" element={<BiharLivepure title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/livepure-ro-service-Madhepura" element={<BiharLivepure title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/livepure-ro-service-Muzaffarpur" element={<BiharLivepure title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/livepure-ro-service-Nalanda" element={<BiharLivepure title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/livepure-ro-service-Nawada" element={<BiharLivepure title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/livepure-ro-service-Patna" element={<BiharLivepure title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/livepure-ro-service-Purnia" element={<BiharLivepure title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/livepure-ro-service-Rohtas" element={<BiharLivepure title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/livepure-ro-service-Saharsa" element={<BiharLivepure title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/livepure-ro-service-Samastipur" element={<BiharLivepure title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/livepure-ro-service-Sasaram" element={<BiharLivepure title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/livepure-ro-service-Sheohar" element={<BiharLivepure title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/livepure-ro-service-Sheikhpura" element={<BiharLivepure title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/livepure-ro-service-Saran" element={<BiharLivepure title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/livepure-ro-service-Sitamarhi" element={<BiharLivepure title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/livepure-ro-service-Supaul" element={<BiharLivepure title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/livepure-ro-service-Siwan" element={<BiharLivepure title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/livepure-ro-service-Vaishali" element={<BiharLivepure title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/livepure-ro-service-West-Champaran" element={<BiharLivepure title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/livepure-ro-service-Garhwa" element={<JharkhandLivepure title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/livepure-ro-service-Palamu" element={<JharkhandLivepure title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/livepure-ro-service-Latehar" element={<JharkhandLivepure title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/livepure-ro-service-Chatra" element={<JharkhandLivepure title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/livepure-ro-service-Hazaribagh" element={<JharkhandLivepure title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/livepure-ro-service-Jamshedpur" element={<JharkhandLivepure title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/livepure-ro-service-Koderma" element={<JharkhandLivepure title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/livepure-ro-service-Giridih" element={<JharkhandLivepure title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/livepure-ro-service-Ramgarh" element={<JharkhandLivepure title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/livepure-ro-service-Bokaro" element={<JharkhandLivepure title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/livepure-ro-service-Dhanbad" element={<JharkhandLivepure title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/livepure-ro-service-Lohardaga" element={<JharkhandLivepure title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/livepure-ro-service-Gumla" element={<JharkhandLivepure title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/livepure-ro-service-Simdega" element={<JharkhandLivepure title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/livepure-ro-service-Ranchi" element={<JharkhandLivepure title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/livepure-ro-service-Khunti" element={<JharkhandLivepure title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/livepure-ro-service-Singhbhum" element={<JharkhandLivepure title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/livepure-ro-service-Saraikela-Kharsawan" element={<JharkhandLivepure title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/livepure-ro-service-Jamtara" element={<JharkhandLivepure title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/livepure-ro-service-Deoghar" element={<JharkhandLivepure title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/livepure-ro-service-Dumka" element={<JharkhandLivepure title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/livepure-ro-service-Pakur" element={<JharkhandLivepure title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/livepure-ro-service-Godda" element={<JharkhandLivepure title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/livepure-ro-service-Sahebganj" element={<JharkhandLivepure title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />


        <Route path="/livepure-ro-service-Alipurduar" element={<BengalLivepure title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/livepure-ro-service-Bankura" element={<BengalLivepure title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/livepure-ro-service-Birbhum" element={<BengalLivepure title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/livepure-ro-service-Cooch-Behar" element={<BengalLivepure title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/livepure-ro-service-Dakshin-Dinajpur" element={<BengalLivepure title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/livepure-ro-service-Darjeeling" element={<BengalLivepure title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/livepure-ro-service-Durgapur" element={<BengalLivepure title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/livepure-ro-service-Hooghly" element={<BengalLivepure title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/livepure-ro-service-Howrah" element={<BengalLivepure title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/livepure-ro-service-Jalpaiguri" element={<BengalLivepure title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/livepure-ro-service-Jhargram" element={<BengalLivepure title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/livepure-ro-service-Kalimpong" element={<BengalLivepure title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/livepure-ro-service-Kolkata" element={<BengalLivepure title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/livepure-ro-service-Malda" element={<BengalLivepure title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/livepure-ro-service-Murshidabad" element={<BengalLivepure title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/livepure-ro-service-Nadia" element={<BengalLivepure title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/livepure-ro-service-North-24-Parganas" element={<BengalLivepure title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/livepure-ro-service-Paschim-Bardhaman" element={<BengalLivepure title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/livepure-ro-service-Paschim-Medinipur" element={<BengalLivepure title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/livepure-ro-service-Purba-Bardhaman" element={<BengalLivepure title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/livepure-ro-service-Purba-Medinipur" element={<BengalLivepure title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/livepure-ro-service-South-24-Parganas" element={<BengalLivepure title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/livepure-ro-service-Uttar-Dinajpur" element={<BengalLivepure title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/livepure-ro-service-Mumbai" element={<Livepurero title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/livepure-ro-service-Delhi" element={<Livepurero title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/livepure-ro-service-Bengaluru" element={<Livepurero title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/livepure-ro-service-Hyderabad" element={<Livepurero title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/livepure-ro-service-Ahmedabad" element={<Livepurero title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/livepure-ro-service-Chennai" element={<Livepurero title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/livepure-ro-service-Surat" element={<Livepurero title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/livepure-ro-service-Pune" element={<Livepurero title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/livepure-ro-service-Jaipur" element={<Livepurero title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/livepure-ro-service-Lucknow" element={<Livepurero title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/livepure-ro-service-Kanpur" element={<Livepurero title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/livepure-ro-service-Nagpur" element={<Livepurero title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/livepure-ro-service-Indore" element={<Livepurero title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/livepure-ro-service-Thane" element={<Livepurero title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/livepure-ro-service-Bhopal" element={<Livepurero title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/livepure-ro-service-Visakhapatnam" element={<Livepurero title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/livepure-ro-service-Vadodara" element={<Livepurero title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/livepure-ro-service-Ghaziabad" element={<Livepurero title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/livepure-ro-service-Ludhiana" element={<Livepurero title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/livepure-ro-service-Agra" element={<Livepurero title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/livepure-ro-service-Nashik" element={<Livepurero title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/livepure-ro-service-Faridabad" element={<Livepurero title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/livepure-ro-service-Meerut" element={<Livepurero title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/livepure-ro-service-Rajkot" element={<Livepurero title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/livepure-ro-service-Varanasi" element={<Livepurero title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/livepure-ro-service-Srinagar" element={<Livepurero title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/livepure-ro-service-Amritsar" element={<Livepurero title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/livepure-ro-service-Navi-Mumbai" element={<Livepurero title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/livepure-ro-service-Prayagraj" element={<Livepurero title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/livepure-ro-service-Jabalpur" element={<Livepurero title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/livepure-ro-service-Gwalior" element={<Livepurero title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/livepure-ro-service-Coimbatore" element={<Livepurero title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/livepure-ro-service-Vijayawada" element={<Livepurero title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/livepure-ro-service-Jodhpur" element={<Livepurero title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/livepure-ro-service-Madurai" element={<Livepurero title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/livepure-ro-service-Raipur" element={<Livepurero title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/livepure-ro-service-Kota" element={<Livepurero title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/livepure-ro-service-Guwahati" element={<Livepurero title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/livepure-ro-service-Chandigarh" element={<Livepurero title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/livepure-ro-service-Thiruvananthapuram" element={<Livepurero title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/livepure-ro-service-Solapur" element={<Livepurero title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/livepure-ro-service-Tiruppur" element={<Livepurero title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/livepure-ro-service-Moradabad" element={<Livepurero title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/livepure-ro-service-Mysore" element={<Livepurero title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/livepure-ro-service-Gurgaon" element={<Livepurero title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/livepure-ro-service-Aligarh" element={<Livepurero title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/livepure-ro-service-Jalandhar" element={<Livepurero title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/livepure-ro-service-Bhubaneswar" element={<Livepurero title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/livepure-ro-service-Noida" element={<Livepurero title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/livepure-ro-service-Jamshedpur" element={<Livepurero title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/livepure-ro-service-Cuttack" element={<Livepurero title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/livepure-ro-service-Bhavnagar" element={<Livepurero title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/livepure-ro-service-Dehradun" element={<Livepurero title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/livepure-ro-service-Rourkela" element={<Livepurero title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/livepure-ro-service-Jamnagar" element={<Livepurero title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/livepure-ro-service-Ujjain" element={<Livepurero title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/livepure-ro-service-Jammu" element={<Livepurero title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/livepure-ro-service-Mangalore" element={<Livepurero title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/livepure-ro-service-Erode" element={<Livepurero title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/livepure-ro-service-Udaipur" element={<Livepurero title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/livepure-ro-service-Rajahmundry" element={<Livepurero title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/livepure-ro-service-Patiala" element={<Livepurero title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/livepure-ro-service-Agartala" element={<Livepurero title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/livepure-ro-service-Tirupati" element={<Livepurero title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/livepure-ro-service-Sambalpur" element={<Livepurero title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/livepure-ro-service-Bilaspur" element={<Livepurero title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/livepure-ro-service-Alwar" element={<Livepurero title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/livepure-ro-service-Bardhaman" element={<Livepurero title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/livepure-ro-service-Sonipat" element={<Livepurero title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/livepure-ro-service-New-Delhi" element={<Livepurero title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/livepure-ro-service-Puducherry" element={<Livepurero title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/livepure-ro-service-Haridwar" element={<Livepurero title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/livepure-ro-service-Vijayanagaram" element={<Livepurero title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/livepure-ro-service-Sri-Ganganagar" element={<Livepurero title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/livepure-ro-service-Secunderabad" element={<Livepurero title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/livepure-ro-service-Yamunanagar" element={<Livepurero title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/livepure-ro-service-Gandhinagar" element={<Livepurero title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/livepure-ro-service-Ambala" element={<Livepurero title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/livepure-ro-service-Vellore" element={<Livepurero title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/livepure-ro-service-Shimla" element={<Livepurero title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/livepure-ro-service-Amaravati" element={<Livepurero title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/marq-ro-service-Araria" element={<BiharMarq title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/marq-ro-service-Arwal" element={<BiharMarq title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/marq-ro-service-Aurangabad" element={<BiharMarq title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/marq-ro-service-Banka" element={<BiharMarq title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/marq-ro-service-Begusarai" element={<BiharMarq title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/marq-ro-service-Bhabua" element={<BiharMarq title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/marq-ro-service-Bhagalpur" element={<BiharMarq title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/marq-ro-service-Bhojpur" element={<BiharMarq title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/marq-ro-service-Bihar-Sharif" element={<BiharMarq title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/marq-ro-service-Buxar" element={<BiharMarq title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/marq-ro-service-Chhapra" element={<BiharMarq title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/marq-ro-service-Darbhanga" element={<BiharMarq title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/marq-ro-service-East-Champaran" element={<BiharMarq title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/marq-ro-service-Gaya" element={<BiharMarq title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/marq-ro-service-Gopalganj" element={<BiharMarq title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/marq-ro-service-Jamui" element={<BiharMarq title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/marq-ro-service-Jehanabad" element={<BiharMarq title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/marq-ro-service-Khagaria" element={<BiharMarq title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/marq-ro-service-Kishanganj" element={<BiharMarq title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/marq-ro-service-Kaimur" element={<BiharMarq title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/marq-ro-service-Katihar" element={<BiharMarq title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/marq-ro-service-Lakhisarai" element={<BiharMarq title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/marq-ro-service-Madhubani" element={<BiharMarq title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/marq-ro-service-Munger" element={<BiharMarq title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/marq-ro-service-Madhepura" element={<BiharMarq title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/marq-ro-service-Muzaffarpur" element={<BiharMarq title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/marq-ro-service-Nalanda" element={<BiharMarq title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/marq-ro-service-Nawada" element={<BiharMarq title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/marq-ro-service-Patna" element={<BiharMarq title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/marq-ro-service-Purnia" element={<BiharMarq title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/marq-ro-service-Rohtas" element={<BiharMarq title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/marq-ro-service-Saharsa" element={<BiharMarq title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/marq-ro-service-Samastipur" element={<BiharMarq title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/marq-ro-service-Sasaram" element={<BiharMarq title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/marq-ro-service-Sheohar" element={<BiharMarq title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/marq-ro-service-Sheikhpura" element={<BiharMarq title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/marq-ro-service-Saran" element={<BiharMarq title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/marq-ro-service-Sitamarhi" element={<BiharMarq title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/marq-ro-service-Supaul" element={<BiharMarq title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/marq-ro-service-Siwan" element={<BiharMarq title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/marq-ro-service-Vaishali" element={<BiharMarq title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/marq-ro-service-West-Champaran" element={<BiharMarq title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/marq-ro-service-Garhwa" element={<JharkhandMarq title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/marq-ro-service-Palamu" element={<JharkhandMarq title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/marq-ro-service-Latehar" element={<JharkhandMarq title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/marq-ro-service-Chatra" element={<JharkhandMarq title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/marq-ro-service-Hazaribagh" element={<JharkhandMarq title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/marq-ro-service-Jamshedpur" element={<JharkhandMarq title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/marq-ro-service-Koderma" element={<JharkhandMarq title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/marq-ro-service-Giridih" element={<JharkhandMarq title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/marq-ro-service-Ramgarh" element={<JharkhandMarq title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/marq-ro-service-Bokaro" element={<JharkhandMarq title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/marq-ro-service-Dhanbad" element={<JharkhandMarq title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/marq-ro-service-Lohardaga" element={<JharkhandMarq title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/marq-ro-service-Gumla" element={<JharkhandMarq title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/marq-ro-service-Simdega" element={<JharkhandMarq title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/marq-ro-service-Ranchi" element={<JharkhandMarq title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/marq-ro-service-Khunti" element={<JharkhandMarq title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/marq-ro-service-Singhbhum" element={<JharkhandMarq title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/marq-ro-service-Saraikela-Kharsawan" element={<JharkhandMarq title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/marq-ro-service-Jamtara" element={<JharkhandMarq title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/marq-ro-service-Deoghar" element={<JharkhandMarq title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/marq-ro-service-Dumka" element={<JharkhandMarq title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/marq-ro-service-Pakur" element={<JharkhandMarq title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/marq-ro-service-Godda" element={<JharkhandMarq title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/marq-ro-service-Sahebganj" element={<JharkhandMarq title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />


        <Route path="/marq-ro-service-Alipurduar" element={<BengalMarq title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/marq-ro-service-Bankura" element={<BengalMarq title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/marq-ro-service-Birbhum" element={<BengalMarq title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/marq-ro-service-Cooch-Behar" element={<BengalMarq title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/marq-ro-service-Dakshin-Dinajpur" element={<BengalMarq title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/marq-ro-service-Darjeeling" element={<BengalMarq title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/marq-ro-service-Durgapur" element={<BengalMarq title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/marq-ro-service-Hooghly" element={<BengalMarq title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/marq-ro-service-Howrah" element={<BengalMarq title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/marq-ro-service-Jalpaiguri" element={<BengalMarq title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/marq-ro-service-Jhargram" element={<BengalMarq title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/marq-ro-service-Kalimpong" element={<BengalMarq title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/marq-ro-service-Kolkata" element={<BengalMarq title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/marq-ro-service-Malda" element={<BengalMarq title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/marq-ro-service-Murshidabad" element={<BengalMarq title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/marq-ro-service-Nadia" element={<BengalMarq title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/marq-ro-service-North-24-Parganas" element={<BengalMarq title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/marq-ro-service-Paschim-Bardhaman" element={<BengalMarq title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/marq-ro-service-Paschim-Medinipur" element={<BengalMarq title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/marq-ro-service-Purba-Bardhaman" element={<BengalMarq title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/marq-ro-service-Purba-Medinipur" element={<BengalMarq title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/marq-ro-service-South-24-Parganas" element={<BengalMarq title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/marq-ro-service-Uttar-Dinajpur" element={<BengalMarq title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/marq-ro-service-Mumbai" element={<MarQro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/marq-ro-service-Delhi" element={<MarQro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/marq-ro-service-Bengaluru" element={<MarQro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/marq-ro-service-Hyderabad" element={<MarQro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/marq-ro-service-Ahmedabad" element={<MarQro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/marq-ro-service-Chennai" element={<MarQro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/marq-ro-service-Surat" element={<MarQro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/marq-ro-service-Pune" element={<MarQro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/marq-ro-service-Jaipur" element={<MarQro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/marq-ro-service-Lucknow" element={<MarQro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/marq-ro-service-Kanpur" element={<MarQro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/marq-ro-service-Nagpur" element={<MarQro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/marq-ro-service-Indore" element={<MarQro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/marq-ro-service-Thane" element={<MarQro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/marq-ro-service-Bhopal" element={<MarQro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/marq-ro-service-Visakhapatnam" element={<MarQro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/marq-ro-service-Vadodara" element={<MarQro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/marq-ro-service-Ghaziabad" element={<MarQro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/marq-ro-service-Ludhiana" element={<MarQro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/marq-ro-service-Agra" element={<MarQro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/marq-ro-service-Nashik" element={<MarQro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/marq-ro-service-Faridabad" element={<MarQro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/marq-ro-service-Meerut" element={<MarQro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/marq-ro-service-Rajkot" element={<MarQro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/marq-ro-service-Varanasi" element={<MarQro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/marq-ro-service-Srinagar" element={<MarQro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/marq-ro-service-Amritsar" element={<MarQro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/marq-ro-service-Navi-Mumbai" element={<MarQro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/marq-ro-service-Prayagraj" element={<MarQro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/marq-ro-service-Jabalpur" element={<MarQro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/marq-ro-service-Gwalior" element={<MarQro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/marq-ro-service-Coimbatore" element={<MarQro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/marq-ro-service-Vijayawada" element={<MarQro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/marq-ro-service-Jodhpur" element={<MarQro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/marq-ro-service-Madurai" element={<MarQro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/marq-ro-service-Raipur" element={<MarQro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/marq-ro-service-Kota" element={<MarQro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/marq-ro-service-Guwahati" element={<MarQro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/marq-ro-service-Chandigarh" element={<MarQro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/marq-ro-service-Thiruvananthapuram" element={<MarQro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/marq-ro-service-Solapur" element={<MarQro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/marq-ro-service-Tiruppur" element={<MarQro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/marq-ro-service-Moradabad" element={<MarQro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/marq-ro-service-Mysore" element={<MarQro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/marq-ro-service-Gurgaon" element={<MarQro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/marq-ro-service-Aligarh" element={<MarQro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/marq-ro-service-Jalandhar" element={<MarQro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/marq-ro-service-Bhubaneswar" element={<MarQro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/marq-ro-service-Noida" element={<MarQro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/marq-ro-service-Jamshedpur" element={<MarQro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/marq-ro-service-Cuttack" element={<MarQro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/marq-ro-service-Bhavnagar" element={<MarQro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/marq-ro-service-Dehradun" element={<MarQro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/marq-ro-service-Rourkela" element={<MarQro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/marq-ro-service-Jamnagar" element={<MarQro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/marq-ro-service-Ujjain" element={<MarQro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/marq-ro-service-Jammu" element={<MarQro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/marq-ro-service-Mangalore" element={<MarQro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/marq-ro-service-Erode" element={<MarQro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/marq-ro-service-Udaipur" element={<MarQro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/marq-ro-service-Rajahmundry" element={<MarQro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/marq-ro-service-Patiala" element={<MarQro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/marq-ro-service-Agartala" element={<MarQro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/marq-ro-service-Tirupati" element={<MarQro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/marq-ro-service-Sambalpur" element={<MarQro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/marq-ro-service-Bilaspur" element={<MarQro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/marq-ro-service-Alwar" element={<MarQro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/marq-ro-service-Bardhaman" element={<MarQro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/marq-ro-service-Sonipat" element={<MarQro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/marq-ro-service-New-Delhi" element={<MarQro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/marq-ro-service-Puducherry" element={<MarQro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/marq-ro-service-Haridwar" element={<MarQro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/marq-ro-service-Vijayanagaram" element={<MarQro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/marq-ro-service-Sri-Ganganagar" element={<MarQro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/marq-ro-service-Secunderabad" element={<MarQro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/marq-ro-service-Yamunanagar" element={<MarQro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/marq-ro-service-Gandhinagar" element={<MarQro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/marq-ro-service-Ambala" element={<MarQro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/marq-ro-service-Vellore" element={<MarQro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/marq-ro-service-Shimla" element={<MarQro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/marq-ro-service-Amaravati" element={<MarQro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/pureit-ro-service-Araria" element={<BiharPureit title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/pureit-ro-service-Arwal" element={<BiharPureit title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/pureit-ro-service-Aurangabad" element={<BiharPureit title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/pureit-ro-service-Banka" element={<BiharPureit title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/pureit-ro-service-Begusarai" element={<BiharPureit title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/pureit-ro-service-Bhabua" element={<BiharPureit title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/pureit-ro-service-Bhagalpur" element={<BiharPureit title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/pureit-ro-service-Bhojpur" element={<BiharPureit title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/pureit-ro-service-Bihar-Sharif" element={<BiharPureit title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/pureit-ro-service-Buxar" element={<BiharPureit title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/pureit-ro-service-Chhapra" element={<BiharPureit title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/pureit-ro-service-Darbhanga" element={<BiharPureit title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/pureit-ro-service-East-Champaran" element={<BiharPureit title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/pureit-ro-service-Gaya" element={<BiharPureit title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/pureit-ro-service-Gopalganj" element={<BiharPureit title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/pureit-ro-service-Jamui" element={<BiharPureit title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/pureit-ro-service-Jehanabad" element={<BiharPureit title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/pureit-ro-service-Khagaria" element={<BiharPureit title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/pureit-ro-service-Kishanganj" element={<BiharPureit title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/pureit-ro-service-Kaimur" element={<BiharPureit title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/pureit-ro-service-Katihar" element={<BiharPureit title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/pureit-ro-service-Lakhisarai" element={<BiharPureit title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/pureit-ro-service-Madhubani" element={<BiharPureit title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/pureit-ro-service-Munger" element={<BiharPureit title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/pureit-ro-service-Madhepura" element={<BiharPureit title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/pureit-ro-service-Muzaffarpur" element={<BiharPureit title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/pureit-ro-service-Nalanda" element={<BiharPureit title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/pureit-ro-service-Nawada" element={<BiharPureit title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/pureit-ro-service-Patna" element={<BiharPureit title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/pureit-ro-service-Purnia" element={<BiharPureit title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/pureit-ro-service-Rohtas" element={<BiharPureit title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/pureit-ro-service-Saharsa" element={<BiharPureit title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/pureit-ro-service-Samastipur" element={<BiharPureit title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/pureit-ro-service-Sasaram" element={<BiharPureit title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/pureit-ro-service-Sheohar" element={<BiharPureit title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/pureit-ro-service-Sheikhpura" element={<BiharPureit title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/pureit-ro-service-Saran" element={<BiharPureit title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/pureit-ro-service-Sitamarhi" element={<BiharPureit title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/pureit-ro-service-Supaul" element={<BiharPureit title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/pureit-ro-service-Siwan" element={<BiharPureit title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/pureit-ro-service-Vaishali" element={<BiharPureit title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/pureit-ro-service-West-Champaran" element={<BiharPureit title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/pureit-ro-service-Garhwa" element={<JharkhandPureit title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/pureit-ro-service-Palamu" element={<JharkhandPureit title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/pureit-ro-service-Latehar" element={<JharkhandPureit title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/pureit-ro-service-Chatra" element={<JharkhandPureit title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/pureit-ro-service-Hazaribagh" element={<JharkhandPureit title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/pureit-ro-service-Jamshedpur" element={<JharkhandPureit title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/pureit-ro-service-Koderma" element={<JharkhandPureit title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/pureit-ro-service-Giridih" element={<JharkhandPureit title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/pureit-ro-service-Ramgarh" element={<JharkhandPureit title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/pureit-ro-service-Bokaro" element={<JharkhandPureit title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/pureit-ro-service-Dhanbad" element={<JharkhandPureit title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/pureit-ro-service-Lohardaga" element={<JharkhandPureit title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/pureit-ro-service-Gumla" element={<JharkhandPureit title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/pureit-ro-service-Simdega" element={<JharkhandPureit title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/pureit-ro-service-Ranchi" element={<JharkhandPureit title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/pureit-ro-service-Khunti" element={<JharkhandPureit title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/pureit-ro-service-Singhbhum" element={<JharkhandPureit title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/pureit-ro-service-Saraikela-Kharsawan" element={<JharkhandPureit title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/pureit-ro-service-Jamtara" element={<JharkhandPureit title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/pureit-ro-service-Deoghar" element={<JharkhandPureit title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/pureit-ro-service-Dumka" element={<JharkhandPureit title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/pureit-ro-service-Pakur" element={<JharkhandPureit title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/pureit-ro-service-Godda" element={<JharkhandPureit title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/pureit-ro-service-Sahebganj" element={<JharkhandPureit title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/pureit-ro-service-Alipurduar" element={<BengalPureit title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/pureit-ro-service-Bankura" element={<BengalPureit title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/pureit-ro-service-Birbhum" element={<BengalPureit title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/pureit-ro-service-Cooch-Behar" element={<BengalPureit title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/pureit-ro-service-Dakshin-Dinajpur" element={<BengalPureit title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/pureit-ro-service-Darjeeling" element={<BengalPureit title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/pureit-ro-service-Durgapur" element={<BengalPureit title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/pureit-ro-service-Hooghly" element={<BengalPureit title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/pureit-ro-service-Howrah" element={<BengalPureit title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/pureit-ro-service-Jalpaiguri" element={<BengalPureit title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/pureit-ro-service-Jhargram" element={<BengalPureit title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/pureit-ro-service-Kalimpong" element={<BengalPureit title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/pureit-ro-service-Kolkata" element={<BengalPureit title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/pureit-ro-service-Malda" element={<BengalPureit title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/pureit-ro-service-Murshidabad" element={<BengalPureit title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/pureit-ro-service-Nadia" element={<BengalPureit title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/pureit-ro-service-North-24-Parganas" element={<BengalPureit title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/pureit-ro-service-Paschim-Bardhaman" element={<BengalPureit title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/pureit-ro-service-Paschim-Medinipur" element={<BengalPureit title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/pureit-ro-service-Purba-Bardhaman" element={<BengalPureit title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/pureit-ro-service-Purba-Medinipur" element={<BengalPureit title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/pureit-ro-service-South-24-Parganas" element={<BengalPureit title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/pureit-ro-service-Uttar-Dinajpur" element={<BengalPureit title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/pureit-ro-service-Mumbai" element={<PureItro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/pureit-ro-service-Delhi" element={<PureItro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/pureit-ro-service-Bengaluru" element={<PureItro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/pureit-ro-service-Hyderabad" element={<PureItro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/pureit-ro-service-Ahmedabad" element={<PureItro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/pureit-ro-service-Chennai" element={<PureItro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/pureit-ro-service-Surat" element={<PureItro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/pureit-ro-service-Pune" element={<PureItro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/pureit-ro-service-Jaipur" element={<PureItro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/pureit-ro-service-Lucknow" element={<PureItro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/pureit-ro-service-Kanpur" element={<PureItro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/pureit-ro-service-Nagpur" element={<PureItro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/pureit-ro-service-Indore" element={<PureItro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/pureit-ro-service-Thane" element={<PureItro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/pureit-ro-service-Bhopal" element={<PureItro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/pureit-ro-service-Visakhapatnam" element={<PureItro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/pureit-ro-service-Vadodara" element={<PureItro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/pureit-ro-service-Ghaziabad" element={<PureItro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/pureit-ro-service-Ludhiana" element={<PureItro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/pureit-ro-service-Agra" element={<PureItro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/pureit-ro-service-Nashik" element={<PureItro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/pureit-ro-service-Faridabad" element={<PureItro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/pureit-ro-service-Meerut" element={<PureItro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/pureit-ro-service-Rajkot" element={<PureItro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/pureit-ro-service-Varanasi" element={<PureItro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/pureit-ro-service-Srinagar" element={<PureItro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/pureit-ro-service-Amritsar" element={<PureItro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/pureit-ro-service-Navi-Mumbai" element={<PureItro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/pureit-ro-service-Prayagraj" element={<PureItro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/pureit-ro-service-Jabalpur" element={<PureItro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/pureit-ro-service-Gwalior" element={<PureItro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/pureit-ro-service-Coimbatore" element={<PureItro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/pureit-ro-service-Vijayawada" element={<PureItro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/pureit-ro-service-Jodhpur" element={<PureItro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/pureit-ro-service-Madurai" element={<PureItro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/pureit-ro-service-Raipur" element={<PureItro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/pureit-ro-service-Kota" element={<PureItro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/pureit-ro-service-Guwahati" element={<PureItro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/pureit-ro-service-Chandigarh" element={<PureItro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/pureit-ro-service-Thiruvananthapuram" element={<PureItro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/pureit-ro-service-Solapur" element={<PureItro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/pureit-ro-service-Tiruppur" element={<PureItro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/pureit-ro-service-Moradabad" element={<PureItro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/pureit-ro-service-Mysore" element={<PureItro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/pureit-ro-service-Gurgaon" element={<PureItro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/pureit-ro-service-Aligarh" element={<PureItro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/pureit-ro-service-Jalandhar" element={<PureItro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/pureit-ro-service-Bhubaneswar" element={<PureItro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/pureit-ro-service-Noida" element={<PureItro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/pureit-ro-service-Jamshedpur" element={<PureItro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/pureit-ro-service-Cuttack" element={<PureItro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/pureit-ro-service-Bhavnagar" element={<PureItro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/pureit-ro-service-Dehradun" element={<PureItro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/pureit-ro-service-Rourkela" element={<PureItro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/pureit-ro-service-Jamnagar" element={<PureItro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/pureit-ro-service-Ujjain" element={<PureItro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/pureit-ro-service-Jammu" element={<PureItro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/pureit-ro-service-Mangalore" element={<PureItro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/pureit-ro-service-Erode" element={<PureItro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/pureit-ro-service-Udaipur" element={<PureItro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/pureit-ro-service-Rajahmundry" element={<PureItro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/pureit-ro-service-Patiala" element={<PureItro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/pureit-ro-service-Agartala" element={<PureItro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/pureit-ro-service-Tirupati" element={<PureItro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/pureit-ro-service-Sambalpur" element={<PureItro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/pureit-ro-service-Bilaspur" element={<PureItro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/pureit-ro-service-Alwar" element={<PureItro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/pureit-ro-service-Bardhaman" element={<PureItro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/pureit-ro-service-Sonipat" element={<PureItro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/pureit-ro-service-New-Delhi" element={<PureItro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/pureit-ro-service-Puducherry" element={<PureItro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/pureit-ro-service-Haridwar" element={<PureItro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/pureit-ro-service-Vijayanagaram" element={<PureItro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/pureit-ro-service-Sri-Ganganagar" element={<PureItro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/pureit-ro-service-Secunderabad" element={<PureItro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/pureit-ro-service-Yamunanagar" element={<PureItro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/pureit-ro-service-Gandhinagar" element={<PureItro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/pureit-ro-service-Ambala" element={<PureItro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/pureit-ro-service-Vellore" element={<PureItro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/pureit-ro-service-Shimla" element={<PureItro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/pureit-ro-service-Amaravati" element={<PureItro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />


        <Route path="/whirlpool-ro-service-Araria" element={<BiharWhirlpool title={locdata.Araria.title} number={locdata.Araria.number} />} />
        <Route path="/whirlpool-ro-service-Arwal" element={<BiharWhirlpool title={locdata.Arwal.title} number={locdata.Arwal.number} />} />
        <Route path="/whirlpool-ro-service-Aurangabad" element={<BiharWhirlpool title={locdata.Aurangabad.title} number={locdata.Aurangabad.number} />} />
        <Route path="/whirlpool-ro-service-Banka" element={<BiharWhirlpool title={locdata.Banka.title} number={locdata.Banka.number} />} />
        <Route path="/whirlpool-ro-service-Begusarai" element={<BiharWhirlpool title={locdata.Begusarai.title} number={locdata.Begusarai.number} />} />
        <Route path="/whirlpool-ro-service-Bhabua" element={<BiharWhirlpool title={locdata.Bhabua.title} number={locdata.Bhabua.number} />} />
        <Route path="/whirlpool-ro-service-Bhagalpur" element={<BiharWhirlpool title={locdata.Bhagalpur.title} number={locdata.Bhagalpur.number} />} />
        <Route path="/whirlpool-ro-service-Bhojpur" element={<BiharWhirlpool title={locdata.Bhojpur.title} number={locdata.Bhojpur.number} />} />
        <Route path="/whirlpool-ro-service-Bihar-Sharif" element={<BiharWhirlpool title={locdata.BiharSharif.title} number={locdata.BiharSharif.number} />} />
        <Route path="/whirlpool-ro-service-Buxar" element={<BiharWhirlpool title={locdata.Buxar.title} number={locdata.Buxar.number} />} />
        <Route path="/whirlpool-ro-service-Chhapra" element={<BiharWhirlpool title={locdata.Chhapra.title} number={locdata.Chhapra.number} />} />
        <Route path="/whirlpool-ro-service-Darbhanga" element={<BiharWhirlpool title={locdata.Darbhanga.title} number={locdata.Darbhanga.number} />} />
        <Route path="/whirlpool-ro-service-East-Champaran" element={<BiharWhirlpool title={locdata.EastChamparan.title} number={locdata.EastChamparan.number} />} />
        <Route path="/whirlpool-ro-service-Gaya" element={<BiharWhirlpool title={locdata.Gaya.title} number={locdata.Gaya.number} />} />
        <Route path="/whirlpool-ro-service-Gopalganj" element={<BiharWhirlpool title={locdata.Gopalganj.title} number={locdata.Gopalganj.number} />} />
        <Route path="/whirlpool-ro-service-Jamui" element={<BiharWhirlpool title={locdata.Jamui.title} number={locdata.Jamui.number} />} />
        <Route path="/whirlpool-ro-service-Jehanabad" element={<BiharWhirlpool title={locdata.Jehanabad.title} number={locdata.Jehanabad.number} />} />
        <Route path="/whirlpool-ro-service-Khagaria" element={<BiharWhirlpool title={locdata.Khagaria.title} number={locdata.Khagaria.number} />} />
        <Route path="/whirlpool-ro-service-Kishanganj" element={<BiharWhirlpool title={locdata.Kishanganj.title} number={locdata.Kishanganj.number} />} />
        <Route path="/whirlpool-ro-service-Kaimur" element={<BiharWhirlpool title={locdata.Kaimur.title} number={locdata.Kaimur.number} />} />
        <Route path="/whirlpool-ro-service-Katihar" element={<BiharWhirlpool title={locdata.Katihar.title} number={locdata.Katihar.number} />} />
        <Route path="/whirlpool-ro-service-Lakhisarai" element={<BiharWhirlpool title={locdata.Lakhisarai.title} number={locdata.Lakhisarai.number} />} />
        <Route path="/whirlpool-ro-service-Madhubani" element={<BiharWhirlpool title={locdata.Madhubani.title} number={locdata.Madhubani.number} />} />
        <Route path="/whirlpool-ro-service-Munger" element={<BiharWhirlpool title={locdata.Munger.title} number={locdata.Munger.number} />} />
        <Route path="/whirlpool-ro-service-Madhepura" element={<BiharWhirlpool title={locdata.Madhepura.title} number={locdata.Madhepura.number} />} />
        <Route path="/whirlpool-ro-service-Muzaffarpur" element={<BiharWhirlpool title={locdata.Muzaffarpur.title} number={locdata.Muzaffarpur.number} />} />
        <Route path="/whirlpool-ro-service-Nalanda" element={<BiharWhirlpool title={locdata.Nalanda.title} number={locdata.Nalanda.number} />} />
        <Route path="/whirlpool-ro-service-Nawada" element={<BiharWhirlpool title={locdata.Nawada.title} number={locdata.Nawada.number} />} />
        <Route path="/whirlpool-ro-service-Patna" element={<BiharWhirlpool title={locdata.Patna.title} number={locdata.Patna.number} />} />
        <Route path="/whirlpool-ro-service-Purnia" element={<BiharWhirlpool title={locdata.Purnia.title} number={locdata.Purnia.number} />} />
        <Route path="/whirlpool-ro-service-Rohtas" element={<BiharWhirlpool title={locdata.Rohtas.title} number={locdata.Rohtas.number} />} />
        <Route path="/whirlpool-ro-service-Saharsa" element={<BiharWhirlpool title={locdata.Saharsa.title} number={locdata.Saharsa.number} />} />
        <Route path="/whirlpool-ro-service-Samastipur" element={<BiharWhirlpool title={locdata.Samastipur.title} number={locdata.Samastipur.number} />} />
        <Route path="/whirlpool-ro-service-Sasaram" element={<BiharWhirlpool title={locdata.Sasaram.title} number={locdata.Sasaram.number} />} />
        <Route path="/whirlpool-ro-service-Sheohar" element={<BiharWhirlpool title={locdata.Sheohar.title} number={locdata.Sheohar.number} />} />
        <Route path="/whirlpool-ro-service-Sheikhpura" element={<BiharWhirlpool title={locdata.Sheikhpura.title} number={locdata.Sheikhpura.number} />} />
        <Route path="/whirlpool-ro-service-Saran" element={<BiharWhirlpool title={locdata.Saran.title} number={locdata.Saran.number} />} />
        <Route path="/whirlpool-ro-service-Sitamarhi" element={<BiharWhirlpool title={locdata.Sitamarhi.title} number={locdata.Sitamarhi.number} />} />
        <Route path="/whirlpool-ro-service-Supaul" element={<BiharWhirlpool title={locdata.Supaul.title} number={locdata.Supaul.number} />} />
        <Route path="/whirlpool-ro-service-Siwan" element={<BiharWhirlpool title={locdata.Siwan.title} number={locdata.Siwan.number} />} />
        <Route path="/whirlpool-ro-service-Vaishali" element={<BiharWhirlpool title={locdata.Vaishali.title} number={locdata.Vaishali.number} />} />
        <Route path="/whirlpool-ro-service-West-Champaran" element={<BiharWhirlpool title={locdata.WestChamparan.title} number={locdata.WestChamparan.number} />} />
        <Route path="/whirlpool-ro-service-Garhwa" element={<JharkhandWhirlpool title={locdata.Garhwa.title} number={locdata.Garhwa.number} />} />
        <Route path="/whirlpool-ro-service-Palamu" element={<JharkhandWhirlpool title={locdata.Palamu.title} number={locdata.Palamu.number} />} />
        <Route path="/whirlpool-ro-service-Latehar" element={<JharkhandWhirlpool title={locdata.Latehar.title} number={locdata.Latehar.number} />} />
        <Route path="/whirlpool-ro-service-Chatra" element={<JharkhandWhirlpool title={locdata.Chatra.title} number={locdata.Chatra.number} />} />
        <Route path="/whirlpool-ro-service-Hazaribagh" element={<JharkhandWhirlpool title={locdata.Hazaribagh.title} number={locdata.Hazaribagh.number} />} />
        <Route path="/whirlpool-ro-service-Jamshedpur" element={<JharkhandWhirlpool title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/whirlpool-ro-service-Koderma" element={<JharkhandWhirlpool title={locdata.Koderma.title} number={locdata.Koderma.number} />} />
        <Route path="/whirlpool-ro-service-Giridih" element={<JharkhandWhirlpool title={locdata.Giridih.title} number={locdata.Giridih.number} />} />
        <Route path="/whirlpool-ro-service-Ramgarh" element={<JharkhandWhirlpool title={locdata.Ramgarh.title} number={locdata.Ramgarh.number} />} />
        <Route path="/whirlpool-ro-service-Bokaro" element={<JharkhandWhirlpool title={locdata.Bokaro.title} number={locdata.Bokaro.number} />} />
        <Route path="/whirlpool-ro-service-Dhanbad" element={<JharkhandWhirlpool title={locdata.Dhanbad.title} number={locdata.Dhanbad.number} />} />
        <Route path="/whirlpool-ro-service-Lohardaga" element={<JharkhandWhirlpool title={locdata.Lohardaga.title} number={locdata.Lohardaga.number} />} />
        <Route path="/whirlpool-ro-service-Gumla" element={<JharkhandWhirlpool title={locdata.Gumla.title} number={locdata.Gumla.number} />} />
        <Route path="/whirlpool-ro-service-Simdega" element={<JharkhandWhirlpool title={locdata.Simdega.title} number={locdata.Simdega.number} />} />
        <Route path="/whirlpool-ro-service-Ranchi" element={<JharkhandWhirlpool title={locdata.Ranchi.title} number={locdata.Ranchi.number} />} />
        <Route path="/whirlpool-ro-service-Khunti" element={<JharkhandWhirlpool title={locdata.Khunti.title} number={locdata.Khunti.number} />} />
        <Route path="/whirlpool-ro-service-Singhbhum" element={<JharkhandWhirlpool title={locdata.Singhbhum.title} number={locdata.Singhbhum.number} />} />
        <Route path="/whirlpool-ro-service-Saraikela-Kharsawan" element={<JharkhandWhirlpool title={locdata.SaraikelaKharsawan.title} number={locdata.SaraikelaKharsawan.number} />} />
        <Route path="/whirlpool-ro-service-Jamtara" element={<JharkhandWhirlpool title={locdata.Jamtara.title} number={locdata.Jamtara.number} />} />
        <Route path="/whirlpool-ro-service-Deoghar" element={<JharkhandWhirlpool title={locdata.Deoghar.title} number={locdata.Deoghar.number} />} />
        <Route path="/whirlpool-ro-service-Dumka" element={<JharkhandWhirlpool title={locdata.Dumka.title} number={locdata.Dumka.number} />} />
        <Route path="/whirlpool-ro-service-Pakur" element={<JharkhandWhirlpool title={locdata.Pakur.title} number={locdata.Pakur.number} />} />
        <Route path="/whirlpool-ro-service-Godda" element={<JharkhandWhirlpool title={locdata.Godda.title} number={locdata.Godda.number} />} />
        <Route path="/whirlpool-ro-service-Sahebganj" element={<JharkhandWhirlpool title={locdata.Sahebganj.title} number={locdata.Sahebganj.number} />} />

        <Route path="/whirlpool-ro-service-Alipurduar" element={<BengalWhirlpool title={locdata.Alipurduar.title} number={locdata.Alipurduar.number} />} />
        <Route path="/whirlpool-ro-service-Bankura" element={<BengalWhirlpool title={locdata.Bankura.title} number={locdata.Bankura.number} />} />
        <Route path="/whirlpool-ro-service-Birbhum" element={<BengalWhirlpool title={locdata.Birbhum.title} number={locdata.Birbhum.number} />} />
        <Route path="/whirlpool-ro-service-Cooch-Behar" element={<BengalWhirlpool title={locdata.CoochBehar.title} number={locdata.CoochBehar.number} />} />
        <Route path="/whirlpool-ro-service-Dakshin-Dinajpur" element={<BengalWhirlpool title={locdata.DakshinDinajpur.title} number={locdata.DakshinDinajpur.number} />} />
        <Route path="/whirlpool-ro-service-Darjeeling" element={<BengalWhirlpool title={locdata.Darjeeling.title} number={locdata.Darjeeling.number} />} />
        <Route path="/whirlpool-ro-service-Durgapur" element={<BengalWhirlpool title={locdata.Durgapur.title} number={locdata.Durgapur.number} />} />
        <Route path="/whirlpool-ro-service-Hooghly" element={<BengalWhirlpool title={locdata.Hooghly.title} number={locdata.Hooghly.number} />} />
        <Route path="/whirlpool-ro-service-Howrah" element={<BengalWhirlpool title={locdata.Howrah.title} number={locdata.Howrah.number} />} />
        <Route path="/whirlpool-ro-service-Jalpaiguri" element={<BengalWhirlpool title={locdata.Jalpaiguri.title} number={locdata.Jalpaiguri.number} />} />
        <Route path="/whirlpool-ro-service-Jhargram" element={<BengalWhirlpool title={locdata.Jhargram.title} number={locdata.Jhargram.number} />} />
        <Route path="/whirlpool-ro-service-Kalimpong" element={<BengalWhirlpool title={locdata.Kalimpong.title} number={locdata.Kalimpong.number} />} />
        <Route path="/whirlpool-ro-service-Kolkata" element={<BengalWhirlpool title={locdata.Kolkata.title} number={locdata.Kolkata.number} />} />
        <Route path="/whirlpool-ro-service-Malda" element={<BengalWhirlpool title={locdata.Malda.title} number={locdata.Malda.number} />} />
        <Route path="/whirlpool-ro-service-Murshidabad" element={<BengalWhirlpool title={locdata.Murshidabad.title} number={locdata.Murshidabad.number} />} />
        <Route path="/whirlpool-ro-service-Nadia" element={<BengalWhirlpool title={locdata.Nadia.title} number={locdata.Nadia.number} />} />
        <Route path="/whirlpool-ro-service-North-24-Parganas" element={<BengalWhirlpool title={locdata.North24Parganas.title} number={locdata.North24Parganas.number} />} />
        <Route path="/whirlpool-ro-service-Paschim-Bardhaman" element={<BengalWhirlpool title={locdata.PaschimBardhaman.title} number={locdata.PaschimBardhaman.number} />} />
        <Route path="/whirlpool-ro-service-Paschim-Medinipur" element={<BengalWhirlpool title={locdata.PaschimMedinipur.title} number={locdata.PaschimMedinipur.number} />} />
        <Route path="/whirlpool-ro-service-Purba-Bardhaman" element={<BengalWhirlpool title={locdata.PurbaBardhaman.title} number={locdata.PurbaBardhaman.number} />} />
        <Route path="/whirlpool-ro-service-Purba-Medinipur" element={<BengalWhirlpool title={locdata.PurbaMedinipur.title} number={locdata.PurbaMedinipur.number} />} />
        <Route path="/whirlpool-ro-service-South-24-Parganas" element={<BengalWhirlpool title={locdata.South24Parganas.title} number={locdata.South24Parganas.number} />} />
        <Route path="/whirlpool-ro-service-Uttar-Dinajpur" element={<BengalWhirlpool title={locdata.UttarDinajpur.title} number={locdata.UttarDinajpur.number} />} />

        <Route path="/whirlpool-ro-service-Mumbai" element={<Whirlpoolro title={locdata.Mumbai.title} number={locdata.Mumbai.number} />} />
        <Route path="/whirlpool-ro-service-Delhi" element={<Whirlpoolro title={locdata.Delhi.title} number={locdata.Delhi.number} />} />
        <Route path="/whirlpool-ro-service-Bengaluru" element={<Whirlpoolro title={locdata.Bengaluru.title} number={locdata.Bengaluru.number} />} />
        <Route path="/whirlpool-ro-service-Hyderabad" element={<Whirlpoolro title={locdata.Hyderabad.title} number={locdata.Hyderabad.number} />} />
        <Route path="/whirlpool-ro-service-Ahmedabad" element={<Whirlpoolro title={locdata.Ahmedabad.title} number={locdata.Ahmedabad.number} />} />
        <Route path="/whirlpool-ro-service-Chennai" element={<Whirlpoolro title={locdata.Chennai.title} number={locdata.Chennai.number} />} />
        <Route path="/whirlpool-ro-service-Surat" element={<Whirlpoolro title={locdata.Surat.title} number={locdata.Surat.number} />} />
        <Route path="/whirlpool-ro-service-Pune" element={<Whirlpoolro title={locdata.Pune.title} number={locdata.Pune.number} />} />
        <Route path="/whirlpool-ro-service-Jaipur" element={<Whirlpoolro title={locdata.Jaipur.title} number={locdata.Jaipur.number} />} />
        <Route path="/whirlpool-ro-service-Lucknow" element={<Whirlpoolro title={locdata.Lucknow.title} number={locdata.Lucknow.number} />} />
        <Route path="/whirlpool-ro-service-Kanpur" element={<Whirlpoolro title={locdata.Kanpur.title} number={locdata.Kanpur.number} />} />
        <Route path="/whirlpool-ro-service-Nagpur" element={<Whirlpoolro title={locdata.Nagpur.title} number={locdata.Nagpur.number} />} />
        <Route path="/whirlpool-ro-service-Indore" element={<Whirlpoolro title={locdata.Indore.title} number={locdata.Indore.number} />} />
        <Route path="/whirlpool-ro-service-Thane" element={<Whirlpoolro title={locdata.Thane.title} number={locdata.Thane.number} />} />
        <Route path="/whirlpool-ro-service-Bhopal" element={<Whirlpoolro title={locdata.Bhopal.title} number={locdata.Bhopal.number} />} />
        <Route path="/whirlpool-ro-service-Visakhapatnam" element={<Whirlpoolro title={locdata.Visakhapatnam.title} number={locdata.Visakhapatnam.number} />} />
        <Route path="/whirlpool-ro-service-Vadodara" element={<Whirlpoolro title={locdata.Vadodara.title} number={locdata.Vadodara.number} />} />
        <Route path="/whirlpool-ro-service-Ghaziabad" element={<Whirlpoolro title={locdata.Ghaziabad.title} number={locdata.Ghaziabad.number} />} />
        <Route path="/whirlpool-ro-service-Ludhiana" element={<Whirlpoolro title={locdata.Ludhiana.title} number={locdata.Ludhiana.number} />} />
        <Route path="/whirlpool-ro-service-Agra" element={<Whirlpoolro title={locdata.Agra.title} number={locdata.Agra.number} />} />
        <Route path="/whirlpool-ro-service-Nashik" element={<Whirlpoolro title={locdata.Nashik.title} number={locdata.Nashik.number} />} />
        <Route path="/whirlpool-ro-service-Faridabad" element={<Whirlpoolro title={locdata.Faridabad.title} number={locdata.Faridabad.number} />} />
        <Route path="/whirlpool-ro-service-Meerut" element={<Whirlpoolro title={locdata.Meerut.title} number={locdata.Meerut.number} />} />
        <Route path="/whirlpool-ro-service-Rajkot" element={<Whirlpoolro title={locdata.Rajkot.title} number={locdata.Rajkot.number} />} />
        <Route path="/whirlpool-ro-service-Varanasi" element={<Whirlpoolro title={locdata.Varanasi.title} number={locdata.Varanasi.number} />} />
        <Route path="/whirlpool-ro-service-Srinagar" element={<Whirlpoolro title={locdata.Srinagar.title} number={locdata.Srinagar.number} />} />
        <Route path="/whirlpool-ro-service-Amritsar" element={<Whirlpoolro title={locdata.Amritsar.title} number={locdata.Amritsar.number} />} />
        <Route path="/whirlpool-ro-service-Navi-Mumbai" element={<Whirlpoolro title={locdata.NaviMumbai.title} number={locdata.NaviMumbai.number} />} />
        <Route path="/whirlpool-ro-service-Prayagraj" element={<Whirlpoolro title={locdata.Prayagraj.title} number={locdata.Prayagraj.number} />} />
        <Route path="/whirlpool-ro-service-Jabalpur" element={<Whirlpoolro title={locdata.Jabalpur.title} number={locdata.Jabalpur.number} />} />
        <Route path="/whirlpool-ro-service-Gwalior" element={<Whirlpoolro title={locdata.Gwalior.title} number={locdata.Gwalior.number} />} />
        <Route path="/whirlpool-ro-service-Coimbatore" element={<Whirlpoolro title={locdata.Coimbatore.title} number={locdata.Coimbatore.number} />} />
        <Route path="/whirlpool-ro-service-Vijayawada" element={<Whirlpoolro title={locdata.Vijayawada.title} number={locdata.Vijayawada.number} />} />
        <Route path="/whirlpool-ro-service-Jodhpur" element={<Whirlpoolro title={locdata.Jodhpur.title} number={locdata.Jodhpur.number} />} />
        <Route path="/whirlpool-ro-service-Madurai" element={<Whirlpoolro title={locdata.Madurai.title} number={locdata.Madurai.number} />} />
        <Route path="/whirlpool-ro-service-Raipur" element={<Whirlpoolro title={locdata.Raipur.title} number={locdata.Raipur.number} />} />
        <Route path="/whirlpool-ro-service-Kota" element={<Whirlpoolro title={locdata.Kota.title} number={locdata.Kota.number} />} />
        <Route path="/whirlpool-ro-service-Guwahati" element={<Whirlpoolro title={locdata.Guwahati.title} number={locdata.Guwahati.number} />} />
        <Route path="/whirlpool-ro-service-Chandigarh" element={<Whirlpoolro title={locdata.Chandigarh.title} number={locdata.Chandigarh.number} />} />
        <Route path="/whirlpool-ro-service-Thiruvananthapuram" element={<Whirlpoolro title={locdata.Thiruvananthapuram.title} number={locdata.Thiruvananthapuram.number} />} />
        <Route path="/whirlpool-ro-service-Solapur" element={<Whirlpoolro title={locdata.Solapur.title} number={locdata.Solapur.number} />} />
        <Route path="/whirlpool-ro-service-Tiruppur" element={<Whirlpoolro title={locdata.Tiruppur.title} number={locdata.Tiruppur.number} />} />
        <Route path="/whirlpool-ro-service-Moradabad" element={<Whirlpoolro title={locdata.Moradabad.title} number={locdata.Moradabad.number} />} />
        <Route path="/whirlpool-ro-service-Mysore" element={<Whirlpoolro title={locdata.Mysore.title} number={locdata.Mysore.number} />} />
        <Route path="/whirlpool-ro-service-Gurgaon" element={<Whirlpoolro title={locdata.Gurgaon.title} number={locdata.Gurgaon.number} />} />
        <Route path="/whirlpool-ro-service-Aligarh" element={<Whirlpoolro title={locdata.Aligarh.title} number={locdata.Aligarh.number} />} />
        <Route path="/whirlpool-ro-service-Jalandhar" element={<Whirlpoolro title={locdata.Jalandhar.title} number={locdata.Jalandhar.number} />} />
        <Route path="/whirlpool-ro-service-Bhubaneswar" element={<Whirlpoolro title={locdata.Bhubaneswar.title} number={locdata.Bhubaneswar.number} />} />
        <Route path="/whirlpool-ro-service-Noida" element={<Whirlpoolro title={locdata.Noida.title} number={locdata.Noida.number} />} />
        <Route path="/whirlpool-ro-service-Jamshedpur" element={<Whirlpoolro title={locdata.Jamshedpur.title} number={locdata.Jamshedpur.number} />} />
        <Route path="/whirlpool-ro-service-Cuttack" element={<Whirlpoolro title={locdata.Cuttack.title} number={locdata.Cuttack.number} />} />
        <Route path="/whirlpool-ro-service-Bhavnagar" element={<Whirlpoolro title={locdata.Bhavnagar.title} number={locdata.Bhavnagar.number} />} />
        <Route path="/whirlpool-ro-service-Dehradun" element={<Whirlpoolro title={locdata.Dehradun.title} number={locdata.Dehradun.number} />} />
        <Route path="/whirlpool-ro-service-Rourkela" element={<Whirlpoolro title={locdata.Rourkela.title} number={locdata.Rourkela.number} />} />
        <Route path="/whirlpool-ro-service-Jamnagar" element={<Whirlpoolro title={locdata.Jamnagar.title} number={locdata.Jamnagar.number} />} />
        <Route path="/whirlpool-ro-service-Ujjain" element={<Whirlpoolro title={locdata.Ujjain.title} number={locdata.Ujjain.number} />} />
        <Route path="/whirlpool-ro-service-Jammu" element={<Whirlpoolro title={locdata.Jammu.title} number={locdata.Jammu.number} />} />
        <Route path="/whirlpool-ro-service-Mangalore" element={<Whirlpoolro title={locdata.Mangalore.title} number={locdata.Mangalore.number} />} />
        <Route path="/whirlpool-ro-service-Erode" element={<Whirlpoolro title={locdata.Erode.title} number={locdata.Erode.number} />} />
        <Route path="/whirlpool-ro-service-Udaipur" element={<Whirlpoolro title={locdata.Udaipur.title} number={locdata.Udaipur.number} />} />
        <Route path="/whirlpool-ro-service-Rajahmundry" element={<Whirlpoolro title={locdata.Rajahmundry.title} number={locdata.Rajahmundry.number} />} />
        <Route path="/whirlpool-ro-service-Patiala" element={<Whirlpoolro title={locdata.Patiala.title} number={locdata.Patiala.number} />} />
        <Route path="/whirlpool-ro-service-Agartala" element={<Whirlpoolro title={locdata.Agartala.title} number={locdata.Agartala.number} />} />
        <Route path="/whirlpool-ro-service-Tirupati" element={<Whirlpoolro title={locdata.Tirupati.title} number={locdata.Tirupati.number} />} />
        <Route path="/whirlpool-ro-service-Sambalpur" element={<Whirlpoolro title={locdata.Sambalpur.title} number={locdata.Sambalpur.number} />} />
        <Route path="/whirlpool-ro-service-Bilaspur" element={<Whirlpoolro title={locdata.Bilaspur.title} number={locdata.Bilaspur.number} />} />
        <Route path="/whirlpool-ro-service-Alwar" element={<Whirlpoolro title={locdata.Alwar.title} number={locdata.Alwar.number} />} />
        <Route path="/whirlpool-ro-service-Bardhaman" element={<Whirlpoolro title={locdata.Bardhaman.title} number={locdata.Bardhaman.number} />} />
        <Route path="/whirlpool-ro-service-Sonipat" element={<Whirlpoolro title={locdata.Sonipat.title} number={locdata.Sonipat.number} />} />
        <Route path="/whirlpool-ro-service-New-Delhi" element={<Whirlpoolro title={locdata.NewDelhi.title} number={locdata.NewDelhi.number} />} />
        <Route path="/whirlpool-ro-service-Puducherry" element={<Whirlpoolro title={locdata.Puducherry.title} number={locdata.Puducherry.number} />} />
        <Route path="/whirlpool-ro-service-Haridwar" element={<Whirlpoolro title={locdata.Haridwar.title} number={locdata.Haridwar.number} />} />
        <Route path="/whirlpool-ro-service-Vijayanagaram" element={<Whirlpoolro title={locdata.Vijayanagaram.title} number={locdata.Vijayanagaram.number} />} />
        <Route path="/whirlpool-ro-service-Sri-Ganganagar" element={<Whirlpoolro title={locdata.SriGanganagar.title} number={locdata.SriGanganagar.number} />} />
        <Route path="/whirlpool-ro-service-Secunderabad" element={<Whirlpoolro title={locdata.Secunderabad.title} number={locdata.Secunderabad.number} />} />
        <Route path="/whirlpool-ro-service-Yamunanagar" element={<Whirlpoolro title={locdata.Yamunanagar.title} number={locdata.Yamunanagar.number} />} />
        <Route path="/whirlpool-ro-service-Gandhinagar" element={<Whirlpoolro title={locdata.Gandhinagar.title} number={locdata.Gandhinagar.number} />} />
        <Route path="/whirlpool-ro-service-Ambala" element={<Whirlpoolro title={locdata.Ambala.title} number={locdata.Ambala.number} />} />
        <Route path="/whirlpool-ro-service-Vellore" element={<Whirlpoolro title={locdata.Vellore.title} number={locdata.Vellore.number} />} />
        <Route path="/whirlpool-ro-service-Shimla" element={<Whirlpoolro title={locdata.Shimla.title} number={locdata.Shimla.number} />} />
        <Route path="/whirlpool-ro-service-Amaravati" element={<Whirlpoolro title={locdata.Amaravati.title} number={locdata.Amaravati.number} />} />

        </Routes>
      
      <Footerdetail data={landingPageData.Contact} />
      <Footer data={landingPageData.Footer} />
    </div>

  );
};


export default App;

