export const Brandlink = () => {
    return (
    <div className="main2 pregrahd" style={{ display: 'inline-block', width: '100%', flexDirection: 'column' }}>
        <p><a href="/ro-service-Kent" className="btn">Kent</a></p>
        <p><a href="/ro-service-Aquaguard" className="btn">Aquaguard</a></p>
        <p><a href="/ro-service-EurekaForbes" className="btn">Eureka Forbes</a></p>
        <p><a href="/ro-service-AOSmith" className="btn">A.O. Smith</a></p>
        <p><a href="/ro-service-BlueStar" className="btn">Blue Star</a></p>
        <p><a href="/ro-service-AquaFresh" className="btn">Aqua Fresh</a></p>
        <p><a href="/ro-service-Livpure" className="btn">Livpure</a></p>
        <p><a href="/ro-service-Pureit" className="btn">Pureit</a></p>
        <p><a href="/ro-service-Havells" className="btn"> Havells</a></p>
        <p><a href="/ro-service-LG" className="btn">LG</a></p>
        <p><a href="/ro-service-Whirlpool" className="btn">Whirlpool</a></p>
        <p><a href="/ro-service-MarQ" className="btn">MarQ</a></p>
        <p><a href="/ro-service-Kutchina" className="btn">Kutchina</a></p>
        <p><a href="/ro-service-Aquagrand" className="btn">Aquagrand</a></p>
        
    </div>
)
}