import React, { useState } from 'react'

const BiharAquafreshNearme = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div class="row">
                            {/*<!-- End Col --> */}
                            <div class="col-md-12 col-lg-12">

                                <div class="popular-city" style={{ display: 'flex' }}>
                                    <div class="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div class="section-heading">
                                            <h3>AO Smith RO Service in Near you</h3>
                                            <span class="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                            <li>
                                                <a href="/aquafresh-ro-service-Araria">Araria</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Arwal">Arwal</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Aurangabad">Aurangabad</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Banka">Banka</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Begusarai">Begusarai</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Bhabua">Bhabua</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Bhagalpur">Bhagalpur</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Bhojpur">Bhojpur</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Bihar-Sharif">Bihar Sharif</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Buxar">Buxar</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Chhapra">Chhapra</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Darbhanga">Darbhanga</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-East-Champaran">East Champaran</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Gaya">Gaya</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Gopalganj">Gopalganj</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Jamui">Jamui</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Jehanabad">Jehanabad</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Khagaria">Khagaria</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Kishanganj">Kishanganj</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Kaimur">Kaimur</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Katihar">Katihar</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Lakhisarai">Lakhisarai</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Madhubani">Madhubani</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Munger">Munger</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Madhepura">Madhepura</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Muzaffarpur">Muzaffarpur</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Nalanda">Nalanda</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Nawada">Nawada</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Patna">Patna</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Purnia">Purnia</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Rohtas">Rohtas</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Saharsa">Saharsa</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Samastipur">Samastipur</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Sasaram">Sasaram</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Sheohar">Sheohar</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Sheikhpura">Sheikhpura</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Saran">Saran</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Sitamarhi">Sitamarhi</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Supaul">Supaul</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Siwan">Siwan</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-Vaishali">Vaishali</a>
                                            </li>
                                            <li>
                                                <a href="/aquafresh-ro-service-West-Champaran">West Champaran</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BiharAquafreshNearme;