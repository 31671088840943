import React, { useState } from 'react'

const BengalAquagrandnearme = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div className="row">
                            {/*<!-- End Col --> */}
                            <div className="col-md-12 col-lg-12">

                                <div className="popular-city" style={{ display: 'flex' }}>
                                    <div className="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div className="section-heading">
                                            <h3>Aquagrand RO Service Near you</h3>
                                            <span className="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                            <li>  <a href="/aquagrand-ro-service-Alipurduar">Alipurduar</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Bankura">Bankura</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Birbhum">Birbhum</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Cooch-Behar">Cooch-Behar</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Dakshin-Dinajpur">Dakshin-Dinajpur</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Darjeeling">Darjeeling</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Durgapur">Durgapur</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Hooghly">Hooghly</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Howrah">Howrah</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Jalpaiguri">Jalpaiguri</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Jhargram">Jhargram</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Kalimpong">Kalimpong</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Kolkata">Kolkata</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Malda">Malda</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Murshidabad">Murshidabad</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Nadia">Nadia</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-North-24-Parganas">North 24 Parganas</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Paschim-Bardhaman">Paschim-Bardhaman</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Paschim-Medinipur">Paschim-Medinipur</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Purba-Bardhaman">Purba-Bardhaman</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Purba-Medinipur ">Purba-Medinipur </a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-South-24-Parganas">South-24-Parganas</a>	</li>
                                            <li>  <a href="/aquagrand-ro-service-Uttar-Dinajpur ">Uttar Dinajpur </a>	</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BengalAquagrandnearme;