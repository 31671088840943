import React, { useState } from 'react'

const PopularEdurekaforbesnearme = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div className="row">

                            <div className="col-md-12 col-lg-12">

                                <div className="popular-city" style={{ display: 'flex' }}>
                                    <div className="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div className="section-heading">
                                            <h3>Eurekaforbes RO Service in Popular Cities</h3>
                                            <span className="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                            <li>  <a href="/eurekaforbes-ro-service-Mumbai">Mumbai</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Delhi">Delhi</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Bengaluru">Bengaluru</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Hyderabad">Hyderabad</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Ahmedabad">Ahmedabad</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Chennai">Chennai</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Kolkata">Kolkata</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Surat">Surat</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Pune">Pune</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Jaipur">Jaipur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Lucknow">Lucknow</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Kanpur">Kanpur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Nagpur">Nagpur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Indore">Indore</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Thane">Thane</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Bhopal">Bhopal</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Visakhapatnam">Visakhapatnam</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Patna">Patna</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Vadodara">Vadodara</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Ghaziabad">Ghaziabad</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Ludhiana">Ludhiana</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Agra">Agra</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Nashik">Nashik</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Faridabad">Faridabad</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Meerut">Meerut</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Rajkot">Rajkot</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Varanasi">Varanasi</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Srinagar">Srinagar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Aurangabad">Aurangabad</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Dhanbad">Dhanbad</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Amritsar">Amritsar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Navi-Mumbai">Navi Mumbai</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Prayagraj">Prayagraj</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Howrah">Howrah</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Ranchi">Ranchi</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Jabalpur">Jabalpur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Gwalior">Gwalior</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Coimbatore">Coimbatore</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Vijayawada">Vijayawada</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Jodhpur">Jodhpur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Madurai">Madurai</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Raipur">Raipur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Kota">Kota</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Guwahati">Guwahati</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Chandigarh">Chandigarh</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Thiruvananthapuram">Thiruvananthapuram	</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Solapur">Solapur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Tiruppur">Tiruppur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Moradabad">Moradabad</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Mysore">Mysore</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Gurgaon">Gurgaon</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Aligarh">Aligarh</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Jalandhar">Jalandhar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Bhubaneswar">Bhubaneswar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Noida">Noida</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Jamshedpur">Jamshedpur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Cuttack">Cuttack</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Bhavnagar">Bhavnagar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Dehradun">Dehradun</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Durgapur">Durgapur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Rourkela">Rourkela</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Jamnagar">Jamnagar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Ujjain">Ujjain</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Jammu">Jammu</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Mangalore">Mangalore</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Erode">Erode</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Udaipur">Udaipur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Rajahmundry">Rajahmundry</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Bokaro">Bokaro</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Patiala">Patiala</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Agartala">Agartala</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Tirupati">Tirupati</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Sambalpur">Sambalpur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Bilaspur">Bilaspur</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Alwar">Alwar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Bardhaman">Bardhaman</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Sonipat">Sonipat</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Begusarai">Begusarai</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-New-Delhi">New Delhi</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Puducherry">Puducherry</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Haridwar">Haridwar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Vijayanagaram">Vijayanagaram</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Sri-Ganganagar">Sri Ganganagar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Secunderabad">Secunderabad</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Yamunanagar">Yamunanagar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Gandhinagar">Gandhinagar</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Malda">Malda</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Ambala">Ambala</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Vellore">Vellore</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Shimla">Shimla</a>	</li>
                                            <li>  <a href="/eurekaforbes-ro-service-Amaravati">Amaravati</a>	</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="popular-city" style={{ display: 'flex', marginTop: '20px' }}>

                                    <div className="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div className="section-heading">
                                            <h3 style={{ marginTop: '10px' }}>Important Links</h3>
                                            <span className="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                            <li>
                                                <a href="/ro-service">RO Service</a>
                                            </li>
                                            <li>
                                                <a href="/buy-water-purifier">Buy Water Purifier</a>
                                            </li>
                                            <li>
                                                <a href="/water-purifier-installation">Water Purifier Installation</a>
                                            </li>
                                            <li>
                                                <a href="/amc-plans"> Water Purifier AMC Plans </a>
                                            </li>
                                            <li>
                                                <a href="/customer-care">RO Water Purifier Customer Care</a>
                                            </li>
                                            <li>
                                                <a href="/charges">RO Service Charges</a>
                                            </li>
                                            <li>
                                                <a href="/ro-service-kit">RO Service Kit</a>
                                            </li>
                                            <li>
                                                <a href="/ro-plant">RO Plant</a>
                                            </li>
                                            <li>
                                                <a href="/ro-softener">Water Softener</a>
                                            </li>
                                            <li>
                                                <a href="/water-purifier-spare">Water Purifier Spare</a>
                                            </li>
                                            <li>
                                                <a href="/dm-plant-manufacturers">DM Plant Manufacturers</a>
                                            </li>
                                            <li>
                                                <a href="/sewage-treatment-plant-manufacturers">Sewage Treatment Plant Manufacturers</a>
                                            </li>
                                            <li>
                                                <a href="/atm">Water ATM Machine Manufacturers</a>
                                            </li>
                                            <li>
                                                <a href="/effluent-treatment-manufacturers">Effluent Treatment Plant Manufacturers</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PopularEdurekaforbesnearme;