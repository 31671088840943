import React, { useState } from 'react'

const Jharkhandaquaguardnearme = (props) => {
    return (
        <>
            <div id='footerdetail' >
                <div id='callus'>
                    <div className="container">
                        <div className="row">
                            {/*<!-- End Col --> */}
                            <div className="col-md-12 col-lg-12">

                                <div className="popular-city" style={{ display: 'flex' }}>
                                    <div className="footer-widget footer-left-widgetcties-pop popular-city-footers">
                                        <div className="section-heading">
                                            <h3>Aquaguard RO Service Near you</h3>
                                            <span className="animate-border border-black"></span>
                                        </div>
                                        <ul>
                                            <li>  <a href="/aquaguard-ro-service-Garhwa">Garhwa</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Palamu">Palamu</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Latehar">Latehar</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Chatra">Chatra</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Hazaribagh">Hazaribagh</a></li>
                                            <li>  <a href="/aquaguard-ro-service-Jamshedpur">Jamshedpur</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Koderma">Koderma</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Giridih">Giridih</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Ramgarh">Ramgarh</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Bokaro">Bokaro</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Dhanbad">Dhanbad</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Lohardaga">Lohardaga</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Gumla">Gumla</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Simdega">Simdega</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Ranchi">Ranchi</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Khunti">Khunti</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Singhbhum">Singhbhum</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Saraikela-Kharsawan">Saraikela-Kharsawan</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Jamtara">Jamtara</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Deoghar">Deoghar</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Dumka">Dumka</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Pakur">Pakur</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Godda">Godda</a>	</li>
                                            <li>  <a href="/aquaguard-ro-service-Sahebganj">Sahebganj</a>	</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Jharkhandaquaguardnearme;